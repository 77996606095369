/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%#= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import { createConsumer } from "@rails/actioncable"
import 'fullcalendar/dist/fullcalendar';
import 'simplebar/dist/simplebar';
import 'chart.js/dist/Chart'
import 'apexcharts/dist/apexcharts';           // Para o ApexCharts
import 'peity';               // Para o Peity
import * as ActiveStorage from "activestorage";
import "../utils/direct_uploads.js"
import $ from 'jquery'
import 'select2/dist/css/select2.css'
import 'select2'
import toastr from 'toastr';

window.toastr = toastr;

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)




// Configuração do ActionCable
document.addEventListener('DOMContentLoaded', function() {
    let isUserInteracted = false;

    // Função para habilitar a reprodução de áudio após a interação do usuário
    function enableAudioPlayback() {
        isUserInteracted = true;
        document.removeEventListener('click', enableAudioPlayback);
    }

    // Adiciona um evento de clique para detectar a primeira interação do usuário
    document.addEventListener('click', enableAudioPlayback);

    var cable = createConsumer('/cable');

    // Lista de message_ids
    var messageIds = [];

    // Função para exibir o toast
    function showToast(title, message) {
        const toastContainer = document.getElementById('toast-container');
        if (toastContainer) {
            toastContainer.innerHTML = `
                <div class="toast toast-info" aria-live="polite" style="display: block;">
                    <div class="toast-progress"></div>
                    <button type="button" class="toast-close-button" role="button">×</button>
                    <div class="toast-title">${title}</div>
                    <div class="toast-message">${message}</div>
                </div>
            `;

            // Fechar o toast ao clicar no botão fechar
            const closeButton = toastContainer.querySelector('.toast-close-button');
            if (closeButton) {
                closeButton.addEventListener('click', () => {
                    toastContainer.innerHTML = ''; // Limpa o container ao clicar no botão fechar
                });
            }
        }
    }

    function updateUnreadCount() {
        const unreadCountElement = document.getElementById('unread-count');
        const unreadWhatsappElement = document.getElementById('unread-count-whatsapp');
        if (unreadCountElement) {
            unreadCountElement.style.display = "block"
        }
        if (unreadWhatsappElement) {
            unreadWhatsappElement.style.display = "block"
        }
    }

    function playNotificationSound(messageId) {
        var audio = new Audio('/notification_sound2.mp3');
        audio.play().then(() => {
            audio.onended = function () {
                // Remover message_id da lista após a notificação ser tocada
                var index = messageIds.indexOf(messageId);
                if (index > -1) {
                    messageIds.splice(index, 1);
                    updateUnreadCount(); // Atualiza o contador depois de remover
                }
            };
        }).catch(error => {
            console.error('Erro ao reproduzir o som:', error);
        });
    }
    function notifyUser(operationalId, attorneyId,userId, messageId) {
        toastr['info']('Uma nova mensagem chegou para você!', 'Atenção!');

        if (isUserInteracted) {
            playNotificationSound(messageId);
        } else {
            console.log('User has not interacted with the page yet.');
        }
    }

    // Função para se conectar ao canal
    function subscribeToMessageNotificationChannel() {

        const allowNotificationMetaTag = document.querySelector('meta[name="allow-notification"]');
        const userIdMetaTag = document.querySelector('meta[name="user-id"]');
        const tenantMetaTag = document.querySelector('meta[name="tenant"]');
        const currentUserId = userIdMetaTag ? userIdMetaTag.content : null;
        const currentUserNotification = allowNotificationMetaTag ? allowNotificationMetaTag.content : null;
        const currentTenantNotification = tenantMetaTag ? tenantMetaTag.content : null;

        if (currentUserId) {
            //console.log("Logged in user ID:", currentUserId);
            // Agora você pode usar o userId no seu JavaScript
        } else {
            console.log("User ID not found");
        }

        return cable.subscriptions.create("MessageNotificationChannel", {
            connected: function() {
                console.log('Connected to MessageNotificationChannel');
            },
            received: function(data) {
                var messageId = data.message_id;
                var tenant = data.tenant_name;

                if (tenant && tenant.toString() === currentTenantNotification.toString()) {
                    if (!messageIds.includes(messageId)) {
                        messageIds.push(messageId);
                        updateUnreadCount(); // Atualiza o número de mensagens não lidas
                    }

                    if (currentUserNotification.toString() === "true") {
                        var operationalId = data.operational_id;
                        var attorneyId = data.attorney_id;
                        var userId = data.user_id;

                        if ((operationalId && currentUserId.toString() === operationalId.toString()) ||
                            (attorneyId && currentUserId.toString() === attorneyId.toString()) ||
                            (userId && currentUserId.toString() === userId.toString())) {

                            notifyUser(operationalId, attorneyId, userId, messageId);
                        }
                    }
                }

            },
            disconnected: function() {
                console.log('Disconnected from MessageNotificationChannel');
            }
        });
    }

    // Chamar a função para se conectar ao canal
    subscribeToMessageNotificationChannel();
});
window.addEventListener('turbolinks:load', () => {
    $('.select2').select2({tags: true, tokenSeparators: [',', ' ']});})
ActiveStorage.start();