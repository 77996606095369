import React, { useState, useEffect } from 'react';
import ImageWithFallback from "../ImageWithFallback";
const ContactMessage = ({message, image_avatar, chat, currentCompany}) => {


    return (
        <div className="list-response-message">
            {/* Cabeçalho */}

            <div className="chat-time">
                <div className="justify-content-between">
                    <div className="d-flex">
                        <ImageWithFallback
                            classImage={"img-fluid rounded-circle"}
                            src={message.position === "right" ?  message.avatar : chat.avatar}
                            style={{width: 50, height: 50, filter: "none"}}
                            fallback={image_avatar}
                            alt="user"
                        />
                        <span style={{paddingLeft: 10}}>{message.position === "right" ?
                            `${currentCompany.name}${message.sender ? " - " + message.sender : ""}` :
                            chat.title}</span>
                        <p>{message.message["phone"]} </p>
                    </div>

                    <div className="contact-edit chat-alert">
                        <i className='bx bx-save'></i>
                    </div>
                    <div className="contact-edit chat-alert">
                        <i className='bx bx-chat'></i>
                    </div>

                </div>


            </div>

            {/*<div className="list-response-message-header">
                <h4>{"ees"}</h4>
            </div>}


            {/* Corpo */}
            {/* <div className="list-response-message-body">
                <p>{"fdgsdfgdg"}</p>
            </div>*/}


        </div>
    );
}
export default ContactMessage;