import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Image, ListGroup, Modal, ProgressBar, Row} from "react-bootstrap";
const UseImportContactForCampaignModal = ({openModal, typeBaseCampaign, botId, campaignInteractionType }) => {
    const [showModal, setShowModal] = useState(openModal || false);

    const handleCloseModal = () => {
        setShowModal(false)
    };
    return(
        <React.Fragment>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Row style={{padding: 10}} className="d-flex justify-content-between">
                    <Modal.Title>
                    </Modal.Title>
                </Row>
                <Modal.Body>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" >
                        Salvar
                    </Button>
                    <Button variant="secondary" >
                        Cancelar
                    </Button>

                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );

}
export default UseImportContactForCampaignModal;