import React, { useState, useEffect, useRef } from 'react';
import ReactFlow, {
    useNodesState,
    useEdgesState
} from "react-flow-renderer";

import Recorder from "audio-react-recorder";
import axios from "axios";
import FlowChatBot from './FlowChatBot';
import TextInputNode from './TextInputNode';
import EmailInputNode from './EmailInputNode';
import PhoneInputNode from './PhoneInputNode';
import ImageChoiceNode from './ImageChoiceNode';
import DateInputNode from './DateInputNode';
import NameInputNode from './NameInputNode';


import 'react-flow-renderer/dist/style.css';

import './style/FlowChatBot.css';
import './style/Dropzone.css';
import './style/RangeSlider.css';
import ImageWithFallback from "../ImageWithFallback";
import InputMask from "react-input-mask";

const getNodeId = () => `${String(+new Date()).slice(6)}`;

function FlowChatBotRom({type_bot,loader,users,avatar,process_status, query_types_new_process, query_types_in_progress, query_types_in_close, image_pdf_url, image_doc_url,image_video_url,image_mp3_url,chatbots, image_audio_url, image_image_url, bot_id, bot_name, instance_id, bot_nodes,bot_edges, bot_published,departments,image_close,image_redirect_url,image_notification_url, image_close_url}) {

    const botNodes = Object.values(bot_nodes).length > 0 ? JSON.parse(bot_nodes) : [];
    const botEdges = Object.values(bot_edges).length > 0 ? JSON.parse(bot_edges) : [];

    const [nodes, setNodes, onNodesChange] = useNodesState(botNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(botEdges);

    const [nodeEdit, setNodeEdit] = useState(null);
    const [isNodeEdit, setIsNodeEdit] = useState(false);

    const [onSave, setOnSave] = useState(bot_published);
    const [clickInNode, setClickInNode] = useState(false);

    const [iconsOpen, setIconsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [selectValue, setSelectValue] = useState('Etiqueta 1');
    const [textareaValue, setTextareaValue] = useState("");
    const [textListValue, setTextListValue] = useState("");
    const [textPixNameValue, setTextPixNameValue] = useState({});
    const [textPhoneValue, setTextPhoneValue] = useState({});
    const [phoneValue, setPhoneValue] = useState("");
    const [textListareaValue, setTextListareaValue] = useState("");
    const [textTriggerValue, setTextTriggerValue] = useState("");
    const [textFinishValue, setTextFinishValue] = useState("");
    const [redirectType, setRedirectType] = useState("");

    const [delayValue, setDelayValue] = useState(2);
    const [textAreas, setTextAreas] = useState([{ id:  getNodeId(), value: '' }]);
    const [textListAreas, setTextListAreas] = useState([{ id:  getNodeId(), value: '', description: "" }]);
    const [textButtonsAreas, setTextButtonsAreas] = useState([{ id:  getNodeId(), value: '', description: "" , info:{}}]);
    const [maxTextAreas, setMaxTextAreas] = useState( 10);
    const [emojiPicker, setEmojiPicker] = useState( []);
    const [emojiAreas, setEmojiAreas] = useState( [{ value: '' }]);
    const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState( false);
    const [isButtonActive, setButtonActive] = useState(false);
    const [isFerramentasSlide, setFerramentasSlide] = useState(false);
    const [addFile, setAddFile] = useState(false);
    const [addInfo, setAddInfo] = useState(false);


    const [typeFile, setTypeFile] = useState("");
    const [editState, setEditState] = useState([]);
    const [trigger, setTrigger] = useState([]);
    const [state, setState] = useState([]);
    const [idState, setIdState] = useState([]);
    const [options, setOptions] = useState([]);
    const [uploadFile, setUploadFile] = useState(null);
    const [departmentUsers, setDepartmentUsers] = useState([]);

    const [selectedOptionChatBot, setSelectedOptionChatBot] = useState([]);
    const [selectedFile, setSelectedFile] = useState("");
    const [uploadFileRoute,   setUploadFileRoute] = useState("");
    const [selectedFileName, setSelectedFileName] = useState("");
    let [selectedFileSubmit, setSelectedFileSubmit] = useState(false);
    let [typeNode, setTypeNode] = useState("");

    // ACTIONS

    const [onDepartment, setOnDepartment] = useState(false);
    const [onConnectBot, setOnConnectBot] = useState(false)
    const [onUser, setOnUser] = useState(false)


    let [onRedirect, setOnRedirect] = useState(false);
    let [onTag, setOnTag] = useState(false);
    let [whichDepartment, setWhichDepartment] = useState([]);
    let [selectedOptionUser, setSelectedOptionUser] = useState([]);
    let [selectedOptionBot, setSelectedOptionBot] = useState([]);
    let [onClose, setOnClose] = useState(false);
    let [onNotification, setOnNotification] = useState(false);
    let [onShooting, setOnShooting] = useState(false);
    let [onCheckContact, setOnCheckContact] = useState(false);
    let [isCheckedAction, setIsCheckedAction] = useState(false);
    let [openOptionsSelectedUser, setOpenOptionsSelectedUser] = useState(false);

    let [selectedProcessStatus, setSelectedProcessStatus] = useState('')
    let [selectedQueryType, setSelectedQueryType] = useState('')
    let [openOptionsSelectedProcessStatus, setOpenOptionsSelectedProcessStatus] = useState(false)
    const [queryType, setQueryType] = useState([])
    const [optionsSelectedRedirect, setOptionsSelectedRedirect] = useState([
        ["Departamento", "DEPARTMENT"],
        ["Conexão de Fluxo", "CONNECT_BOT"]
    ]);
    const [selectedRedirect, setSelectedRedirect] = useState([]);
    const [selectTypeButtons, setSelectTypeButtons] = useState({});

    const [searchTerm, setSearchTerm] = useState('');

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedUsersNames, setSelectedUsersNames] = useState([]);
    const [selectedUsersAvatar, setSelectedUsersAvatar] = useState([]);
    const [inputSelected, setInputSelected] = useState({
        textInput:false,
        phoneInput:false,
        emailInput:false,
        dateInput:false,
        imageInput:false,
        nameInput:false,

    });
    const [inputTextSelected, setInputTextSelected] = useState('');
    const [textPixValue, setTextPixValue] = useState({});
    const [typeButton, setTypeButton] = useState('reply');
    const ferramentasRef = useRef(null);
    const emojiCode =  [128526, 128565, 129299, 129322, 128516];
    const getInfo =  ['Nome', "Telefone"];
    const getInfoCode =  {"Nome":"#{name}", "Telefone":"#{phone}"};

    const enumTypeFlowNode =  {1:'tag', 2:'trigger', 3:'finish', 4:'redirect', 5:'notify', 6:'message', 7:'smart_delay', 8:'files', 9: 'input', 10: 'list', 11: 'buttons', 12: 'webhook', 13: 'calendar', 14: 'order_note'};
    const enumTypeFlowNodeDict =  Object.fromEntries(
        Object.entries(enumTypeFlowNode).map(([key, value]) => [value, Number(key)])
    );
    {/*const enumTypeRedirect =  {'department':"Departamento", 'user':"Usuário", 'other_bot': "Outro ChatBot"};*/}
    const enumTypeRedirect =  {'user':"Usuário", 'other_bot': "Outro ChatBot"};

    const typeButtons =  [
        {'code':"reply", 'title': "Responder"},
        {'code':"copy", 'title': "Copiar"},
        {'code':"url", 'title': "Redirecionar Url"},
        {'code':"call", 'title': "Fazer Ligação"},
        {'code':"pix", 'title': "Pix"},
    ]
    const typePix =  [
        {'code':"random", 'title': "Aleatória"},
        {'code':"phone", 'title': "Telefone"},
        {'code':"email", 'title': "Email"},
        {'code':"cpf", 'title': "CPF"},
        {'code':"cnpj", 'title': "CNPJ"},
    ]
    const [selectedPix, setSelectedPix] = useState({});
    const isInitialized = useRef(false);

    const [seconds, setSeconds] = useState(20);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [expandedTextAreas, setExpandedTextAreas] = useState({});



    useEffect(() => {
        if (nodeEdit?.id ) {

                setState(nodeEdit);
                setIdState((prev) => ({ ...prev, id: nodeEdit.id }));
                setEditState((prev) => ({ ...prev, message: nodeEdit.data.message }));
                 if (Object.values(nodeEdit.data.options).length > 0) {
                     setOptions(Object.values(nodeEdit.data.options));
                 } else {
                     setOptions([]);
                 }


        }
    }, [nodeEdit]);

    const onUploadNodesEdges = (nodes,edges) => {


        const nodesString = JSON.stringify(nodes);
        const edgesString = JSON.stringify(edges);
        const formData = new FormData();

        formData.append('nodes', nodesString);
        formData.append('edges', edgesString);
        formData.append('bot_id', bot_id);
        formData.append('type', "upload");
        formData.append('instance_id', instance_id);
        formData.append('published', false);


        fetch('/bots/save_bot', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar data para o servidor.');
            }
            return response.json();
        }).then(data => {




            {/*document.getElementById('save_bot').value = '';
            document.getElementById('public_bot').value = '';

            document.getElementById("save_bot").style.display = "none";
            document.getElementById("public_bot").style.display = "none";

            var elementos = document.querySelectorAll(".modal-backdrop");

            elementos.forEach(function(elemento) {
                elemento.classList.remove("modal-backdrop");
            });

           document.getElementById("loading").style.display = "block";


            setInterval(() =>   loading(), 15000);


            if(type === "published"){
                setOnSave(!onSave)
            }
                window.location.reload();
            */}



        })
            .catch(error => {
                console.error('Erro ao enviar data:', error);
            });
    }
    const handleEditNode = (data) => {
        console.log("Função externa acionada -> handleEditNode", data);

        const updatedNodes = nodes.filter(node => node.id !== data.nodeId);

        setNodeEdit(updatedNodes);
        setIsNodeEdit(true);
        setButtonActive(prevState => !prevState);
        setIconsOpen(prevState => !prevState);


        let numberTypeNode = enumTypeFlowNodeDict[data.type]

        if(numberTypeNode === 9){
            setSelectedOption(2)
        }else{
            setSelectedOption(numberTypeNode)
        }


        if(numberTypeNode === 6){
            setTextareaValue(data.message)
            setTextAreas( Object.values(data.options).length > 0 ? data.options :  [{ id:  getNodeId(), value: '' }])
        }else{

        }



        setFerramentasSlide(prevState => !prevState);


    };
    const handleDeleteNode = (data) => {

        console.log("Função externa acionada -> handleDeleteNode", data);
        const updatedNodes = nodes.filter(node => node.id !== data.nodeId);

        // Remover todas as conexões vinculadas ao nó que está sendo excluído
        const updatedEdges = edges.filter(edge => edge.source !== data.nodeId && edge.target !== data.nodeId);

        // Atualizar o estado com os nós e as conexões atualizadas
        setNodes(updatedNodes);
        setEdges(updatedEdges);

        onUploadNodesEdges(updatedNodes, updatedEdges);
    };

    const handleChangeTypeButton = (id,value, label) => {

        let  newButton

        setSelectTypeButtons((prevState) => ({
            ...prevState,
            [id]: {value: value, label: label}, // Alterna o estado expandido do textarea específico
        }));
        switch (value) {
            case 'reply':
                 newButton = {
                     type: value,
                     content:{}
                }
                break;
            case 'copy':
                 newButton = {
                     type: value,
                     content:{
                            copyCode: ""
                     }
                }
                break;
            case 'url':
                newButton = {
                    type: value,
                    content:{
                        url: ""
                    }
                }
                break;
            case 'call':
                newButton = {
                    type: value,
                    content:{
                        phoneNumber: ""
                    }
                }
                break;
            case 'pix':
                newButton = {
                    type: value,
                    content:{
                        currency: "BRL",
                        name: "",
                        keyType: "", /* phone, email, cpf, cnpj, random  */
                        key: ""
                    }
                }
                break;
        }


        setTextButtonsAreas((prevAreas) =>
            prevAreas.map((area) =>
                area.id === id
                    ? { ...area, info:  newButton }
                    : area
            )
        );


    };

    const handleChangeTypePix = (id,value, label) => {

        setSelectedPix((prevState) => ({
            ...prevState,
            [id]: {value: value, label: label},
        }));
        setTextButtonsAreas((prevAreas) =>
            prevAreas.map((area) =>
                area.id === id
                    ? {
                        ...area,
                        info: {
                            ...area.info,
                            content: {
                                ...area.info.content,
                                keyType: value, // Atualiza o atributo 'name'
                            },
                        },
                    }
                    : area
            )
        );

    }
    const handleChangePhone = (id,event) => {
        setTextPhoneValue((prevState) => ({
            ...prevState,
            [id]: {value: event.target.value },
        }));

        setTextButtonsAreas((prevAreas) =>
            prevAreas.map((area) =>
                area.id === id
                    ? {
                        ...area,
                        info: {
                            ...area.info,
                            content: {
                                ...area.info.content,
                                phoneNumber: event.target.value, // Atualiza o atributo 'name'
                            },
                        },
                    }
                    : area
            )
        );
    }

    const handleDuplicateNode = (data) => {
        // Encontra o nó que será duplicado
        const nodeToDuplicate = nodes.find(node => node.id === data.nodeId);

        if (!nodeToDuplicate) return;

        // Cria uma cópia do nó e altera o id
        const newNode = {
            ...nodeToDuplicate,
            id: getNodeId(), // Gera um novo id
            data: {
                ...nodeToDuplicate.data,
                nodeId: getNodeId(), // Atualiza o nodeId dentro de data
                onClickDuplicate: handleDuplicateNode,
                onClickEdit: handleEditNode,
                onClickDelete: handleDeleteNode,
            },
            position: {
                x: nodeToDuplicate.position.x + 60, // Move o nó duplicado 60px para a direita
                y: nodeToDuplicate.position.y
            }

        };

        // Atualiza o estado de nodes imediatamente
        // Atualiza o estado de nodes imediatamente
        setNodes((prevNodes) => {
            const updatedNodes = [...prevNodes, newNode];

            // Atualiza os nodes e edges no backend ou conforme necessário
            onUploadNodesEdges(updatedNodes, edges); // Chama com os nodes atualizados

            return updatedNodes;
        });

        // Atualiza os nodes e edges no backend ou conforme necessário
        {/*onUploadNodesEdges([...nodes, newNode], edges);*/}
    }


    useEffect(() => {
        if (!isInitialized.current) {
            if (nodes.length === 0) {
                const initialNodes = [
                    {
                        id: "1",
                        type: 'custom',
                        data: {
                            type: "start",
                            message: "Start",
                            nodeId: '1',
                            onClickDuplicate: handleDuplicateNode,
                            onClickEdit: handleEditNode,
                            onClickDelete: handleDeleteNode,
                            options: {}
                        },
                        position: { x: 100, y: 100 }
                    }
                ];
                setNodes(initialNodes);
            } else {
                const needUpdate = nodes.some(node =>
                    node.data.onClickEdit !== handleEditNode || node.data.onClickDelete !== handleDeleteNode || node.data.onClickDuplicate !== handleDuplicateNode
                );

                if (needUpdate) {
                    const updatedNodes = nodes.map(node => ({
                        ...node,
                        data: {
                            ...node.data,
                            onClickEdit: handleEditNode,
                            onClickDuplicate: handleDuplicateNode,
                            onClickDelete: handleDeleteNode,
                        }
                    }));
                    setNodes(updatedNodes);
                }
            }
            isInitialized.current = true;
        }
    }, [nodes, setNodes, handleEditNode, handleDeleteNode, handleDuplicateNode]);

    useEffect(() => {
        if (!clickInNode) {
            setTypeFile("")
            setTypeNode("")
            setEditState([])
            setState([])
            setIdState([])
            setOptions([])
            setUploadFile(null)
            setSelectedFile("")
            setUploadFileRoute("");
            setSelectedFileName("")
            setSelectedRedirect([])
            setSelectedFileSubmit(false)
            setOnRedirect(false)
            setNodeEdit(null)
            setOpenOptionsSelectedUser(false)
            setWhichDepartment([])
            setSelectedOptionUser([])
            setSelectedOptionChatBot([])
            setExpandedTextAreas({})
            setTextListValue("")
            setTextListareaValue("")
            setTextListAreas([{ id:  getNodeId(), value: '', description: "" }])
            setTextButtonsAreas([{ id:  getNodeId(), value: '', description: "", info: {} }])
        }
    }, [clickInNode]);

    useEffect(() => {
        const items = document.querySelectorAll('.flow-list__item');
        const handleClick = () => {
            setFerramentasSlide(true);
        };

        items.forEach(item => {
            item.addEventListener('click', handleClick);
        });

        return () => {
            items.forEach(item => {
                item.removeEventListener('click', handleClick);
            });
        };
    }, []);

    const clearVariables = () => {
        setRedirectType("")
        setTextTriggerValue('')
        setTextFinishValue('')
        setTextareaValue('')
        setTextAreas([{ id: 1, value: '' }])
        setButtonActive(prevState => !prevState);
        setIconsOpen(prevState => !prevState);
        setFerramentasSlide(false);

        setOnUser(false)


        setTypeFile("");
        setEditState([]);
        setState([]);
        setIdState([]);
        setOptions([]);
        setSelectedOptionUser([]);
        setSelectedOptionChatBot([]);
        setWhichDepartment([]);
        setSelectedOptionBot([]);
        setUploadFile(null);
        setSelectedFile("");
        setSelectedFileName("");
        setSelectedFileSubmit(false);
        setNodeEdit(null);
        setOnClose(false);
        setOnDepartment(false)
        setOnRedirect(false)
        setOnShooting(false)
        setOnConnectBot(false)
        setOpenOptionsSelectedUser(false)

        setOnTag(false);
        setOpenOptionsSelectedProcessStatus(false)
        setSelectedQueryType('')
        setSelectedProcessStatus('')
        setSearchTerm("")
        setSelectedUsers([])
        setSelectedUsersNames([])
        setSelectedUsersAvatar([])

        setExpandedTextAreas({})
        setTextListValue("")
        setTextListareaValue("")
        setTextListAreas([{ id:  getNodeId(), value: '', description: "" }])
        setTextButtonsAreas([{ id:  getNodeId(), value: '', description: "", info: {} }])
    };

    const addOption = () => {
        setOptions([...options, ""]);
    };

    // Remove um input de opção
    const removeOption = (index) => {
        const newOptions = [...options];
        newOptions.splice(index, 1);
        setOptions(newOptions);
    };


    // Atualiza o valor de uma opção

    const handleOptionRedirectChange = (value) => {

        setSelectedRedirect(value)

        if(value === "DEPARTMENT"){
            setOnDepartment(true)
            setOnConnectBot(false)
        }else{
            if(value === "CONNECT_BOT"){
                setOnConnectBot(true)
                setOnDepartment(false)
            }
        }

    }

    const handleFileTypeChange =  (fileType) => {
        console.log("fileType " + fileType)
        setTypeFile(fileType)
    }
    const handleToggleClick = () => {
        let checkbox = document.querySelector('.switch input[type="checkbox"]');
        setIsCheckedAction(checkbox.checked)
    }



    const handleSubmit =  () => {
        setSelectedFileSubmit(true);
        document.getElementById('input_file_update').value = '';

        document.getElementById("upload_arquivo").style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    };
    const handleFileSubmit =  () => {
        setSelectedFileSubmit(true);
        document.getElementById('input_file_update').value = '';

        document.getElementById("form_upload_arquivo").style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    };
    const handleRemoveFileChange = () => {
        console.log("handleRemoveFileChange foi chamado");
        setUploadFile("");
        setUploadFileRoute("");
        setSelectedFile("");
        setSelectedFileName("");
        setSelectedFileSubmit(false);
    };
    const handleFileChange = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const fileType = file["type"].split('/')[0];

        // Cria o Blob
        const blob = new Blob([file], { type: file["type"] });
        const blobUrl = URL.createObjectURL(blob);
        setSelectedFile(blobUrl);
        setUploadFile(blobUrl);
        setTypeFile(fileType);
        setSelectedFileName(file["name"]);

        // Criação do FormData e envio do arquivo
        const formData = new FormData();
        formData.append('file', blob, file.name); // Use o Blob aqui
        formData.append('file_name', file["name"]);
        formData.append('type_file', file["type"]);
        formData.append('bot_name', bot_name);
        formData.append('bot_id', bot_id);
        formData.append('instance_id', instance_id);


        fetch('/bots/upload_data_from_url', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao enviar arquivo para o servidor.');
                }
                return response.json();
            })
            .then(data => {
                setUploadFileRoute(data.arquivoUrl);
            })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });
    };
    const handleOptionProcessStatusChange = (value) => {

        setSelectedProcessStatus(value);
        setOpenOptionsSelectedProcessStatus(true);

        if(value === 'IN_PROGRESS'){
            setQueryType(query_types_in_progress);
        }else{
            if(value === 'NEW_PROCESS'){
                setQueryType(query_types_new_process);

            }else{
                if(value === 'CLOSED'){
                    setQueryType(query_types_in_close);
                }
            }
        }

    }
    const  handleOptionQueryTypeChange = (value) => {
        setSelectedQueryType(value);

    }


    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleInputTextChange = (event) => {
        setInputTextSelected(event.target.value);
    };
    const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const handleCheckboxChange = (userId,name, avatar) => {
        const isSelected = selectedUsers.includes(userId);
        if (isSelected) {
            setSelectedUsers(selectedUsers.filter((id) => id !== userId));
            setSelectedUsersNames(selectedUsers.filter((name) => name !== name));
            setSelectedUsersAvatar(selectedUsers.filter((avatar) => avatar !== avatar))
        } else {
            setSelectedUsers([...selectedUsers, userId]);
            setSelectedUsersNames([...selectedUsersNames, name])
            setSelectedUsersAvatar([...selectedUsersAvatar, avatar])
        }
        setSearchTerm("")
    };

    const openToggle = () => {
        setButtonActive(prevState => !prevState);
        setIconsOpen(prevState => !prevState);
        setFerramentasSlide(false);


        setTextareaValue('')
        setTextAreas([{ id:  getNodeId(), value: '' }])
    };

    const removeClass = () => {
        if (ferramentasRef.current) {
            ferramentasRef.current.classList.remove('ferramentaslide');
        }
    };
    const showOption = (number) =>{
        setSelectedOption(number);
    };



   const handleTextareaChange = (event) => {
       setTextareaValue(event.target.value);

    }
    const handleTextListareaChange = (event) => {
        setTextListareaValue(event.target.value);

    }

    const handleTextListChange = (event) => {
        setTextListValue(event.target.value);

    }
    const handleTextPhoneChange = (event) => {
        setPhoneValue(event.target.value);

    }
    const handleTextPixNameChange = (id,event) => {
        setTextPixNameValue((prevState) => ({
            ...prevState,
            [id]: {value: event.target.value },
        }));

        setTextButtonsAreas((prevAreas) =>
            prevAreas.map((area) =>
                area.id === id
                    ? {
                        ...area,
                        info: {
                            ...area.info,
                            content: {
                                ...area.info.content,
                                name: event.target.value, // Atualiza o atributo 'name'
                            },
                        },
                    }
                    : area
            )
        );

    }
    const handleTextPixChange = (id,event) => {
        setTextPixValue((prevState) => ({
            ...prevState,
            [id]: {value: event.target.value },
        }));
        setTextButtonsAreas((prevAreas) =>
            prevAreas.map((area) =>
                area.id === id
                    ? {
                        ...area,
                        info: {
                            ...area.info,
                            content: {
                                ...area.info.content,
                                key: event.target.value, // Atualiza o atributo 'name'
                            },
                        },
                    }
                    : area
            )
        );

    }
    const handleTextTriggerChange = (event) => {
        setTextTriggerValue(event.target.value);

    }
    const handleTextFinishChange = (event) => {
        setTextFinishValue(event.target.value);

    }
    const handleDelayChange = (event) => {
        setDelayValue(event.target.value )
    }
    const handleEmojiPickerVisible = () => {
        setIsEmojiPickerVisible(!isEmojiPickerVisible)
        setAddInfo(false)
        setAddFile(false)
    }
    const handleAddTextArea = () => {
        setTextAreas((prevState) => {
            if (prevState.length < maxTextAreas) {
                const newId =  getNodeId();
                return [...prevState, { id: newId, value: '' }];
            }
            return prevState; // No changes if maxTextAreas is reached
        });
    };
    const handleAddTextListArea = () => {
        setTextListAreas((prevState) => {
            if (prevState.length < maxTextAreas) {
                const newId =  getNodeId();
                return [...prevState, { id: newId, value: '' , description: ''}];
            }
            return prevState; // No changes if maxTextAreas is reached
        });
    };

    const handleAddTextButtonArea = () => {
        setTextButtonsAreas((prevState) => {
            if (prevState.length < maxTextAreas) {
                const newId =  getNodeId();
                return [...prevState, { id: newId, value: '' , description: '', info:[]}];
            }
            return prevState; // No changes if maxTextAreas is reached
        });
    };
    const handleTextAreaChange = (id, event) => {

        const newTextAreas = textAreas.map((textArea) => {
            if (textArea.id === id) {
                return { ...textArea, value: event.target.value };
            }
            return textArea;
        });
        setTextAreas(newTextAreas);
    };

    const handleTextListAreaChange = (id, event) => {

        const newTextAreas = textListAreas.map((textArea) => {
            if (textArea.id === id) {
                return { ...textArea, value: event.target.value };
            }
            return textArea;
        });
        setTextListAreas(newTextAreas);
    };
    const handleTextButtonAreaChange = (id, event) => {

        const newTextAreas = textButtonsAreas.map((textArea) => {
            if (textArea.id === id) {
                return { ...textArea, value: event.target.value };
            }
            return textArea;
        });
        setTextButtonsAreas(newTextAreas);
    };
    const handleDescriptionListAreaChange = (id, event) => {

        const newDescriptionAreas = textListAreas.map((textArea) => {
            if (textArea.id === id) {
                return { ...textArea, description: event.target.value };
            }
            return textArea;
        });
        setTextListAreas(newDescriptionAreas);
    };
    const handleButtonListButtonsAreaChange = (id, event) => {

        const newButtonArea = textListAreas.map((textArea) => {
            if (textArea.id === id) {
                return { ...textArea, description: event.target.value };
            }
            return textArea;
        });
        setTextButtonsAreas(newButtonArea);
    };
    const handleDeleteTextArea = (id) => {
        setTextAreas(textAreas.filter(textArea => textArea.id !== id));
    };
    const handleDeleteTextListArea = (id) => {
        setTextListAreas(textListAreas.filter(textArea => textArea.id !== id));
    };
    const handleDeleteTextButtonsArea = (id) => {
        setTextButtonsAreas(textButtonsAreas.filter(textArea => textArea.id !== id));
    };
    const handleEmojiClick = (emoji) => {
        const newEmojiAreas =  textareaValue +  emoji
        setTextareaValue(newEmojiAreas);
        setIsEmojiPickerVisible(false);

    };
    const handleInfoClick = (info) => {

        const newInfoAreas =  textareaValue +  " " + getInfoCode[info]
        setTextareaValue(newInfoAreas);

        setAddInfo(false)

    };
    const renderGetInfo = () =>  {


        return (
            <React.Fragment>
                <div className="emoji-arrow"></div>
                {getInfo.map((code) => (
                    <a className="btn" key={code} style={{cursor: "pointer",
                        width: "100%",
                        height: 35,
                        display: "inline-block",
                        textAlign: "center",
                        backgroundColor: "rgb(255, 170, 5)" ,
                        borderColor: "rgb(255, 170, 5)" ,
                        paddingTop: 4}}
                       onClick={() => handleInfoClick(code)}>
                        {code}
                    </a>
                ))}
            </React.Fragment>
        );
    }
    const renderEmojiPicker = () =>  {


        return (
            <React.Fragment>
                <div className="emoji-arrow"></div>
                {emojiCode.map((code) => (
                    <span key={code} className='my-emoji' onClick={() => handleEmojiClick(String.fromCodePoint(code))}>
                        {String.fromCodePoint(code)}
                    </span>
                ))}
            </React.Fragment>
        );
    }
    const handleChangeRedirectType = (e) =>{
        const {  value } = e.target;
        setRedirectType(value)
        if(value === "department"){
            setOnDepartment(true)
            setOnConnectBot(false)
            setOnUser(false)
        }else{
            if(value === "other_bot"){
                setOnConnectBot(true)
                setOnDepartment(false)
                setOnUser(false)
            }else{
                if(value === "user"){
                    setOnUser(true)
                    setOnConnectBot(false)
                    setOnDepartment(false)

                }
            }
        }


    }
    const handleOptionDepartmentChange = (e) =>{
        const {  value } = e.target;
        const department = departments.find(option => option[1] === parseInt(value));
        setWhichDepartment(department);
    }
    const handleOptionChatBotChange = (e) =>{
        const {  value } = e.target;
        const chatbot = chatbots.find(option => option[1] === parseInt(value));
        setSelectedOptionChatBot(chatbot)
    }
    const handleOptionUserChange = (e) =>{
        const {  value } = e.target;

        const user = users.find(option => option.id === parseInt(value));

        setSelectedOptionUser([user.name, user.id, user.avatar, user.role])
    }
    const handleOptionDepartmentChanged = (value) => {

        // Encontre o índice do valor na matriz de opções
        const department = departments.find(option => option[1] === parseInt(value));
        // Atualize a matriz de opções com o novo valor

        setWhichDepartment(department);

        const formData = new FormData();
        formData.append('department',value);

        fetch('/messagings/get_user_from_department', {
            method: 'GET',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar arquivo para o servidor.');
            }
            return response.json();
        }).then(data => {

            setOpenOptionsSelectedUser(true);
            setDepartmentUsers( data.users);

        })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });

    }

    const handleOptionUserChanges = (value) => {

        const user = departmentUsers.find(option => option[1] === parseInt(value));
        setSelectedOptionUser(user);


    }
    const handleOptionConnectBotChange = (value) => {

        const chatbot = chatbots.find(option => option[1] === parseInt(value));
        setSelectedOptionChatBot(chatbot);

    }
    const createAction = {
        trigger: (textTriggerValue, bot_id, instance_id) => {
            const formData = new FormData();
            formData.append('trigger', textTriggerValue);
            formData.append('bot_id', bot_id);
            formData.append('instance_id', instance_id);

            fetch('/bots/trigger', {
                method: 'POST',
                body: formData,
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Erro ao enviar trigger para o servidor.');
                    }
                    return response.json();
                })
                .catch(error => {
                    console.error('Erro ao enviar trigger:', error);
                });

            return {
                type: 'trigger',
                title: 'Gatilho',
                message: textTriggerValue,
                action: [],
            };
        },
        finish: textFinishValue => ({
            type: 'close',
            title: 'Finalizar',
            message: textFinishValue || 'Terminar interação',
            action: [],
        }),
        redirect: (redirectType, redirectData) => ({
            type: 'redirect',
            title: 'Redirecionar',
            message: `Redirecionar para ${redirectType} - ${redirectData.name}`,
            action: redirectData,
        }),
        notify: (selectedUsers, selectedUsersNames, selectedUsersAvatar) => {
            const users = selectedUsers.reduce((acc, id, index) => {
                acc[id] = {
                    name: selectedUsersNames[index],
                    avatar: selectedUsersAvatar[index],
                };
                return acc;
            }, {});

            return {
                type: 'notification',
                title: 'Notificar',
                message: 'Enviar Notificação',
                action: {
                    users: {
                        ids: selectedUsers,
                        data: users,
                    },
                },
            };
        },
        files: (typeFile, uploadFile, uploadFileRoute, selectedFileName, image_doc_url) => ({
            type: 'files',
            media: {
                type: typeFile,
                src: uploadFile,
                viewFile: typeFile === 'document' || typeFile === 'application' ? image_doc_url : '',
                uploadFileRoute,
                viewFileName: selectedFileName,
            },
        }),
        smartDelay: delayValue => ({
            type: 'smart_delay',
            title: 'Atraso de envio',
            message: `Atraso de envio ${delayValue}s`,
            action: { delay: delayValue },
        }),
        tag: (processStatus, queryType, selectedProcessStatus, selectedQueryType) => {
            const findLabel = (list, value) => list.find(item => item[1] === value)?.[0] || '';

            return {
                type: 'tag',
                title: 'Etiquetar',
                message: `${findLabel(processStatus, selectedProcessStatus)} - ${findLabel(queryType, selectedQueryType)}`,
                action: {
                    type: 'TAG',
                    label_process_status: findLabel(processStatus, selectedProcessStatus),
                    code_process_status: selectedProcessStatus,
                    label_query_type: findLabel(queryType, selectedQueryType),
                    code_query_type: selectedQueryType,
                },
            };
        },
        list: (textListValue, textListareaValue, textListAreas) => ({
            type: 'list',
            title: 'Listagem',
            action: {
                title: textListValue,
                description: textListareaValue,
                selections: textListAreas,
            },
        }),
        buttons: (textListValue, textListareaValue, textButtonsAreas, selectTypeButtons,textPhoneValue,selectedPix, textPixValue , textPixNameValue) => {


            let buttons =   textButtonsAreas.map((button) => ({
                id: button.id,
                type_button: selectTypeButtons[button.id]?.value || '',
                label: button.value,
                info: button.info,
            }));

            return {
                type: 'buttons',
                title: 'Butôes',
                action: {
                    title: textListValue,
                    description: textListareaValue,
                    buttons: buttons,
                }
            }
        },


        order_note: (textListValue, textListareaValue, textListAreas) => ({
            type: 'webhook',
            title: 'Webhook',
            action: {
                url: textListValue,
                attributes: textListAreas,
            },
        }),
        calendar: (textListValue, textListareaValue, textListAreas) => ({
            type: 'webhook',
            title: 'Webhook',
            action: {
                url: textListValue,
                attributes: textListAreas,
            },
        }),
        webhook: (textListValue, textListareaValue, textListAreas, phoneValue) => ({
            type: 'webhook',
            title: 'Webhook',
            action: {
                title: textListValue,
                phone: phoneValue,
                selections: textListAreas,
            },
        }),
        input: (handleInputSelected, inputTextSelected) => ({
            type: 'input',
            title: 'Coletar',
            action: {
                inputSelected: handleInputSelected(),
                inputTextSelected,
            },
        }),
    };

    const onEdit = () => {



        setNodes((nds) =>
            nds.map((node) => {
                if (node.id === nodeEdit.id) {

                    let media = node.data.media
                    let editOptions = []
                    let message = ""
                    let action= {}
                    let  type = enumTypeFlowNode[selectedOption] || 'message'
                    let  newOptions = {}


                    if (textareaValue){
                        message = textareaValue
                    }
                    if(textAreas[textAreas.length - 1].value !== ''  ){
                        newOptions =  textAreas
                    }

                    if(uploadFile){
                        media = {
                            type: typeFile,
                            src: uploadFile,
                            viewFile: "",
                            uploadFileRoute: uploadFileRoute,
                            viewFileName: selectedFileName,
                        }
                    }
                    switch (type) {
                        case 'trigger':
                            action = createAction.trigger(textTriggerValue, bot_id, instance_id);
                            break
                        case 'finish':
                            action = createAction.finish(textFinishValue);
                            break
                        case 'redirect':
                            if (onDepartment) {
                                action = createAction.redirect('departamento', {
                                    type_redirect: 'DEPARTMENT',
                                    department_id: whichDepartment[1],
                                    department_name: whichDepartment[0],
                                    department_avatar: whichDepartment[2],
                                });
                            } else if (onConnectBot) {
                                action = createAction.redirect('chatbot', {
                                    type_redirect: 'CONNECT_BOT',
                                    bot_id: selectedOptionChatBot[1],
                                    bot_name: selectedOptionChatBot[0],
                                    bot_avatar: selectedOptionChatBot[2],
                                });
                            } else if (onUser) {
                                action = createAction.redirect('usuário', {
                                    type_redirect: 'USER',
                                    user_id: selectedOptionUser[1],
                                    user_name: selectedOptionUser[0],
                                    user_avatar: selectedOptionUser[2],
                                    user_role: selectedOptionUser[3],
                                    user_job: '',
                                });
                            }
                            break;
                        case 'notify':
                            action = createAction.notify(selectedUsers, selectedUsersNames, selectedUsersAvatar);
                            break;
                        case 'files':
                            action = createAction.files(typeFile, uploadFile, uploadFileRoute, selectedFileName,image_doc_url);
                            break;
                        case 'smart_delay':
                            action = createAction.smartDelay(delayValue);
                            break
                        case 'tag':
                            action = createAction.tag(process_status, queryType, selectedProcessStatus, selectedQueryType);
                            break;
                        case 'list':
                            action = createAction.list(textListValue, textListareaValue, textListAreas);
                            break;
                        case 'buttons':
                            action = createAction.buttons(textListValue, textListareaValue, textListAreas);
                            break;
                        case 'webhook':
                            action = createAction.webhook(textListValue, textListareaValue, newOptions, phoneValue);
                            break;
                        case 'calendar':
                            action = createAction.calendar(textListValue, textListareaValue, textListAreas);
                            break;
                        case 'order_note':
                            action = createAction.order_note(textListValue, textListareaValue, textListAreas);
                            break;
                        case 'input':
                            action = createAction.input(handleInputSelected, inputTextSelected);
                            break;
                        default:
                            console.error(`Tipo de ação desconhecido: ${type}`);
                            break;
                    }

                    if (onCheckContact){
                        action ={
                            type: 'checkContact',
                            title: "Checar Contato",
                            message: "Chegar se é o primeiro contato, caso contrario responder com o nome do contato.",
                            action: [],
                        }
                    }

                    node.data = {
                        ...node.data,
                        type: type,
                        message: message,
                        options: newOptions,
                        nodeId:  node.id,
                        media: media,
                        onClickEdit: handleEditNode,
                        onClickDuplicate: handleDuplicateNode,
                        onClickDelete: handleDeleteNode,
                        action: action,

                    };
                }

                return node;
            })
        );


        clearVariables()



    };



    const onAddNode = () => {

        if(isNodeEdit){

            onEdit()
            setIsNodeEdit(false)
        }else{
            const id = getNodeId(); // Obtenha um novo ID único para o nó
            let media = {}
            let message = ""
            let  action = {}
            let  type = enumTypeFlowNode[selectedOption] || 'message'
            let  newOptions = {}



            if (textareaValue){
                message = textareaValue
            }
            if(textAreas[textAreas.length - 1].value !== ''  ){
                newOptions =  textAreas
            }
            if(uploadFile){
                media = {
                    type: typeFile,
                    src: uploadFile,
                    viewFile: "",
                    uploadFileRoute: uploadFileRoute,
                    viewFileName: selectedFileName,
                }
            }

            switch (type) {
                case 'trigger':
                    action = createAction.trigger(textTriggerValue, bot_id, instance_id);
                    break
                case 'finish':
                    action = createAction.finish(textFinishValue);
                    break
                case 'redirect':
                    if (onDepartment) {
                        action = createAction.redirect('departamento', {
                            type_redirect: 'DEPARTMENT',
                            department_id: whichDepartment[1],
                            department_name: whichDepartment[0],
                            department_avatar: whichDepartment[2],
                        });
                    } else if (onConnectBot) {
                        action = createAction.redirect('chatbot', {
                            type_redirect: 'CONNECT_BOT',
                            bot_id: selectedOptionChatBot[1],
                            bot_name: selectedOptionChatBot[0],
                            bot_avatar: selectedOptionChatBot[2],
                        });
                    } else if (onUser) {
                        action = createAction.redirect('usuário', {
                            type_redirect: 'USER',
                            user_id: selectedOptionUser[1],
                            user_name: selectedOptionUser[0],
                            user_avatar: selectedOptionUser[2],
                            user_role: selectedOptionUser[3],
                            user_job: '',
                        });
                    }
                    break;
                case 'notify':
                    action = createAction.notify(selectedUsers, selectedUsersNames, selectedUsersAvatar);
                    break;
                case 'files':
                    action = createAction.files(typeFile, uploadFile, uploadFileRoute, selectedFileName,image_doc_url);
                    break;
                case 'smart_delay':
                    action = createAction.smartDelay(delayValue);
                    break
                case 'tag':
                    action = createAction.tag(process_status, queryType, selectedProcessStatus, selectedQueryType);
                    break;
                case 'list':
                    action = createAction.list(textListValue, textListareaValue, textListAreas);
                    break;
                case 'buttons':
                    action = createAction.buttons(textListValue, textListareaValue, textButtonsAreas, selectTypeButtons,textPhoneValue,selectedPix, textPixValue , textPixNameValue);
                    break;
                case 'webhook':
                    action = createAction.webhook(textListValue, textListareaValue, newOptions, phoneValue);
                    break;
                case 'calendar':
                    action = createAction.calendar(textListValue, textListareaValue, textListAreas);
                    break;
                case 'order_note':
                    action = createAction.order_note(textListValue, textListareaValue, textListAreas);
                    break;
                case 'input':
                    action = createAction.input(handleInputSelected, inputTextSelected);
                    break;
                default:
                    console.error(`Tipo de ação desconhecido: ${type}`);
                    break;
            }




            if(type !== ""){
                const newNode = {
                    id,
                    type: 'custom',
                    data: { message: message,
                        options: newOptions,
                        nodeId:  id,
                        media: media,
                        action: action,
                        onClickEdit: handleEditNode,
                        onClickDuplicate: handleDuplicateNode,
                        onClickDelete: handleDeleteNode,
                        type: type

                    }, // Use o estado options aqui
                    position: {
                        x: 0,
                        y: 0 + (nodes.length + 1) * 20
                    }
                };
                setNodes((prevNodes) => [...prevNodes, newNode]);

            }



            clearVariables()



            setTypeFile("");
            setEditState([]);
            setState([]);
            setIdState([]);
            setOptions([]);
            setSelectedOptionUser([]);
            setSelectedOptionChatBot([]);
            setWhichDepartment([]);
            setSelectedOptionBot([]);
            setUploadFile(null);
            setSelectedFile("");
            setSelectedFileName("");
            setSelectedFileSubmit(false);
            setNodeEdit(null);
            setOnClose(false);
            setOnShooting(false);
            setOnDepartment(false)
            setOnRedirect(false)
            setOnConnectBot(false)
            setOpenOptionsSelectedUser(false)

            setOnTag(false);
            setOpenOptionsSelectedProcessStatus(false)
            setSelectedQueryType('')
            setSelectedProcessStatus('')
            setSearchTerm("")
            setSelectedUsers([])
            setSelectedUsersNames([])
            setSelectedUsersAvatar([])
            setSelectTypeButtons({})
        }



    };

    const onAddCollectInputNode = () =>{
        onAddNode()
    }

    useEffect(() => {
        let timerId;
        if (selectedFile) {
            setSeconds(15); // Reinicia o cronômetro sempre que um novo arquivo for selecionado
            setIsButtonDisabled(true); // Desabilita o botão
            timerId = setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds <= 1) {
                        clearInterval(timerId);
                        setIsButtonDisabled(false);
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }
        return () => clearInterval(timerId);
    }, [selectedFile]);

    const toggleExpand = (id) => {
        setExpandedTextAreas((prevState) => ({
            ...prevState,
            [id]: !prevState[id], // Alterna o estado expandido do textarea específico
        }));
    };

    const handleInputSelecting = (label) => {
        setInputSelected((prevState) => {
            // Verifique se o input já está selecionado
            const isCurrentlySelected = prevState[label];

            // Se já estiver selecionado, desmarque todos
            if (isCurrentlySelected) {
                return {
                    textInput: false,
                    phoneInput: false,
                    emailInput: false,
                    dateInput: false,
                    imageInput: false,
                    nameInput: false,
                };
            } else {
                // Caso contrário, marque apenas o input selecionado
                return {
                    textInput: label === 'textInput',
                    phoneInput: label === 'phoneInput',
                    emailInput: label === 'emailInput',
                    dateInput: label === 'dateInput',
                    imageInput: label === 'imageInput',
                    nameInput: label === 'nameInput',
                };
            }
        });
    };
    const  handleInputSelected = ()=> {
        return Object.keys(inputSelected).find(key => inputSelected[key]);

    }


    return (
        <div>
            <div className="row">
                <div className="col-xl-12 col-md-12">
                    <div className="modal custom-modal fade" id="upload_arquivo" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="form-header">
                                        <h4 className="modal-title">Enviar Arquivo</h4>
                                        <button type="button" className="close"
                                                data-dismiss="modal">&times;</button>

                                        <div className="user-pic text-center">
                                            <div className="avatar ">

                                                {typeFile === "audio" && selectedFile &&
                                                    <div className="pro-user-username  fs-14"
                                                         style={{marginTop: "10%"}}>
                                                        <audio src={selectedFile} controls/>
                                                    </div>

                                                }
                                                {typeFile === "image" && selectedFile &&
                                                    <img style={{filter: "none"}} width={230} height={230} src={selectedFile}
                                                         alt="Preview"/>
                                                }
                                                {typeFile === "video" && selectedFile &&
                                                    <video  style={{filter: "none"}} width={230} height={230} src={selectedFile}
                                                           controls/>
                                                }

                                                {typeFile === "application" && selectedFile &&
                                                    <img   style={{filter: "none"}}width={230} height={230} src={image_doc_url}
                                                         alt="Word Document"></img>
                                                }

                                            </div>

                                            <div className="pro-user mt-3">
                                                <h5 className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span">
                                                    {/*selectedNameFile*/}
                                                </h5>
                                                <h6 className="pro-user-desc text-muted fs-14"></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-btn  text-center">


                                        <div className="form-group">
                                            <label>Upload</label>
                                            <input className="form-control" accept={`${typeFile}/*`}
                                                   id="input_file_update" type="file"
                                                   onChange={handleFileChange}/>
                                        </div>

                                        <div>
                                            {selectedFile && (
                                                <button
                                                    onClick={handleSubmit}
                                                    disabled={isButtonDisabled}
                                                    style={{
                                                        padding: "0.8rem 2rem",
                                                        border: "none",
                                                        backgroundColor: isButtonDisabled ? "" : "#3c21f7",
                                                        marginLeft: "15px",
                                                        fontSize: "1rem",
                                                        cursor: "pointer",
                                                        color: isButtonDisabled ? "#3c21f7" : "white",
                                                        borderRadius: "5px",
                                                        fontWeight: "bold",
                                                        transition: "all 300ms ease-in-out",
                                                        transform: "translateY(0)"
                                                    }}
                                                >
                                                    {isButtonDisabled ? `Espere ${seconds}s` : "Confirmar"}
                                                </button>
                                            )}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal custom-modal fade" id="form_upload_arquivo" role="dialog">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="form-header">
                                        <h4 className="modal-title">Enviar Arquivo</h4>
                                        <button type="button" className="close"
                                                data-dismiss="modal">&times;</button>

                                        <div className="user-pic text-center">
                                            <div className="avatar ">

                                                {typeFile === "image" && selectedFile &&
                                                    <img  style={{filter: "none"}} width={230} height={230} src={selectedFile}
                                                         alt="Preview"/>
                                                }
                                                {typeFile === "video" && selectedFile &&
                                                    <video width={230} height={230} src={selectedFile}
                                                           controls/>
                                                }

                                            </div>

                                            <div className="pro-user mt-3">
                                                <h5 className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span">
                                                    {/*selectedNameFile*/}
                                                </h5>
                                                <h6 className="pro-user-desc text-muted fs-14"></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-btn  text-center">


                                        <div className="form-group">
                                            <label>Upload</label>
                                            <input className="form-control" accept="image/*, video/*"
                                                   id="input_file_update" type="file"
                                                   onChange={handleFileChange}/>
                                        </div>

                                        <div>
                                            {selectedFile && (
                                                <button
                                                    onClick={handleFileSubmit}
                                                    disabled={isButtonDisabled}
                                                    style={{
                                                        padding: "0.8rem 2rem",
                                                        border: "none",
                                                        backgroundColor: isButtonDisabled ? "" : "#3c21f7",
                                                        marginLeft: "15px",
                                                        fontSize: "1rem",
                                                        cursor: "pointer",
                                                        color: isButtonDisabled ? "#3c21f7" : "white",
                                                        borderRadius: "5px",
                                                        fontWeight: "bold",
                                                        transition: "all 300ms ease-in-out",
                                                        transform: "translateY(0)"
                                                    }}
                                                >
                                                    {isButtonDisabled ? `Espere ${seconds}s` : "Confirmar"}
                                                </button>
                                            )}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-xl-12">
                            <div id="toolbar">
                                <div onClick={openToggle}
                                     className={`button ${isButtonActive ? 'active' : ''}`}></div>
                                <div className={`icons ${iconsOpen ? 'open' : ''}`}>
                                    <div className="flow-list__container">
                                        <div onClick={() => showOption(1)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="#EC5858">
                                                <path
                                                    d="M12.7886 3.4931C12.4671 2.83563 11.5329 2.83563 11.2114 3.4931L8.86562 8.29027L3.73049 9.17547C3.04016 9.29447 2.75957 10.1365 3.23978 10.6481L6.92912 14.5785L6.142 19.9897C6.03817 20.7035 6.78644 21.2332 7.42197 20.8958L12 18.4657L16.578 20.8958C17.2136 21.2332 17.9618 20.7035 17.858 19.9897L17.0709 14.5785L20.7602 10.6481C21.2404 10.1365 20.9598 9.29447 20.2695 9.17547L15.1344 8.29027L12.7886 3.4931Z"
                                                    fill="#EC5858"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Etiquetar</div>
                                        </div>

                                        { type_bot !== "auto-response" && <div onClick={() => showOption(2)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M17.1533 12.9488L14.5147 11.0708L16.6292 3.56061C16.736 3.20918 16.6038 2.82589 16.3074 2.62696C16.0117 2.42866 15.6269 2.46581 15.3716 2.7163L6.76406 10.7191C6.57601 10.8895 6.47903 11.1475 6.50382 11.409C6.52779 11.6698 6.67069 11.9035 6.88725 12.0348L9.43482 13.5797L7.06044 20.3991C6.94655 20.7336 7.05207 21.1072 7.32175 21.3222L7.343 21.3382C7.60975 21.5484 7.97294 21.5545 8.24477 21.3525L17.2053 14.277C17.4008 14.1174 17.5104 13.8662 17.4992 13.6046C17.4967 13.3392 17.3668 13.092 17.1533 12.9488Z"
                                                    fill="#F5B715"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Gatilho</div>
                                        </div>}
                                        <div onClick={() => showOption(3)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="#5175F5">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V7.99999C19 8.26521 18.8946 8.51956 18.7071 8.70709L14 13.4142V17C14 17.2652 13.8946 17.5196 13.7071 17.7071L11.7071 19.707C11.4211 19.993 10.991 20.0786 10.6173 19.9238C10.2436 19.769 10 19.4045 10 19V13.4142L5.29289 8.70709C5.10536 8.51956 5 8.26521 5 7.99999V5Z"
                                                      fill="#5175F5"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Finalizar</div>
                                        </div>
                                        <div onClick={() => showOption(4)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="#1FBADC">
                                                <path
                                                    d="M16.7017 10.7013C16.9145 10.9036 17.1696 11.0161 17.4248 11.0161C17.7015 11.0161 17.999 10.9036 18.191 10.6556L20.9993 7.50352L18.2125 4.35239C17.8086 3.9021 17.1271 3.87923 16.7016 4.30754C16.2761 4.73495 16.2544 5.45614 16.6592 5.90643L17.0847 6.37959C15.6594 6.44731 14.3188 7.12275 13.3614 8.22561L11.5953 10.3188L9.82921 8.2476C8.80779 7.05415 7.38246 6.37872 5.87228 6.37872H4.06381C3.4679 6.37872 3 6.87386 3 7.50444C3 8.13503 3.4679 8.63017 4.06381 8.63017H5.87228C6.76571 8.63017 7.63837 9.03561 8.23435 9.7559L10.1492 12.0073L8.23435 14.2588C7.63845 14.9791 6.76664 15.3845 5.87228 15.3845H4.06381C3.4679 15.3845 3 15.8797 3 16.5103C3 17.1408 3.4679 17.636 4.06381 17.636H5.87228C7.38322 17.636 8.8086 16.9605 9.83007 15.7671L11.5962 13.6959L13.3622 15.7671C14.3197 16.8928 15.6603 17.5683 17.0856 17.6131L16.66 18.0863C16.2561 18.5366 16.2769 19.2568 16.7024 19.6852C16.9152 19.8874 17.1703 20 17.4255 20C17.7022 20 17.9998 19.8874 18.1918 19.6394L21 16.4874L18.2124 13.3583C17.8085 12.908 17.127 12.8851 16.7015 13.3135C16.2759 13.7409 16.2543 14.4621 16.6591 14.9123L17.063 15.3626C16.2543 15.2949 15.4889 14.9123 14.9354 14.2598L13.0205 12.0083L14.9354 9.75688C15.4889 9.10432 16.2543 8.72085 17.063 8.65403L16.6591 9.10432C16.2759 9.55373 16.2759 10.274 16.7015 10.7015L16.7017 10.7013Z"
                                                    fill="#1FBADC"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Redirecionar</div>
                                        </div>
                                        <div onClick={() => showOption(5)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M14.4192 11.4071H14.5061C14.8807 11.4083 15.232 11.2251 15.4465 10.9178C15.661 10.6106 15.712 10.2187 15.5833 9.86619C15.0569 8.44235 13.8418 7.38491 12.3601 7.06027C10.8771 6.73445 9.33033 7.18782 8.25789 8.26149C7.1842 9.33517 6.73201 10.8819 7.05781 12.3636C7.38364 13.8466 8.44225 15.0617 9.86608 15.5856C10.2186 15.7155 10.6116 15.6645 10.9189 15.4488C11.2262 15.2332 11.4082 14.8807 11.407 14.5062V14.4192C11.3954 13.9357 11.0917 13.5067 10.6383 13.3363C9.83827 13.0174 9.31766 12.2383 9.32923 11.3767C9.35938 10.259 10.2591 9.35926 11.3769 9.32914C12.2384 9.31522 13.0199 9.83466 13.3399 10.6347C13.508 11.088 13.9359 11.3941 14.4194 11.4069L14.4192 11.4071Z"
                                                    fill="#F169D3"></path>
                                                <path
                                                    d="M17.863 10.4249C17.9442 10.9873 18.4253 11.4058 18.9935 11.407H19.0805C19.4086 11.4082 19.7217 11.269 19.9385 11.0232C20.1565 10.7774 20.2573 10.4504 20.2168 10.1246C19.8991 7.9344 18.7755 5.94 17.0677 4.53246C15.3598 3.12486 13.1882 2.40367 10.9768 2.51035C8.76565 2.61586 6.67277 3.54229 5.1076 5.10756C3.5423 6.67283 2.61587 8.76562 2.51035 10.9767C2.40367 13.1878 3.12487 15.3596 4.53249 17.0675C5.94011 18.7754 7.93444 19.8989 10.1247 20.2165C10.4494 20.2571 10.7764 20.1562 11.0222 19.9394C11.268 19.7226 11.4083 19.4107 11.4071 19.0826V18.9968C11.406 18.4286 10.9874 17.9463 10.425 17.8663C8.82496 17.6216 7.37335 16.7938 6.34959 15.5404C5.32577 14.287 4.80516 12.6985 4.88631 11.0821C4.96746 9.46564 5.64578 7.93753 6.78904 6.79327C7.93346 5.64886 9.46165 4.97059 11.0767 4.88825C12.693 4.80593 14.2816 5.3277 15.536 6.35035C16.7894 7.373 17.6184 8.82472 17.863 10.4249Z"
                                                    fill="#F169D3"></path>
                                                <path
                                                    d="M17.5394 15.8595H19.7181C20.0544 15.8606 20.3755 15.7203 20.6016 15.4722C20.8277 15.2229 20.9379 14.8913 20.9054 14.5562C20.8277 13.9348 20.2932 13.4721 19.6671 13.4849H15.8594C14.548 13.4849 13.4847 14.5481 13.4847 15.8595V19.6671C13.472 20.2933 13.9346 20.8278 14.5561 20.9055C14.8912 20.9379 15.2228 20.8278 15.4721 20.6017C15.7202 20.3756 15.8605 20.0544 15.8594 19.7182V17.5395L19.4724 21.1513C19.9362 21.6162 20.6875 21.6162 21.1513 21.1513C21.6162 20.6875 21.6162 19.9362 21.1513 19.4724L17.5394 15.8595Z"
                                                    fill="#F169D3"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Notificar</div>

                                        </div>
                                        <div onClick={() => showOption(6)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="#3ABA38">
                                                <path
                                                    d="M4.42402 10.7536L6.77615 11.6854C6.51086 12.3251 6.2994 12.9844 6.1434 13.6566L6.05391 14.0441L9.42601 17.2688L9.83148 17.1856V17.1848C10.5339 17.0365 11.2237 16.8343 11.8929 16.5808L12.8678 18.829L12.8686 18.8283C12.8876 18.8737 12.9295 18.907 12.9794 18.9161C13.0301 18.9251 13.0816 18.91 13.1172 18.8752L14.591 17.4665H14.5918C15.078 17.0017 15.3354 16.3613 15.3014 15.7051L15.2483 14.7997C17.9773 12.8733 20.738 9.49576 21.4903 3.60133C21.5339 3.30157 21.4286 2.99954 21.2053 2.78534C20.9819 2.57111 20.6668 2.46893 20.3531 2.50829C14.188 3.2327 10.6518 5.87446 8.63726 8.47461L7.69247 8.42844C7.00666 8.39286 6.33747 8.63509 5.84964 9.09683L4.37586 10.5055C4.33389 10.5396 4.31488 10.5918 4.32438 10.6433C4.33468 10.6948 4.37269 10.7364 4.42417 10.7538L4.42402 10.7536ZM13.8798 7.31924C14.4025 6.82192 15.1865 6.67431 15.8676 6.94454C16.5486 7.21477 16.9921 7.84987 16.9921 8.55459C16.9921 9.26007 16.5486 9.89517 15.8676 10.1654C15.1865 10.4356 14.4025 10.288 13.8798 9.78994C13.5361 9.46294 13.3429 9.0186 13.3429 8.55459C13.3429 8.09134 13.5361 7.647 13.8798 7.31924ZM3.98449 17.5261C3.59406 17.3951 3.17513 17.3633 2.76807 17.433C2.69442 17.4481 2.61761 17.4261 2.56534 17.3747C2.49961 17.3126 2.48139 17.2172 2.5202 17.1362C2.95259 16.2491 4.09772 14.4642 6.17187 15.9062C6.19404 15.9251 6.20592 15.9516 6.20592 15.9796C6.20592 16.0076 6.19404 16.0348 6.17187 16.053C5.60881 16.4769 5.29044 17.1317 5.3142 17.8167C5.31736 17.8992 5.38626 17.9651 5.47258 17.9681C6.18532 17.9954 6.87034 17.6979 7.31701 17.1658C7.33681 17.1423 7.36611 17.1294 7.397 17.1294C7.42867 17.1294 7.45797 17.1423 7.47777 17.1658C7.76604 17.4935 8.55797 18.5631 7.68051 19.5956C7.29722 20.0361 6.7476 20.3147 6.15128 20.3707C5.29995 20.4577 3.72717 20.7234 3.08409 21.4251C3.03182 21.4842 2.94867 21.5114 2.86947 21.4955C2.78949 21.4789 2.72534 21.4221 2.70317 21.3472C2.47588 20.6069 2.12347 18.9522 3.98452 17.526L3.98449 17.5261Z"
                                                    fill="#3ABA38"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Mensagem</div>
                                        </div>

                                        <div onClick={() => showOption(7)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M12 3.5C9.7455 3.5 7.58371 4.39507 5.98889 5.9897C4.39507 7.58344 3.5 9.74513 3.5 11.9995C3.5 14.2539 4.39512 16.4165 5.98889 18.0103C7.58364 19.604 9.74548 20.5 12 20.5C14.2545 20.5 16.4163 19.604 18.0111 18.0103C19.6049 16.4166 20.5 14.2539 20.5 11.9995C20.4981 9.74608 19.6012 7.58633 18.0073 5.99255C16.4144 4.39882 14.2536 3.50285 12 3.5ZM15.6278 15.8838C15.4397 16.0978 15.17 16.2201 14.8865 16.2201C14.6428 16.222 14.4065 16.1339 14.2239 15.9727L11.3477 13.4228C11.1345 13.2356 11.0122 12.9659 11.0113 12.6815V7.74004C11.0113 7.19428 11.4542 6.75137 12 6.75137C12.5458 6.75137 12.9887 7.19428 12.9887 7.74004V12.1978L15.5388 14.4513C15.7473 14.6255 15.8743 14.8775 15.8919 15.1481C15.9086 15.4195 15.8131 15.6855 15.6278 15.8838Z"
                                                    fill="#cd6a41"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Atraso inteligente</div>
                                        </div>

                                        <div onClick={() => showOption(8)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5.22252 3.5C4.29567 3.5 3.53527 4.26318 3.53527 5.19422V9.39727C3.53527 10.3276 4.29354 11.0985 5.22252 11.0985H9.3306C10.2596 11.0985 11.0249 10.3304 11.0249 9.39727V5.19422C11.0249 4.26105 10.2575 3.5 9.3306 3.5H5.22252Z"
                                                      fill="#683AC8"></path>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M14.7047 3.5C13.7778 3.5 13.0104 4.26105 13.0104 5.19422V9.39727C13.0104 10.3304 13.7757 11.0985 14.7047 11.0985H18.8127C19.7417 11.0985 20.5 10.3283 20.5 9.39727V5.19422C20.5 4.26389 19.7396 3.5 18.8127 3.5H14.7047Z"
                                                      fill="#683AC8"></path>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M5.1943 12.9015C4.26533 12.9015 3.5 13.6696 3.5 14.6027V18.8058C3.5 19.7382 4.26744 20.5 5.1943 20.5H9.30237C10.2292 20.5 10.9896 19.7368 10.9896 18.8058V14.6027C10.9896 13.6724 10.2314 12.9015 9.30237 12.9015H5.1943Z"
                                                      fill="#683AC8"></path>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M14.6477 12.9015C13.7187 12.9015 12.9534 13.6696 12.9534 14.6027V18.8058C12.9534 19.7382 13.7208 20.5 14.6477 20.5H18.7557C19.6826 20.5 20.443 19.7368 20.443 18.8058V14.6027C20.443 13.6724 19.6847 12.9015 18.7557 12.9015H14.6477Z"
                                                      fill="#683AC8"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Arquivos</div>
                                        </div>
                                        <div onClick={() => showOption(9)} className="flow-list__item">
                                            <i className='bx bx-copy-alt'
                                            style={{margin: "0px 0px 0px 1px", fontSize: 24,color: "#ffaa05", width: 24, height: 24}}>
                                        </i>
                                        <div className="flow-list__item-text">Coletar Dados</div>
                                        </div>
                                        <div onClick={() => showOption(10)} className="flow-list__item">
                                            <i className='bx bx-list-ul'
                                            style={{
                                            margin: "0px 0px 0px 1px",
                                            fontSize: 24,
                                            color: "#a725b5",
                                            width: 24,
                                            height: 24
                                        }}>
                                        </i>
                                        <div className="flow-list__item-text">Listagem</div>
                                        </div>
                                        {/*} <div onClick={() => showOption(11)} className="flow-list__item">
                                            <i className='bx bx-select-multiple'
                                            style={{margin: "0px 0px 0px 1px", fontSize: 24,color: "#2258aa", width: 24, height: 24}}>
                                        </i>
                                        <div className="flow-list__item-text">Botões</div>
                                        </div>*/}
                                        <div onClick={() => showOption(12)} className="flow-list__item">
                                            <i className='bx bxs-share-alt'
                                               style={{margin: "0px 0px 0px 1px", fontSize: 24,color: "#423073", width: 24, height: 24}}>
                                            </i>
                                            <div className="flow-list__item-text">Webhook</div>
                                        </div>
                                        {/*<div onClick={() => showOption(13)} className="flow-list__item">
                                            <i className='bx bx-calendar-event'
                                                style={{
                                                margin: "0px 0px 0px 1px",
                                                fontSize: 24,
                                                color: "#a725b5",
                                                width: 24,
                                                height: 24
                                            }}>
                                        </i>
                                        <div className="flow-list__item-text">Agendamento</div>
                                        </div>*/}
                                        {/* <div onClick={() => showOption(14)} className="flow-list__item">
                                            <i className='bx bx-receipt'
                                               style={{margin: "0px 0px 0px 1px", fontSize: 24,color: "#134204", width: 24, height: 24}}>
                                            </i>
                                            <div className="flow-list__item-text">Gerar Ordem de Serviço</div>
                                        </div>*/}


                                        {/*<div onClick={() => showOption(11)} className="flow-list__item">
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path
                                                    d="M12 3.5C9.7455 3.5 7.58371 4.39507 5.98889 5.9897C4.39507 7.58344 3.5 9.74513 3.5 11.9995C3.5 14.2539 4.39512 16.4165 5.98889 18.0103C7.58364 19.604 9.74548 20.5 12 20.5C14.2545 20.5 16.4163 19.604 18.0111 18.0103C19.6049 16.4166 20.5 14.2539 20.5 11.9995C20.4981 9.74608 19.6012 7.58633 18.0073 5.99255C16.4144 4.39882 14.2536 3.50285 12 3.5ZM15.6278 15.8838C15.4397 16.0978 15.17 16.2201 14.8865 16.2201C14.6428 16.222 14.4065 16.1339 14.2239 15.9727L11.3477 13.4228C11.1345 13.2356 11.0122 12.9659 11.0113 12.6815V7.74004C11.0113 7.19428 11.4542 6.75137 12 6.75137C12.5458 6.75137 12.9887 7.19428 12.9887 7.74004V12.1978L15.5388 14.4513C15.7473 14.6255 15.8743 14.8775 15.8919 15.1481C15.9086 15.4195 15.8131 15.6855 15.6278 15.8838Z"
                                                    fill="#F7953B"></path>
                                            </svg>
                                            <div className="flow-list__item-text">Template</div>
                                        </div>*/}


                                    </div>
                                </div>

                            </div>
                            <div className="box box-message">
                                <FlowChatBot loader={loader} bot_name={bot_name} bot_id={bot_id} type_bot={type_bot}
                                             instance_id={instance_id}
                                             setClickInNode={setClickInNode} onSave={onSave} setOnSave={setOnSave}
                                             setNodeEdit={setNodeEdit} nodes={nodes} edges={edges} setEdges={setEdges}
                                             onNodesChange={onNodesChange} onEdgesChange={onEdgesChange}/>
                            </div>
                            <div className={`ferramentas ${isFerramentasSlide ? 'ferramentaslide' : ''}`}
                                 ref={ferramentasRef}>

                                <div id="meu-botao" onClick={removeClass} className="fecharferramentas">
                                    <div className="fecharferramentabotao">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="#dfe5ece6"
                                             xmlns="http://www.w3.org/2000/svg"
                                             preserveAspectRatio="xMidYMid meet">
                                            <path d="M14 15.9174L10.0656 12.0496L14 8.08264V6L8 12.0496L14 18V15.9174Z"
                                                  fill="#333">
                                            </path>
                                        </svg>
                                    </div>
                                </div>

                                <div className="opcoes">
                                    <div id="opcao1" className="opcao"
                                         style={{display: selectedOption === 1 ? 'block' : 'none'}}>
                                        <h2>Etiquetas</h2>
                                        <p>Selecione a etiqueta que deseja adicionar ao contato.</p>
                                        <select className="form-select digits mb-20" id="exampleFormControlSelect9"
                                                onChange={(e) => handleOptionProcessStatusChange(e.target.value)}>
                                            <option defaultValue>Selecione o Etiqueta</option>

                                            {process_status.map((option, index) => (
                                                <option key={option[1]} value={option[1]}>{option[0]}</option>
                                            ))}

                                        </select>
                                        {openOptionsSelectedProcessStatus &&
                                            <select className="form-select digits mb-30" id="exampleFormControlSelect84"
                                                    onChange={(e) => handleOptionQueryTypeChange(e.target.value)}>
                                                <option defaultValue>Selecione a etapa</option>

                                                {queryType.map((option, index) => (
                                                    <option key={option[1]} value={option[1]}>{option[0]}</option>
                                                ))}

                                            </select>
                                        }


                                        <button className="btn btn-success btn-sm" type="button"
                                                onClick={onAddNode}>Adicionar
                                        </button>
                                    </div>

                                    <div id="opcao2" className="opcao"
                                         style={{display: selectedOption === 2 ? 'block' : 'none'}}>
                                        <h2>Gatilho</h2>
                                        <p>Por favor, adicione aqui frase ou palavra que serão usadas como gatilho para
                                            iniciar o
                                            fluxo.</p>
                                        <div>
                                            <div>
                                <textarea className="textareamensgem" placeholder="Quero falar com um especialista"
                                          value={textTriggerValue} onChange={handleTextTriggerChange}
                                          style={{height: 49}}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Salvar
                                        </button>
                                    </div>

                                    <div id="opcao3" className="opcao"
                                         style={{display: selectedOption === 3 ? 'block' : 'none'}}>


                                        <h2>Encerrar fluxo </h2>
                                        <p>Caso queira, pode deixar uma mensagem.</p>
                                        <div>
                                            <div>
                                                <textarea className="textareamensgem" placeholder="Aguarde um momento!"
                                                          value={textFinishValue} onChange={handleTextFinishChange}
                                                          style={{height: 49}}></textarea>
                                            </div>
                                        </div>
                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Salvar
                                        </button>
                                    </div>

                                    <div id="opcao4" className="opcao"
                                         style={{display: selectedOption === 4 ? 'block' : 'none'}}>
                                        <h2>Redirecionar</h2>
                                        <p>Selecione para onde deseja redirecionar o contato quando ele chegar aqui.</p>


                                        <select className="form-select digits mb-30"
                                                onChange={handleChangeRedirectType}>
                                            <option defaultValue>Selecione qual redirecionamento
                                                deseja fazer
                                            </option>

                                            {Object.keys(enumTypeRedirect).map(typeRedirect => (

                                                <option
                                                    key={typeRedirect}
                                                    value={typeRedirect}>
                                                    {enumTypeRedirect[typeRedirect]}
                                                </option>
                                            ))}

                                        </select>
                                        {onDepartment &&
                                            <select className="form-select digits" id="exampleFormControlSelect7"
                                                    onChange={handleOptionDepartmentChange}
                                            >
                                                <option defaultValue>Selecione o departamento</option>
                                                {departments.map(department => (

                                                    <option
                                                        key={department[1]}
                                                        value={department[1]}>
                                                        {department[0]}
                                                    </option>
                                                ))}
                                            </select>

                                        }
                                        {onConnectBot &&
                                            <select className="form-select digits" id="exampleFormControlSelect8"
                                                    onChange={handleOptionChatBotChange}
                                            >
                                                <option defaultValue>Selecione o chatbot</option>
                                                {chatbots.map(chatbot => (

                                                    <option
                                                        key={chatbot[1]}
                                                        value={chatbot[1]}>
                                                        {chatbot[0]}
                                                    </option>
                                                ))}
                                            </select>

                                        }
                                        {onUser &&
                                            <select className="form-select digits" id="exampleFormControlSelect9"
                                                    onChange={handleOptionUserChange}
                                            >
                                                <option defaultValue>Selecione o usuário</option>
                                                {users.map(user => (

                                                    <option
                                                        key={user.id}
                                                        value={user.id}>
                                                        {user.name}
                                                    </option>
                                                ))}
                                            </select>

                                        }
                                        {(onDepartment || onConnectBot || onUser) &&
                                            <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                    type="button">Salvar</button>

                                        }
                                    </div>

                                    <div id="opcao5" className="opcao"
                                         style={{display: selectedOption === 5 ? 'block' : 'none'}}>

                                        <h2>Notificar</h2>
                                        <p>Selecione quais usuários deseja que serão notificados quando ele chegar
                                            aqui.</p>

                                        <div className="form-group mb-30">

                                            <input
                                                type="text"
                                                className="form-input digits"
                                                placeholder="Pesquisar por nome"
                                                value={searchTerm}
                                                onChange={handleSearchChange}
                                            />
                                            <ul>
                                                {searchTerm && filteredUsers.map((user) => (
                                                    <li key={user.id}>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedUsers.includes(user.id)}
                                                                onChange={() => handleCheckboxChange(user.id, user.name, user.avatar)}
                                                                className="form-control"
                                                                style={{display: "none"}}
                                                            />
                                                            <div className="text-center">

                                                                <ImageWithFallback
                                                                    classImage={"img-fluid rounded-circle"}
                                                                    src={user.avatar}
                                                                    fallback={avatar}
                                                                    id={`image_profile-${user.name}`}
                                                                    style={{width: 50, height: 50, filter: "none"}}
                                                                    alt={`${user.name}'s avatar`}
                                                                />
                                                                <span className="user-name p-10">{user.name}</span>
                                                            </div>


                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                            {selectedUsers.length > 0 &&
                                                <div className="mt-10 ">
                                                    <div className="selected-users">
                                                        <label>Serão nofificados:</label>
                                                        <div className="avatar-list">
                                                            {selectedUsers.map((userId) => {
                                                                const selectedUser = users.find((user) => user.id === userId);
                                                                return selectedUser ? (
                                                                    <div key={selectedUser.id}>
                                                                        <img
                                                                            className="rounded-circle header-profile-user avatar"
                                                                            style={{width: 30, height: 30}}
                                                                            src={selectedUser.avatar !== null ? selectedUser.avatar : avatar}
                                                                            alt={`${selectedUser.name}'s avatar`}
                                                                        />
                                                                        <span
                                                                            className="user-name p-10">{selectedUser.name}</span>
                                                                    </div>
                                                                ) : null;
                                                            })}
                                                        </div>
                                                    </div>

                                                </div>
                                            }
                                        </div>


                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Salvar
                                        </button>


                                    </div>

                                    <div id="opcao6" className="opcao"
                                         style={{display: selectedOption === 6 ? 'block' : 'none'}}>
                                        <h2>Mensagem</h2>
                                        <p>Por favor, adicione aqui uma mensagem a ser enviada. Se preferir, adicione
                                            também
                                            respostas
                                            pré-programadas.</p>
                                        <div id="mensagem">
                                            <div id="textarea1">
                                                <textarea className="textareamensgem" id="text-area"
                                                          value={textareaValue} onChange={handleTextareaChange}
                                                          placeholder="Digite seu texto aqui..."></textarea>
                                                <div className="d-flex">
                                                    {/*<div onClick={handleAddFileVisible} className="emoji">
                                                        <span> <i className='bx bx-paperclip'></i></span>
                                                        {addFile &&
                                                            <div id="emoji-picker"
                                                                 style={{display: "block"}}>{renderEmojiPicker()}</div>}

                                                    </div>*/}
                                                    {/*  <div onClick={handleAddInfoVisible} className="emoji">
                                                        <span> <i className='bx bx-comment-add'></i></span>
                                                        {addInfo &&
                                                            <div id="emoji-picker"
                                                                 style={{display: "block"}}>{renderGetInfo()}</div>}

                                                    </div>*/}

                                                    {!uploadFile &&
                                                        <div data-toggle="modal"
                                                             data-target="#form_upload_arquivo" className="emoji">
                                                            <span> <i className='bx bx-image-add'></i></span>

                                                        </div>
                                                    }


                                                    <div onClick={handleEmojiPickerVisible} className="emoji">
                                                        <span>🙂</span>
                                                        {isEmojiPickerVisible &&
                                                            <div id="emoji-picker"
                                                                 style={{display: "block"}}>{renderEmojiPicker()}</div>}

                                                    </div>
                                                </div>

                                                {uploadFile && <div>
                                                    <p>Arquivo:</p>
                                                    <div id={`input_file`}
                                                         className=" mb-30 proximotextareaflex">

                                                        <input
                                                            type="text"
                                                            disabled
                                                            value={selectedFileName}
                                                            placeholder={selectedFileName}
                                                            className="proximostextarea textareamensgem"
                                                        />
                                                        <div className="buttonGroup">
                                                            <button className="deleteButton"
                                                                    onClick={handleRemoveFileChange}>Apagar
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>

                                            <p>Opções:</p>
                                            {textAreas.map((textArea) => (
                                                <div key={textArea.id} id={`textarea${textArea.id}`}
                                                     className="proximotextareaflex">

                                                    <input
                                                        type="text"
                                                        placeholder="Digite seu texto aqui..."
                                                        className="proximostextarea textareamensgem"
                                                        value={textArea.value}
                                                        onChange={(e) => handleTextAreaChange(textArea.id, e)}
                                                    />
                                                    <div className="buttonGroup">
                                                        <button className="deleteButton"
                                                                onClick={() => handleDeleteTextArea(textArea.id)}>Apagar
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="botaoadd" id="addButton" onClick={handleAddTextArea}
                                                disabled={textAreas.length >= maxTextAreas}>Adicionar
                                            Resposta
                                        </button>
                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Salvar
                                        </button>
                                    </div>

                                    <div id="opcao7" className="opcao"
                                         style={{display: selectedOption === 7 ? 'block' : 'none'}}>
                                        <h2>Atraso de envio</h2>
                                        <p>Por favor, selecione um valor em segundos de atraso deseja adicionar para que
                                            a próxima
                                            mensagem seja enviada.</p>
                                        <div className="slider-container">
                                            <input type="range" min="2" max="30" value={delayValue}
                                                   onChange={handleDelayChange}
                                                   className="slider" id="rangeSlider"/>
                                            <p className="slider-value"><span id="sliderValue">{delayValue}</span>s</p>
                                        </div>
                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Adicionar atraso
                                        </button>
                                    </div>

                                    <div id="opcao8" className="opcao"
                                         style={{display: selectedOption === 8 ? 'block' : 'none'}}>
                                        <h2>Anexos</h2>
                                        <p>Selecione qual tipo de arquivo você deseja adicionar.</p>
                                        <div className="arquivosanexo mb-30">
                                            <div className="anexo" data-toggle="modal"
                                                 data-target="#upload_arquivo"
                                                 onClick={(e) => handleFileTypeChange("image")}>
                                                <img   style={{filter: "none"}} width="40" height="40"
                                                     src={image_image_url}
                                                     alt="Image"/>
                                                <p className="color-2 mb-0 pt-4">Imagem</p>
                                            </div>
                                            <div className="anexo" data-toggle="modal"
                                                 data-target="#upload_arquivo"
                                                 onClick={(e) => handleFileTypeChange("application")}>
                                                <img width="40" height="40"
                                                     style={{filter: "none"}}
                                                     src={image_doc_url}
                                                     alt="Documento"/>
                                                <p className="color-3 mb-0 pt-4">Documento</p>
                                            </div>
                                            <div className="anexo" data-toggle="modal"
                                                 data-target="#upload_arquivo"
                                                 onClick={(e) => handleFileTypeChange("audio")}>
                                                <img width="40" height="40"
                                                     style={{filter: "none"}}
                                                     src={image_audio_url}
                                                     alt="Audio"/>
                                                <p className="color-1 mb-0 pt-4">Audio</p>
                                            </div>
                                            <div className="anexo" data-toggle="modal"
                                                 data-target="#upload_arquivo"
                                                 onClick={(e) => handleFileTypeChange("video")}>
                                                <img width="40" height="40"
                                                     style={{filter: "none"}}
                                                     src={image_video_url}
                                                     alt="Video"/>
                                                <p className="color-9 mb-0 pt-4">Video</p>
                                            </div>


                                        </div>
                                        {selectedFileSubmit && <div id="mensagem">
                                            <div id={`input_file`}
                                                 className="proximotextareaflex">
                                                <input
                                                    type="text"
                                                    placeholder={selectedFileName}
                                                    disabled
                                                    className="proximostextarea textareamensgem"

                                                />
                                                <div className="buttonGroup">
                                                    <button className="deleteButton"
                                                            onClick={handleRemoveFileChange}> Apagar
                                                    </button>
                                                </div>
                                            </div>

                                        </div>}

                                        {selectedFileSubmit &&

                                            <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                    type="button">Salvar
                                            </button>}

                                    </div>

                                    <div id="opcao9" className="opcao"
                                         style={{display: selectedOption === 9 ? 'block' : 'none'}}>
                                        <h2>Coletar</h2>
                                        <p>Por favor, selecione o input do cliente que deseja coletar</p>


                                        <p>Opções:</p>
                                        <div className="inputs-menu">

                                            <TextInputNode label={'textInput'} inputTextSelected={inputTextSelected}
                                                           handleInputTextChange={handleInputTextChange}
                                                           inputSelected={inputSelected}
                                                           handleInputSelecting={handleInputSelecting}
                                                           onAddCollectInputNode={onAddCollectInputNode}></TextInputNode>
                                            <PhoneInputNode label={'phoneInput'} inputSelected={inputSelected}
                                                            handleInputSelecting={handleInputSelecting}></PhoneInputNode>
                                            <NameInputNode label={'nameInput'} inputSelected={inputSelected}
                                                           handleInputSelecting={handleInputSelecting}></NameInputNode>
                                            <EmailInputNode label={'emailInput'} inputSelected={inputSelected}
                                                            handleInputSelecting={handleInputSelecting}></EmailInputNode>
                                            <DateInputNode label={'dateInput'} inputSelected={inputSelected}
                                                           handleInputSelecting={handleInputSelecting}></DateInputNode>

                                        </div>


                                        <button
                                            style={{display: Object.values(inputSelected).some(value => value === true) && handleInputSelected() !== "textInput" ? 'block' : 'none'}}
                                            className="btn btn-success btn-sm" onClick={onAddCollectInputNode}
                                            type="button">Salvar
                                        </button>
                                    </div>
                                    <div id="opcao10" className="opcao"
                                         style={{
                                             display: selectedOption === 10 ? 'block' : 'none',
                                             maxHeight: '780px',
                                             overflowY: 'auto'
                                         }}>
                                        <h2>Listagem</h2>
                                        <p>Por favor, preencha os campos e adicione os itens da lista que você deseja
                                            que o cliente selecione.</p>
                                        <div id="mensagem">
                                            <p>*Titulo da Listagem:</p>
                                            <input
                                                type="text"
                                                placeholder="Digite seu texto aqui..."
                                                value={textListValue}
                                                onChange={handleTextListChange}
                                                className="proximostextarea textareamensgem mb-10"
                                            />

                                            <p>*Descrição da Listagem:</p>
                                            <div id="textarea1">
                                                <textarea className="textareamensgem" id="text-area"
                                                          value={textListareaValue} onChange={handleTextListareaChange}
                                                          placeholder="Digite seu texto aqui..."></textarea>

                                                <div className="d-flex">
                                                    <div onClick={handleEmojiPickerVisible} className="emoji">
                                                        <span>🙂</span>
                                                        {isEmojiPickerVisible &&
                                                            <div id="emoji-picker"
                                                                 style={{display: "block"}}>{renderEmojiPicker()}</div>}

                                                    </div>
                                                </div>

                                            </div>

                                            <p>Lista:</p>
                                            {textListAreas.map((textArea) => (
                                                <div key={textArea.id} id={`textarea${textArea.id}`}>

                                                    <p>*Titulo do item:</p>
                                                    <div className="proximotextareaflex">

                                                        <input
                                                            type="text"
                                                            placeholder="Digite seu texto aqui..."
                                                            className="proximostextarea textareamensgem"
                                                            value={textArea.value}
                                                            onChange={(e) => handleTextListAreaChange(textArea.id, e)}
                                                        />
                                                        <div className="buttonGroup">
                                                            <button className="deleteButton"
                                                                    onClick={() => handleDeleteTextListArea(textArea.id)}>Apagar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div>

                                                        <button className="botaoexpand mb-10"
                                                                onClick={() => toggleExpand(textArea.id)}>
                                                            {expandedTextAreas[textArea.id] ? (
                                                                <>
                                                                    Esconder <i className='bx bx-chevron-up'></i>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Expandir <i className='bx bx-chevron-down'></i>
                                                                </>
                                                            )}
                                                        </button>

                                                        {expandedTextAreas[textArea.id] && ( // Renderiza a textarea se expandido
                                                            <div className=" mb-10">
                                                                <p>Descrição do item:</p>
                                                                <textarea
                                                                    className="textareamensgem"
                                                                    id="text-area"
                                                                    value={textArea.description}
                                                                    onChange={(e) => handleDescriptionListAreaChange(textArea.id, e)}
                                                                    placeholder="Digite seu texto aqui..."
                                                                ></textarea>
                                                            </div>
                                                        )}


                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="botaoadd mb-20" id="addButton"
                                                onClick={handleAddTextListArea}
                                                disabled={textListAreas.length >= maxTextAreas}>Adicionar Item
                                        </button>

                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Salvar
                                        </button>
                                    </div>
                                    <div id="opcao11" className="opcao"
                                         style={{
                                             display: selectedOption === 11 ? 'block' : 'none',
                                             maxHeight: '780px',
                                             overflowY: 'auto'
                                         }}>
                                        <h2>Botões</h2>
                                        <p>Por favor, preencha os campos e adicione os botões que você deseja
                                            que o cliente selecione.</p>
                                        <div id="mensagem">


                                            <p>*Titulo do Template:</p>
                                            <input
                                                type="text"
                                                placeholder="Digite seu texto aqui..."
                                                value={textListValue}
                                                onChange={handleTextListChange}
                                                className="proximostextarea textareamensgem mb-10"
                                                required
                                            />

                                            <p>*Descrição da Listagem:</p>
                                            <div id="textarea1">
                                                <textarea className="textareamensgem" id="text-area"
                                                          value={textListareaValue} onChange={handleTextListareaChange}
                                                          placeholder="Digite seu texto aqui..."></textarea>

                                                <div className="d-flex">
                                                    <div onClick={handleEmojiPickerVisible} className="emoji">
                                                        <span>🙂</span>
                                                        {isEmojiPickerVisible &&
                                                            <div id="emoji-picker"
                                                                 style={{display: "block"}}>{renderEmojiPicker()}</div>}

                                                    </div>
                                                </div>

                                            </div>


                                            <p>Botões:</p>
                                            {textButtonsAreas.map((textArea) => (
                                                <div key={textArea.id} id={`textarea${textArea.id}`}>

                                                    <p>*Titulo do item:</p>
                                                    <div className="proximotextareaflex">

                                                        <input
                                                            type="text"
                                                            placeholder="Digite seu texto aqui..."
                                                            className="proximostextarea textareamensgem"
                                                            value={textArea.value}
                                                            onChange={(e) => handleTextButtonAreaChange(textArea.id, e)}
                                                        />
                                                        <div className="buttonGroup">
                                                            <button className="deleteButton"
                                                                    onClick={() => handleDeleteTextButtonsArea(textArea.id)}>Apagar
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div>

                                                        <button className="botaoexpand mb-10"
                                                                onClick={() => toggleExpand(textArea.id)}>
                                                            {expandedTextAreas[textArea.id] ? (
                                                                <>
                                                                    Esconder <i className='bx bx-chevron-up'></i>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Expandir <i className='bx bx-chevron-down'></i>
                                                                </>
                                                            )}
                                                        </button>

                                                        {expandedTextAreas[textArea.id] && ( // Renderiza a textarea se expandido
                                                            <React.Fragment>
                                                                <div className=" mb-10">

                                                                    <p>*Tipo do botão:</p>
                                                                    <select
                                                                        className="proximostextarea textareamensgem form-control mb-10"
                                                                        required
                                                                        onChange={(e) =>{
                                                                            const selectedOption = e.target.options[e.target.selectedIndex];
                                                                            const value = selectedOption.value;
                                                                            const label = selectedOption.text; // Captura o texto da opção
                                                                            handleChangeTypeButton(textArea.id, value, label);
                                                                        }}>
                                                                        <option defaultValue>{selectTypeButtons[textArea.id]?.label || "Selecione o tipo de botão"}
                                                                        </option>

                                                                        {Object.values(typeButtons).map(button => (

                                                                            <option
                                                                                key={button.code}
                                                                                value={button.code}>
                                                                                {button.title}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                {selectTypeButtons[textArea.id]?.value === "copy" && (
                                                                    <div className=" mb-10">
                                                                        <p>Descrição do item copy:</p>
                                                                    </div>
                                                                )}
                                                                {selectTypeButtons[textArea.id]?.value === "url" && (
                                                                    <div className=" mb-10">
                                                                        <p>Descrição do item url:</p>
                                                                    </div>
                                                                )}
                                                                {selectTypeButtons[textArea.id]?.value === "call" && (
                                                                    <React.Fragment>
                                                                        <div className=" mb-10">
                                                                            <p>Número Telefônico</p>
                                                                            <input
                                                                                type="phone"
                                                                                placeholder="Digite seu texto aqui..."
                                                                                value={textPhoneValue[textArea.id]?.value}
                                                                                onChange={(e) => handleChangePhone(textArea.id, e)}
                                                                                className="proximostextarea textareamensgem mb-10"
                                                                                required
                                                                            />
                                                                        </div>

                                                                    </React.Fragment>

                                                                )}
                                                                {selectTypeButtons[textArea.id]?.value === "pix" && (
                                                                    <React.Fragment>
                                                                        <div className=" mb-10">
                                                                            <p>Nome:</p>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Digite seu texto aqui..."
                                                                                value={textPixNameValue[textArea.id]?.value}
                                                                                onChange={(e) =>handleTextPixNameChange(textArea.id, e)}
                                                                                className="proximostextarea textareamensgem mb-10"
                                                                                required
                                                                            />
                                                                        </div>
                                                                        <div className=" mb-10">
                                                                            <p>Tipo da Chave Pix:</p>
                                                                            <select
                                                                                className="proximostextarea textareamensgem form-control mb-10"
                                                                                required
                                                                                onChange={(e) => {
                                                                                    const selectedOption = e.target.options[e.target.selectedIndex];
                                                                                    const value = selectedOption.value;
                                                                                    const label = selectedOption.text; // Captura o texto da opção
                                                                                    handleChangeTypePix(textArea.id, value, label);
                                                                                }}>
                                                                                <option
                                                                                    defaultValue>{selectedPix[textArea.id]?.label || "Selecione o tipo do Pix"}
                                                                                </option>

                                                                                {Object.values(typePix).map(pix => (

                                                                                    <option
                                                                                        key={pix.code}
                                                                                        value={pix.code}>
                                                                                        {pix.title}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className=" mb-10">
                                                                            <p>Chave Pix:</p>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Digite seu texto aqui..."
                                                                                value={textPixValue[textArea.id]?.value}
                                                                                onChange={(e) =>handleTextPixChange(textArea.id,e)}
                                                                                className="proximostextarea textareamensgem mb-10"
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </React.Fragment>
                                                                )}


                                                            </React.Fragment>
                                                        )}


                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="botaoadd mb-20" id="addButton"
                                                onClick={handleAddTextButtonArea}
                                                disabled={textListAreas.length >= maxTextAreas}>Adicionar Item
                                        </button>

                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Salvar
                                        </button>
                                    </div>


                                    <div id="opcao12" className="opcao"
                                         style={{display: selectedOption === 12 ? 'block' : 'none'}}>
                                        <h2>Webhook</h2>
                                        <p>Por favor, preencha os campos e adicione os itens que você deseja
                                            envia via webhook.</p>
                                        <div id="mensagem">
                                            <p>Webhook Url:</p>
                                            <input
                                                type="text"
                                                placeholder="Digite seu endereço webhook aqui..."
                                                value={textListValue}
                                                onChange={handleTextListChange}
                                                className="proximostextarea textareamensgem mb-5"
                                            />
                                            <p className="mb-5">e/ou</p>
                                            <p>Webhook Whatsapp:</p>
                                            <InputMask
                                                mask="+55 (99) 9 9999-9999"
                                                maskChar="_"
                                                className="proximostextarea textareamensgem mb-10"
                                                id="num_phone"
                                                name="num_phone"
                                                type="phone"
                                                value={phoneValue}
                                                placeholder="+55 (99) 9 9999-999"
                                                onChange={handleTextPhoneChange}
                                                title="Por favor, digite o número de telefone."
                                                autoComplete="off"
                                                data-bs-original-title=""
                                            />



                                            <p>Atributos:</p>
                                            {textAreas.map((textArea) => (
                                                <div key={textArea.id} id={`textarea${textArea.id}`}
                                                     className="proximotextareaflex">

                                                    <input
                                                        type="text"
                                                        placeholder="Digite tag do atributo aqui..."
                                                        className="proximostextarea textareamensgem"
                                                        value={textArea.value}
                                                        onChange={(e) => handleTextAreaChange(textArea.id, e)}
                                                    />
                                                    <div className="buttonGroup">
                                                        <button className="deleteButton"
                                                                onClick={() => handleDeleteTextArea(textArea.id)}>Apagar
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <button className="botaoadd" id="addButton" onClick={handleAddTextArea}
                                                disabled={textAreas.length >= maxTextAreas}>Adicionar Item
                                        </button>

                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Salvar
                                        </button>
                                    </div>
                                    <div id="opcao13" className="opcao"
                                         style={{display: selectedOption === 13 ? 'block' : 'none'}}>
                                        <h2>13</h2>
                                        <p>Por favor, selecione um valor em segundos de atraso deseja adicionar para que
                                            a próxima
                                            mensagem seja enviada.</p>
                                        <div className="slider-container">
                                            <input type="range" min="2" max="30" value={delayValue}
                                                   onChange={handleDelayChange}
                                                   className="slider" id="rangeSlider"/>
                                            <p className="slider-value"><span id="sliderValue">{delayValue}</span>s</p>
                                        </div>
                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Adicionar atraso
                                        </button>
                                    </div>
                                    <div id="opcao14" className="opcao"
                                         style={{display: selectedOption === 14 ? 'block' : 'none'}}>
                                        <h2>14</h2>
                                        <p>Por favor, selecione um valor em segundos de atraso deseja adicionar para que
                                            a próxima
                                            mensagem seja enviada.</p>
                                        <div className="slider-container">
                                            <input type="range" min="2" max="30" value={delayValue}
                                                   onChange={handleDelayChange}
                                                   className="slider" id="rangeSlider"/>
                                            <p className="slider-value"><span id="sliderValue">{delayValue}</span>s</p>
                                        </div>
                                        <button className="btn btn-success btn-sm" onClick={onAddNode}
                                                type="button">Adicionar atraso
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {/* <div className="col-xl-3 col-md-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <FormContainer users={users} avatar={avatar} process_status={process_status}
                                           query_types_new_process={query_types_new_process}
                                           query_types_in_progress={query_types_in_progress}
                                           query_types_in_close={query_types_in_close} bot_id={bot_id}
                                           bot_name={bot_name} instance_id={instance_id} chatbots={chatbots}
                                           image_close={image_close} image_redirect_url={image_redirect_url}
                                           image_notification_url={image_notification_url}
                                           image_close_url={image_close_url} departments={departments}
                                           clickInNode={clickInNode} setEdges={setEdges} edges={edges}
                                           nodeEdit={nodeEdit} setNodeEdit={setNodeEdit} onNodesChange={onNodesChange}
                                           nodes={nodes} setNodes={setNodes} image_image_url={image_image_url}
                                           image_audio_url={image_audio_url} image_mp3_url={image_mp3_url}
                                           image_video_url={image_video_url} image_pdf_url={image_pdf_url}
                                           image_doc_url={image_doc_url}/>
                        </div>
                    </div>
                </div>*/}
            </div>
        </div>


    );
}

export default FlowChatBotRom;