import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ptBr from "@fullcalendar/core/locales/pt-br";
import DetailsModal from "./hooks/useDetailsModal";
import ImageWithFallback from "../ImageWithFallback";


const CalendarRoom = ({image_avatar}) => {
    const [events, setEvents] = useState([
        { id: "1", title: "Reunião",   start: "2025-01-03T10:00:00", end: "2025-01-03T12:00:00", allDay: false,description: "Reunião importante com a equipe para entregar as premiações de desempenho.",
            eventColor: "#7986CB", colorName: "Lavanda",
            attendees:[
                {avatar: "", email: "lucas@gmail.com", name: "Lucas Sousa", attendeeType: "contact", cellphone: "(62) 9 8265 - 1478", contact_id:""},
                {avatar: "",attendeeType: "user", email: "wangfds-dfsdfsf@gamil.com", name: "Wanderson"}
            ]
        },
        { id: "2", title: "Entrega", start: "2025-01-05",end: "2025-01-05",allDay: true, description: "Entrega do projeto final." ,
            eventColor: "#aa8424", colorName: "Ametista",
            attendees:[]
        },

    ]);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [seletedEvent, setSeletedEvent] = useState(null);

    const handleDateClick = (info) => {
        alert(`Você clicou na data: ${info.dateStr}`);
    };
    const handleCloseDetailsModal = () => {
        setShowDetailsModal(false)
    };
    const handleEventClick = (info) => {
        setSeletedEvent(info.event);
        setShowDetailsModal(true)

    };
    const handleEventDrop = (info) => {
        const { id, start, end } = info.event;

        // Atualiza o estado com a nova data de início e término do evento movido
        setEvents((prevEvents) =>
            prevEvents.map((event) =>
                event.id === id
                    ? {
                        ...event,
                        start: start.toISOString().split("T")[0],
                        ...(end && { end: end.toISOString().split("T")[0] }), // Atualiza `end` apenas se existir
                    }
                    : event
            )
        );

        const newStart = start.toISOString().split("T")[0];
        const newEnd = end ? end.toISOString().split("T")[0] : null;

        if (newEnd) {
            alert(`Evento movido para:\nInício: ${newStart}\nTérmino: ${newEnd}`);
        } else {
            alert(`Evento movido para: ${newStart}`);
        }
    };

    const renderEventContent = (eventInfo) => {

        const eventColor = eventInfo.event.extendedProps.eventColor || "#3788d8"; // Cor padrão caso não esteja definida
        const colorName = eventInfo.event.extendedProps.colorName || "Default";
        const eventStyle = {
            padding: "5px",
            backgroundColor: eventColor
        };
        const isAllDay = eventInfo.event.allDay;
        const startText = isAllDay
            ? new Date(eventInfo.event.start).toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            })
            : new Date(eventInfo.event.start).toLocaleTimeString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
            });

        const endText  = isAllDay
            ? new Date(eventInfo.event.end).toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            })
            : new Date(eventInfo.event.end).toLocaleTimeString("pt-BR", {
                hour: "2-digit",
                minute: "2-digit",
            });
        return (
            <div style={eventStyle} title={eventInfo.event.title}>
                <span>{eventInfo.event.title}</span>
                {isAllDay === true && <><br/><strong> Dia Inteiro </strong><br/></>}

                {isAllDay === false && <div className="d-flex justify-content-start">
                    <strong>{startText}</strong>
                    <small style={{paddingLeft: 5, paddingRight: 5}}>{"até"}</small>
                    <strong>{endText}</strong>
                </div>
                }

                {eventInfo.event.extendedProps.description && (
                    <small title={eventInfo.event.extendedProps.description} style={{
                        display: "block", // Garante que seja um bloco para respeitar o width
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: "180px", // Certifique-se de usar "px" para a largura
                    }}>{eventInfo.event.extendedProps.description}</small>
                )}
                <div className="d-flex justify-content-end" style={{paddingRight: 5}}>
                    <ul className="user-list s2">

                        {eventInfo.event.extendedProps.attendees.slice(0, 3).map((attendee) => (
                            <li style={{ width: 30, height: 30}} key={attendee.name}>
                                <ImageWithFallback
                                    style={{ filter: "none"}}
                                    src={attendee.avatar}
                                    title={attendee.name}
                                    fallback={image_avatar}
                                    alt={`user${attendee.name}`}
                                />
                            </li>))}
                        {eventInfo.event.extendedProps.attendees.length > 3 && (
                            <li   title={`Outros:\n ${eventInfo.event.extendedProps.attendees.slice(3).map((user) => user[0]).join('\n ')}`}  className="total">
                                <span>+{eventInfo.event.extendedProps.attendees.length - 3}</span>
                            </li>
                        )}

                    </ul>
                </div>

            </div>
        );
    };

   const submitEditEvent = (
       title,
       start,
       end,
       description,
       attendees) => {

       console.log("Evento Atualizado:", title);
    }
    return (
        <React.Fragment>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                locale={ptBr}
                initialView="dayGridMonth"
                events={events}
                editable={true}
                selectable={true}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                eventDrop={handleEventDrop} // Callback ao mover eventos
                eventContent={renderEventContent}
            />

            <DetailsModal showDetailsModal={showDetailsModal} image_avatar={image_avatar} handleCloseDetailsModal={handleCloseDetailsModal} event={seletedEvent} onSave={submitEditEvent}/>

        </React.Fragment>
    );
};

export default CalendarRoom;