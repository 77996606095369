import React, {useEffect, useState} from 'react';
import useTasks from "./useTasks";
import useComments from "./useComments";
import {Button, Col, Form, Image, ListGroup, Modal, ProgressBar, Row} from "react-bootstrap";
import ImageWithFallback from "../../ImageWithFallback";
import axios from "axios";
import Select from "react-select";
import "../../chats/style/slick.css";
import "../../chats/style/slick-theme.css";
import "../../chats/style/conversa.css";
import "../../chats/style/scrollbar.css";



import ChatComponent from "../components/ChatComponent";

const useDetailsModal = ({lanes,image_avatar,users, showModal ,selectedCard = {}, handleCloseModal ,users_groups,
                             handleSaveChanges, selectedCardUsers = [], selectedCardUsersCount= 0,
                             setFilteredCards, setData, data, closeModal, openModal, currentUser,currentUserRole, currentCompany,profile_picture, applyFilters,
                             lawyers, process_status, query_types_new_process, lawquery_types_in_progressyers, query_types_in_close,
                         }) => {

    const { tasks, taskText, setTaskText,addTask,editTask,deleteTask,toggleTaskCompletion, calculateProgress } = useTasks(  selectedCard);
    const { comments, commentText, setCommentText, addComment } = useComments(selectedCard);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedLane, setSelectedLane] = useState(null);
    const [selectedTagPrimary, setSelectedTagPrimary] = useState(null);
    const [selectedTagSegundary, setSelectedTagSegundary] = useState(null);
    const [showEditTaskModal, setShowEditTaskModal] = useState(false);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [showAddTagModal, setShowAddTagModal] = useState(false);
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false);
    const [showMoveModal, setShowMoveModal] = useState(false);
    const [editDescription, setEditDescription] = useState(selectedCard?.description);
    const [selectedTask, setSelectedTask] = useState(null);
    const [members, setMembers] = useState([]);
    const [tags, setTags] = useState({});
    const [referenceTags, setReferenceTags] = useState([]);
    const [newMember, setNewMember] = useState('');
    const [interest, setInterest] = useState(null);
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (selectedCard?.description) {
            setEditDescription(selectedCard.description);
        }
    }, [selectedCard?.description]);
    useEffect(() => {
        if (selectedCard?.users) {
            setMembers(selectedCard.users)
        }
    }, [selectedCard?.users]);


    const getTags = async () => {
        try {

            let response = await axios.get('/tags/get_tags.json');

            setTags(response.data.tags);
            setReferenceTags(response.data.references);


        } catch (error) {
            // Captura e exibe o erro
            console.error('Erro ao buscar tags as alterações:', error.response?.data || error.message);
        }
    };
    useEffect(() => {
        if (!loaded && (Object.keys(tags).length === 0 || referenceTags.length === 0)) {
            getTags();
            setLoaded(true);
        }
    }, [tags, referenceTags]);

    const saveCardChanges = async (cardId, laneId, card) => {
        try {
            // Envia a requisição para atualizar o card
            const response = await axios.put('/kanbans/update_card', {
                cardId,
                laneId,
                card, // Adiciona os dados extras enviados
            });

            console.log('Alteração salva com sucesso!', response.data);
        } catch (error) {
            // Captura e exibe o erro
            console.error('Erro ao salvar as alterações:', error.response?.data || error.message);
        }
    };

    const handleSubmitCard = () => {
        const { id: cardId, laneId } = selectedCard;

        // Atualiza os cards filtrados
        const updatedLanes = Array.isArray(data?.lanes)
            ? data.lanes.map((lane) => {
                // Verifica se estamos no lane correto
                if (lane.id === laneId) {
                    const updatedCards = Array.isArray(lane?.cards)
                        ? lane.cards.map((card) => {
                            // Verifica se estamos no card correto
                            if (card.id === cardId) {
                                if (editDescription) {
                                    const updatedCard = { ...card, description: editDescription };
                                    selectedCard.description = editDescription;

                                    // Salva a alteração no backend
                                    saveCardChanges(cardId, laneId, updatedCard)
                                        .catch((error) => {
                                            console.error("Erro ao salvar no backend:", error);
                                            // Opcional: notificar o usuário ou reverter alterações no estado
                                        });
                                    return updatedCard;
                                }
                            }
                            return card;
                        })
                        : [];

                    return { ...lane, cards: updatedCards };
                }
                return lane;
            })
            : [];

        // Atualiza os estados com os dados modificados
        const updatedData = { ...data, lanes: updatedLanes };
        setFilteredCards(updatedData); // Atualiza os cards filtrados
        setData(updatedData); // Atualiza o estado principal
    }
    const handleArchive = (selectedCard) => {
        const { id: cardId, laneId, archivedCard } = selectedCard;

        // Atualiza os cards filtrados
        const updatedLanes = Array.isArray(data?.lanes)
            ? data.lanes.map((lane) => {
                if (lane.id === laneId) {
                    const updatedCards = Array.isArray(lane?.cards)
                        ? lane.cards.map((card) => {
                            if (card.id === cardId) {
                                const updatedCard = { ...card, archivedCard: !archivedCard };
                                selectedCard.archivedCard = !selectedCard.archivedCard
                                // Salva a alteração no backend
                                saveCardChanges(cardId, laneId, updatedCard)
                                    .catch((error) => {
                                        console.error("Erro ao salvar no backend:", error);
                                        // Opcional: notificar o usuário ou reverter alterações no estado
                                    });

                                return updatedCard;
                            }
                            return card;
                        })
                        : [];

                    return { ...lane, cards: updatedCards };
                }
                return lane;
            })
            : [];

        // Atualiza os estados com os dados modificados
        const updatedData = { ...data, lanes: updatedLanes };
        setFilteredCards(updatedData); // Atualiza os cards filtrados
        setData(updatedData); // Atualiza o estado principal

        setShowArchiveModal(false)
        applyFilters('', archivedCard);
    };

    const handleOpenChat =()  =>{
        setShowChatModal(true)
    }
    const handleOpenMove =()  =>{
        setShowMoveModal(true)
    }
    const handleOpenModalArchive = ()  =>{
        setShowArchiveModal(true)
    }
    const handleOpenModalInfo = ()  =>{

    }
    const handleOpenAddMember = ()  =>{
        setShowAddMemberModal(true)
    }
    const handleOpenAddTag = ()  =>{
        setShowAddTagModal(true)
    }
    const handleCloseEditTaskModal = () => setShowEditTaskModal(false);
    const handleCloseAddMemberModal = () => setShowAddMemberModal(false);
    const handleCloseAddTagModal = () => setShowAddTagModal(false);
    const handleCloseMoveModal = () => setShowMoveModal(false);
    const handleCloseArchiveModal = () => setShowArchiveModal(false);
    const handleCloseChatModal = () => setShowChatModal(false);
    const selectedEditTask = (id) => {
        const taskToEdit = tasks.find((task) => task.id === id);
        if (taskToEdit) {
            setSelectedTask(taskToEdit); // Define a tarefa selecionada
            setShowEditTaskModal(true);
        }
    };

    const handleChangeTitleTask = (e) => {
        const { value } = e.target;
        if (selectedTask) {
            setSelectedTask({ ...selectedTask, text: value }); // Atualiza apenas o texto
        }
    };

    const handleTaskSubmit = async (e) => {
        e.preventDefault();
        if (selectedTask) {
            await editTask(selectedTask.id, selectedTask.text); // Atualiza a tarefa
            handleCloseEditTaskModal()
        }
    };
    const handleAddMember = async () => {

        try {


            const formData = new FormData();
            {/*selectedUsers.forEach((user, index) => {
                formData.append(`users[${index}][id]`, user.id);
                formData.append(`users[${index}][role]`, user.role);
            });*/}

            formData.append('cardId',selectedCard?.id);
            formData.append('users', JSON.stringify(selectedUsers) );

            axios.post('/kanbans/add_member', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    console.log('Resposta do servidor:', response.data);
                    axios.get('/kanbans/get_lines',  {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(response => {
                            setData(response.data.data);
                            setFilteredCards(response.data.data);
                            setShowAddMemberModal(false)
                        })
                        .catch(error => {
                            console.error('Erro ao buscar lines :', error);
                        });

                })
                .catch(error => {
                    console.error('Erro aoadd member:', error);
                });

        } catch (error) {
            console.error('Erro ao add member:', error);
        }



    };
    const handleAddTag = async () => {

        try {

            const formData = new FormData();
            formData.append('cardId',selectedCard?.id);
            formData.append('tagPrimary',JSON.stringify(selectedTagPrimary));
            formData.append('tagSegundary',JSON.stringify(selectedTagSegundary));
            formData.append('interest',interest);

            axios.post('/kanbans/add_tag', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    console.log('Resposta do servidor:', response.data);
                    axios.get('/kanbans/get_lines',  {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(response => {
                            setData(response.data.data);
                            setFilteredCards(response.data.data);
                            setShowAddTagModal(false)
                        })
                        .catch(error => {
                            console.error('Erro ao buscar lines :', error);
                        });

                })
                .catch(error => {
                    console.error('Erro aoadd member:', error);
                });

        } catch (error) {
            console.error('Erro ao add member:', error);
        }



    };
    const showCheckboxesForm = () => {
        setShowCheckboxes((prev) => !prev);
    };
    // Função para capturar usuários selecionados
    const handleUserSelection = (e) => {
        const user = e.value

        setSelectedUsers((prevSelected) => {
            // Verifica se o usuário já está na lista usando o id (posição 1)
            const isUserSelected = prevSelected.some((u) => u.id === user.id);

            if (isUserSelected) {
                // Remove o usuário se ele já estiver selecionado
                return prevSelected.filter((u) => u.id !== user.id);
            } else {
                // Adiciona o usuário filtrado se ele não estiver selecionado
                return [...prevSelected, {id: user.id, role: user.role}];
            }
        });

        setMembers((prevSelected) => {
            // Verifica se o usuário já está na lista usando o id
            const isUserSelected = prevSelected.some((u) => u.id === user.id);

            if (isUserSelected) {
                // Remove o usuário se ele já estiver selecionado
                return prevSelected.filter((u) => u.id !== user.id);
            } else {
                // Adiciona o usuário se ele não estiver selecionado
                return [...prevSelected, user];
            }
        });
    };
    const handleChangeInterest = (e) => {
        const {  value } = e.target;
        setInterest(value);
    };
    const handleOptionLaneChange = (value) => {
        setSelectedLane(value);
    }
    const handleOptionTagPrimaryChange = (value) => {
        setSelectedTagPrimary(value);
    }
    const handleOptionTagSegundaryChange = (value) => {
        setSelectedTagSegundary(value);
    }
    const handleMoveCard = async () =>{


        try {

            const formData = new FormData();
            formData.append('cardId',selectedCard?.id);
            formData.append('toLaneId',selectedLane.lane_id);
            formData.append('index',0);
            axios.post('/kanbans/move_card', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    console.log('Resposta do servidor:', response.data);
                    axios.get('/kanbans/get_lines',  {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(response => {
                            setFilteredCards(response.data.data)
                            setData(response.data.data)
                            handleCloseMoveModal();
                        })
                        .catch(error => {
                            console.error('Erro ao buscar lines :', error);
                        });

                })
                .catch(error => {
                    console.error('Erro ao mover card:', error);
                });

        } catch (error) {
            console.error('Erro ao mover o cartão:', error);
        }


    }

    const  hexToRgb =(hex) =>{
        if (!hex) return "rgba(0, 0, 0, 0.5)"; // Cor padrão se hex não estiver presente
        const bigint = parseInt(hex.replace("#", ""), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, 0.5)`;
    }
    const optionsAttorney = Array.isArray(users_groups?.attorney) ? users_groups.attorney.map((user) => ({
        value: { id: user.id, role: "attorney", avatar: user.avatar, name: user.name },
        label: (
            <div className="d-flex align-items-center">
                <img
                    className="img-fluid rounded-circle"
                    style={{ width: 30, height: 30, filter: "none" }}
                    src={user.avatar}
                    alt={`img-${user.id}`}
                />
                <span style={{ paddingLeft: 10 }}>{user.name}</span>
            </div>
        ),
    })) : [];
    const optionsOperational = Array.isArray(users_groups?.operational) ? users_groups.operational.map((user) => ({
        value: { id: user.id, role: "operational", avatar: user.avatar, name: user.name },
        label: (
            <div className="d-flex align-items-center">
                <img
                    className="img-fluid rounded-circle"
                    style={{ width: 30, height: 30, filter: "none" }}
                    src={user.avatar}
                    alt={`img-${user.id}`}
                />
                <span style={{ paddingLeft: 10 }}>{user.name}</span>
            </div>
        ),
    })) : [];
    return(
        <React.Fragment>

            <div id="cardDetails" style={{display:"none"}}  className="modal custom-modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="modal-title">Detalhes</p>
                            <button
                                className="btn-close"
                                type="button"
                                onClick={handleCloseModal}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                data-bs-original-title=""
                                title=""></button>
                        </div>
                        <div className="modal-body">

                            <div className="media">
                                <div className="active-profile pr-20" style={{paddingRight: 10}}>
                                    <ImageWithFallback
                                        classImage={"img-fluid rounded-circle update_img_0"}
                                        style={{width: 50, height: 50, filter: "none"}}
                                        src={selectedCard ? selectedCard.avatar: image_avatar}
                                        fallback={selectedCard ? selectedCard.avatar_fallback : image_avatar}
                                        alt="contact"
                                    />
                                    <div className="status bg-success" style={{
                                        height: 26, top: 23,
                                        width: 26, display: "flex", justifyContent: "center", alignItems: "center"
                                    }}>
                                        <i className='bx bxl-whatsapp'></i>
                                    </div>
                                </div>

                                <div className="pl-10">
                                    <a className="font-w400 first_name_0">
                                        {selectedCard ? selectedCard.title : ''}
                                    </a>
                                    <p style={{marginTop: 0}}>{selectedCard ? selectedCard.phone : ''}</p>
                                </div>
                            </div>
                            <Row>
                                <Col md={9}>

                                    <div className="d-flex">
                                        <div style={{paddingRight: 15}}>
                                            <p style={{marginBottom: "-10px"}}>Membros</p>
                                            <ul className="user-list s2">
                                                {members && members.slice(0, 3).map((user) => (
                                                    <li key={user.id}>
                                                        <ImageWithFallback
                                                            src={user.avatar}
                                                            style={{filter: "none"}}
                                                            fallback={image_avatar}
                                                            title={user.name}
                                                            alt={`user_${user.id}`}
                                                        />
                                                    </li>))}

                                                {members && members.length > 3 && (
                                                    <li className="total">
                                                        <span>+{members.length - 3}</span>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                        <div style={{paddingRight: 15}}>
                                            <p style={{marginBottom: "0px", paddingRight: 70}}>Tags</p>
                                            {selectedCard?.tags && <ul>
                                                {/*
                                            tags && tags.map((tag) => (
                                                <li key={tag.id}>
                                                    <span
                                                        className={`texto-limitado badge  badge-danger mb-3 `}>{tag.title}</span>
                                                </li>
                                            ))
                                        */}
                                                {selectedCard?.tags?.primary && <li>
                                           <span title={selectedCard?.tags?.primary.title}
                                                 style={{
                                                     color: selectedCard?.tags?.primary.color,
                                                     backgroundColor: hexToRgb(selectedCard?.tags?.primary.color),
                                                 }}
                                                 className={`texto-limitado badge mb-3 `}>{selectedCard?.tags?.primary.title}</span>

                                                </li>}
                                                {selectedCard?.tags?.segundary && <li>
                                            <span title={selectedCard?.tags?.segundary.title}
                                                  style={{
                                                      color: selectedCard?.tags?.segundary.color,
                                                      backgroundColor: hexToRgb(selectedCard?.tags?.segundary?.color)
                                                  }}
                                                  className={`texto-limitado badge mb-3 `}>{selectedCard?.tags?.segundary.title}</span>
                                                </li>}


                                            </ul>}

                                        </div>

                                        {currentCompany.type_company === "advocacia" && <div style={{paddingRight: 15}}>
                                            <p style={{marginBottom: "0px", paddingRight: 70}}>Interresse</p>
                                            <ul>
                                                {selectedCard?.interest &&
                                                    <span title={selectedCard?.interest?.title}
                                                          style={{
                                                              color: "#3552cf",
                                                              backgroundColor: "rgb(72 0 255 / 10%)"
                                                          }}
                                                          className={`texto-limitado badge  `}>{selectedCard?.interest?.title}</span>}
                                            </ul>

                                        </div>}
                                        {currentCompany.type_company === "advocacia" && <div style={{paddingRight: 15}}>
                                            <p style={{marginBottom: "0px", paddingRight: 70}}>Valor da Ação</p>
                                            <ul>
                                                {selectedCard?.share_value &&
                                                    <span title={selectedCard?.share_value}
                                                          style={{
                                                              color: "#3552cf",
                                                              backgroundColor: "rgb(72 0 255 / 10%)"
                                                          }}
                                                          className={`texto-limitado badge  `}>{selectedCard?.share_value}</span>
                                                }
                                            </ul>

                                        </div>}

                                        {/*
                                 <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "0px"}}>Campanha</p>
                                    <span className={`texto-limitado badge  badge-info  `}>{"Campanha A"}</span>
                                </div>
                                */}

                                    </div>


                                    <div style={{marginBottom: '20px', marginTop: '20px'}}>
                                        <h5> Descrição</h5>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Escreva uma descrição..."
                                            rows={4}
                                            value={editDescription}
                                            onChange={(e) => setEditDescription(e.target.value)}
                                            style={{resize: 'none'}}
                                        />
                                    </div>

                                    {/* Seção de Tasks (Subtarefas) */}

                                    <div style={{marginBottom: '20px'}}>
                                        <h5> Checklist</h5>
                                        <ProgressBar now={calculateProgress()}
                                                     label={`${Math.round(calculateProgress())}%`}
                                                     style={{marginBottom: '10px'}}/>

                                        {tasks.length > 0 && <div style={{
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            border: '1px solid #dee2e6',
                                            borderRadius: '4px',
                                            padding: '10px'
                                        }}>
                                            <ListGroup>
                                                {tasks.map(task => (
                                                    <ListGroup.Item key={task.id} style={{
                                                        border: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        opacity: task.completed ? '0.6' : '1',
                                                        textDecoration: task.completed ? 'line-through' : 'none'
                                                    }} className="d-flex align-items-start">
                                                        <Form.Check
                                                            type="checkbox"
                                                            checked={task.completed}
                                                            label={task.text}
                                                            onChange={() => toggleTaskCompletion(task.id)}
                                                            style={{minWidth: "80%", cursor: 'pointer'}}
                                                        />
                                                        <div className="d-flex">
                                                            <div className="profilesmoll" style={{marginLeft: '10px'}}>
                                                        <span className="icon">
                                                           <a style={{color: "#000000"}} href="#" title="Editar"
                                                              onClick={() => selectedEditTask(task.id)}>
                                                               <i className='bx bx-edit'></i>
                                                           </a>
                                                       </span>
                                                            </div>
                                                            <div className="profilesmoll" style={{marginLeft: '5px'}}>
                                                        <span className="icon">
                                                           <a style={{color: "#000000"}} href="#" title="Excluir"
                                                              onClick={() => deleteTask(task.id)}>
                                                              <i className='bx bx-trash'></i>
                                                           </a>
                                                        </span>
                                                            </div>
                                                        </div>


                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </div>}

                                        <Form.Control
                                            type="text"
                                            placeholder="Adicionar nova tarefa..."
                                            value={taskText}
                                            onChange={(e) => setTaskText(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    addTask()
                                                        .catch((error) => {
                                                            console.error("Erro ao salvar no backend:", error);
                                                            // Opcional: notificar o usuário ou reverter alterações no estado
                                                        });
                                                }
                                            }}
                                            style={{marginTop: '10px'}}
                                        />
                                        <Button variant="primary" onClick={addTask} style={{marginTop: '10px'}}>
                                            Adicionar Tarefa
                                        </Button>
                                    </div>


                                    {/* Seção de Comentários */}
                                    {/*<div style={{ marginBottom: '20px' }}></div>*/}
                                    {/* Seção de Comentários */}
                                    <div style={{marginTop: '20px'}}>
                                        <h5>Anotações</h5>
                                        <div style={{maxHeight: '200px', overflowY: 'auto'}}>
                                            <ListGroup variant="flush">
                                                {comments.map(comment => (
                                                    <ListGroup.Item key={comment.id}
                                                                    className="d-flex align-items-start">
                                                        <Image
                                                            src={comment.userImage}
                                                            roundedCircle
                                                            style={{width: '40px', marginRight: '10px'}}
                                                        />

                                                        <div className="d-flex" style={{
                                                            background: '#f0f0f0',
                                                            padding: '10px',
                                                            borderRadius: '10px',
                                                            minWidth: "70%"
                                                        }}>
                                                            <div>
                                                                <div className="justify-content-between pt-0 pb-0">
                                                                    <strong>{comment.userName}</strong>
                                                                    <span style={{
                                                                        color: '#666',
                                                                        fontSize: '0.8rem',
                                                                        paddingLeft: 10
                                                                    }}>{comment.timestamp}</span>
                                                                </div>
                                                                <br/>
                                                                <div style={{whiteSpace: 'pre-wrap', flex: '1'}}>
                                                                    {comment.text}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </div>

                                        <Form.Control
                                            as="textarea"
                                            rows={2}
                                            placeholder="Escreva um comentário..."
                                            value={commentText}
                                            onChange={(e) => setCommentText(e.target.value)}
                                            style={{marginTop: '10px'}}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey) {
                                                    e.preventDefault(); // Para evitar quebras de linha ao enviar o comentário
                                                    addComment(currentUser)
                                                        .catch((error) => {
                                                            console.error("Erro ao salvar no backend:", error);
                                                            // Opcional: notificar o usuário ou reverter alterações no estado
                                                        });
                                                }
                                            }}
                                        />
                                        <Button variant="primary" onClick={() => addComment(currentUser)}
                                                style={{marginTop: '10px'}}>
                                            Adicionar Comentário
                                        </Button>
                                    </div>
                                </Col>

                                <Col md={3}>


                                    <h5>Ações</h5>
                                    {selectedCard && (
                                        <React.Fragment>
                                            {currentUserRole === "central" && <Button
                                                variant="light"
                                                style={{width: 170, marginBlock: 5}}
                                                onClick={handleOpenModalArchive}
                                            >
                                                {selectedCard?.archivedCard === true ? (
                                                    <>
                                                        <i className='bx bx-archive-out'></i>
                                                        <span>Desarquivar</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className='bx bx-archive-in'></i>
                                                        <span>Arquivar</span>
                                                    </>
                                                )}
                                            </Button>}
                                            <Button
                                                variant="light"
                                                style={{width: 170, marginBlock: 5}}
                                                onClick={handleOpenMove}
                                            >
                                                <i className='bx bx-move'></i>
                                                <span>Mover</span>

                                            </Button>
                                            {(selectedCard?.users?.some(user => user?.id?.toString() === currentUser.id?.toString()) || currentUserRole === "central") &&
                                                <Button
                                                    variant="light"
                                                    style={{width: 170, marginBlock: 5}}
                                                    onClick={handleOpenChat}
                                                >
                                                    <i className='bx bx-chat'></i>
                                                    <span>Abrir Chat</span>

                                                </Button>}
                                            {(selectedCard?.users?.length < 2 || currentUserRole === "central") &&
                                                <Button
                                                    variant="light"
                                                    dissable="false"
                                                    style={{width: 170, marginBlock: 5}}
                                                    onClick={handleOpenAddMember}
                                                >
                                                    <i className='bx bx-user-plus'></i>
                                                    <span>Add Membro</span>

                                                </Button>}
                                            {(selectedCard?.users?.some(user => user?.id?.toString() === currentUser.id?.toString()) || currentUserRole === "central") &&
                                                <Button
                                                    variant="light"
                                                    dissable="true"
                                                    style={{width: 170, marginBlock: 5}}
                                                    onClick={handleOpenAddTag}
                                                >
                                                    <i className='bx bx-purchase-tag-alt'></i>
                                                    <span>Add Tags</span>
                                                </Button>}
                                        </React.Fragment>


                                    )}


                                    {/* Seção de Membros */}
                                    {/*<div style={{marginTop: '20px'}}>
                               <h5>Membros</h5>
                               <ListGroup>
                                   {members.map((member, index) => (
                                       <ListGroup.Item key={index}>
                                           {member}
                                       </ListGroup.Item>
                                   ))}
                               </ListGroup>
                               <Form.Control
                                   type="text"
                                   placeholder="Adicionar membro..."
                                   value={newMember}
                                   onChange={(e) => setNewMember(e.target.value)}
                                   onKeyPress={(e) => {
                                       if (e.key === 'Enter') {
                                           handleAddMember();
                                       }
                                   }}
                                   style={{marginTop: '10px'}}
                               />
                               <Button variant="primary" onClick={handleAddMember} style={{marginTop: '10px'}}>
                                   Adicionar Membro
                               </Button>
                           </div>*/}

                                    {/* Seção de Tags */}
                                    {/*<div style={{marginTop: '20px'}}>
                                   <h5>Tags</h5>
                                   <ListGroup>
                                       {tags.map((tag, index) => (
                                           <ListGroup.Item key={index}>
                                               {tag}
                                           </ListGroup.Item>
                                       ))}
                                   </ListGroup>
                                   <Form.Control
                                       type="text"
                                       placeholder="Adicionar tag..."
                                       value={newTag}
                                       onChange={(e) => setNewTag(e.target.value)}
                                       onKeyPress={(e) => {
                                           if (e.key === 'Enter') {
                                               handleAddTag();
                                           }
                                       }}
                                       style={{marginTop: '10px'}}
                                   />
                                   <Button variant="primary" onClick={handleAddTag} style={{marginTop: '10px'}}>
                                       Adicionar Tag
                                   </Button>
                               </div>*/}
                                </Col>
                            </Row>

                            <div
                                className="d-flex align-items-center justify-content-center">

                                <button
                                    style={{marginRight: 20}}
                                    className="btn btn-primary "
                                    type="button"
                                    onClick={handleSubmitCard}
                                    id="button-submit-data-list"
                                    data-bs-original-title=""
                                    title="">Salvar
                                </button>


                               <button
                                   className="btn btn-primary"
                                  type="button"
                                  data-bs-dismiss="modal"
                                  onClick={handleCloseModal}
                                  data-bs-original-title=""
                                  title="">Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="media">
                            <div className="active-profile pr-20" style={{paddingRight: 10}}>
                                <ImageWithFallback
                                    classImage={"img-fluid rounded-circle update_img_0"}
                                    style={{width: 50, height: 50, filter: "none"}}
                                    src={selectedCard ? selectedCard.avatar : image_avatar}
                                    fallback={selectedCard ? selectedCard.avatar_fallback : image_avatar}
                                    alt="contact"
                                />
                                <div className="status bg-success" style={{
                                    height: 26, top: 23,
                                    width: 26, display: "flex", justifyContent: "center", alignItems: "center"
                                }}>
                                    <i className='bx bxl-whatsapp'></i>
                                </div>
                            </div>

                            <div className="pl-10">
                                <a className="font-w400 first_name_0">
                                    {selectedCard ? selectedCard.title : ''}
                                </a>
                                <p style={{marginTop: 0}}>{selectedCard ? selectedCard.phone : ''}</p>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={9}>

                            <div className="d-flex">
                                <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "-10px"}}>Membros</p>
                                    <ul className="user-list s2">
                                        {members && members.slice(0, 3).map((user) => (
                                            <li key={user.id}>
                                                <ImageWithFallback
                                                    src={user.avatar}
                                                    style={{filter: "none"}}
                                                    fallback={image_avatar}
                                                    title={user.name}
                                                    alt={`user_${user.id}`}
                                                />
                                            </li>))}

                                        {members && members.length > 3 && (
                                            <li className="total">
                                                <span>+{members.length - 3}</span>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                                <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "0px", paddingRight: 70}}>Tags</p>
                                    {selectedCard?.tags && <ul>
                                        {/*
                                            tags && tags.map((tag) => (
                                                <li key={tag.id}>
                                                    <span
                                                        className={`texto-limitado badge  badge-danger mb-3 `}>{tag.title}</span>
                                                </li>
                                            ))
                                        */}
                                        {selectedCard?.tags?.primary && <li>
                                           <span title={selectedCard?.tags?.primary.title}
                                                 style={{
                                                     color: selectedCard?.tags?.primary.color,
                                                     backgroundColor: hexToRgb(selectedCard?.tags?.primary.color),
                                                 }}
                                                 className={`texto-limitado badge mb-3 `}>{selectedCard?.tags?.primary.title}</span>

                                        </li>}
                                        {selectedCard?.tags?.segundary && <li>
                                            <span title={selectedCard?.tags?.segundary.title}
                                                  style={{
                                                      color: selectedCard?.tags?.segundary.color,
                                                      backgroundColor: hexToRgb(selectedCard?.tags?.segundary?.color)
                                                  }}
                                                  className={`texto-limitado badge mb-3 `}>{selectedCard?.tags?.segundary.title}</span>
                                        </li>}


                                    </ul>}

                                </div>

                                {currentCompany.type_company === "advocacia" && <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "0px", paddingRight: 70}}>Interresse</p>
                                    <ul>
                                        {selectedCard?.interest &&
                                            <span title={selectedCard?.interest.title}
                                                  style={{color: "#3552cf", backgroundColor: "rgb(72 0 255 / 10%)"}}
                                                  className={`texto-limitado badge  `}>{selectedCard?.interest.title}</span>}
                                    </ul>

                                </div>}
                                {currentCompany.type_company === "advocacia" && <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "0px", paddingRight: 70}}>Ação</p>
                                    <ul>
                                        { selectedCard?.share_value &&
                                            <span title={ selectedCard?.share_value}
                                                  style={{
                                                      color: "#44cf35",
                                                      backgroundColor: "rgb(0 255 53 / 10%)"
                                                  }}
                                                  className={`texto-limitado badge  `}>{ selectedCard?.share_value}</span>
                                        }


                                    </ul>

                                </div>}
                                {/*
                                 <div style={{paddingRight: 15}}>
                                    <p style={{marginBottom: "0px"}}>Campanha</p>
                                    <span className={`texto-limitado badge  badge-info  `}>{"Campanha A"}</span>
                                </div>
                                */}

                            </div>


                            <div style={{marginBottom: '20px', marginTop: '20px'}}>
                                <h5> Descrição</h5>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Escreva uma descrição..."
                                    rows={4}
                                    value={editDescription}
                                    onChange={(e) => setEditDescription(e.target.value)}
                                    style={{resize: 'none'}}
                                />
                            </div>

                            {/* Seção de Tasks (Subtarefas) */}

                            <div style={{marginBottom: '20px'}}>
                                <h5> Checklist</h5>
                                <ProgressBar now={calculateProgress()} label={`${Math.round(calculateProgress())}%`}
                                             style={{marginBottom: '10px'}}/>

                                {tasks.length > 0 && <div style={{
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    border: '1px solid #dee2e6',
                                    borderRadius: '4px',
                                    padding: '10px'
                                }}>
                                    <ListGroup>
                                        {tasks.map(task => (
                                            <ListGroup.Item key={task.id} style={{
                                                border: 'none',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                opacity: task.completed ? '0.6' : '1',
                                                textDecoration: task.completed ? 'line-through' : 'none'
                                            }} className="d-flex align-items-start">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={task.completed}
                                                    label={task.text}
                                                    onChange={() => toggleTaskCompletion(task.id)}
                                                    style={{minWidth: "80%", cursor: 'pointer'}}
                                                />
                                                <div className="d-flex">
                                                    <div className="profilesmoll" style={{marginLeft: '10px'}}>
                                                        <span className="icon">
                                                           <a style={{color: "#000000"}} href="#" title="Editar"
                                                              onClick={() => selectedEditTask(task.id)}>
                                                               <i className='bx bx-edit'></i>
                                                           </a>
                                                       </span>
                                                    </div>
                                                    <div className="profilesmoll" style={{marginLeft: '5px'}}>
                                                        <span className="icon">
                                                           <a style={{color: "#000000"}} href="#" title="Excluir"
                                                              onClick={() => deleteTask(task.id)}>
                                                              <i className='bx bx-trash'></i>
                                                           </a>
                                                        </span>
                                                    </div>
                                                </div>


                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>}

                                <Form.Control
                                    type="text"
                                    placeholder="Adicionar nova tarefa..."
                                    value={taskText}
                                    onChange={(e) => setTaskText(e.target.value)}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            addTask()
                                                .catch((error) => {
                                                    console.error("Erro ao salvar no backend:", error);
                                                    // Opcional: notificar o usuário ou reverter alterações no estado
                                                });
                                        }
                                    }}
                                    style={{marginTop: '10px'}}
                                />
                                <Button variant="primary" onClick={addTask} style={{marginTop: '10px'}}>
                                    Adicionar Tarefa
                                </Button>
                            </div>


                            {/* Seção de Comentários */}
                            {/*<div style={{ marginBottom: '20px' }}></div>*/}
                            {/* Seção de Comentários */}
                            <div style={{marginTop: '20px'}}>
                                <h5>Anotações</h5>
                                <div style={{maxHeight: '200px', overflowY: 'auto'}}>
                                    <ListGroup variant="flush">
                                        {comments.map(comment => (
                                            <ListGroup.Item key={comment.id} className="d-flex align-items-start">
                                                <Image
                                                    src={comment.userImage}
                                                    roundedCircle
                                                    style={{width: '40px', marginRight: '10px'}}
                                                />

                                                <div className="d-flex" style={{
                                                    background: '#f0f0f0',
                                                    padding: '10px',
                                                    borderRadius: '10px',
                                                    minWidth: "70%"
                                                }}>
                                                    <div>
                                                        <div className="justify-content-between pt-0 pb-0">
                                                            <strong>{comment.userName}</strong>
                                                            <span style={{
                                                                color: '#666',
                                                                fontSize: '0.8rem',
                                                                paddingLeft: 10
                                                            }}>{comment.timestamp}</span>
                                                        </div>
                                                        <br/>
                                                        <div style={{whiteSpace: 'pre-wrap', flex: '1'}}>
                                                            {comment.text}
                                                        </div>

                                                    </div>
                                                </div>

                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </div>

                                <Form.Control
                                    as="textarea"
                                    rows={2}
                                    placeholder="Escreva um comentário..."
                                    value={commentText}
                                    onChange={(e) => setCommentText(e.target.value)}
                                    style={{marginTop: '10px'}}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' && !e.shiftKey) {
                                            e.preventDefault(); // Para evitar quebras de linha ao enviar o comentário
                                            addComment(currentUser)
                                                .catch((error) => {
                                                    console.error("Erro ao salvar no backend:", error);
                                                    // Opcional: notificar o usuário ou reverter alterações no estado
                                                });
                                        }
                                    }}
                                />
                                <Button variant="primary" onClick={() => addComment(currentUser)}
                                        style={{marginTop: '10px'}}>
                                    Adicionar Comentário
                                </Button>
                            </div>
                        </Col>

                        <Col md={3}>


                            <h5>Ações</h5>
                            {selectedCard && (
                                <React.Fragment>
                                    {/*currentUserRole === "central" && <Button
                                        variant="light"
                                        style={{width: 170, marginBlock: 5}}
                                        onClick={handleOpenModalInfo}
                                    >

                                        <span>Informe</span>


                                    </Button>*/}
                                    {currentUserRole === "central" && <Button
                                        variant="light"
                                        style={{width: 170, marginBlock: 5}}
                                        onClick={handleOpenModalArchive}
                                    >
                                        {selectedCard?.archivedCard === true ? (
                                            <>
                                                <span>Desarquivar</span>
                                            </>
                                        ) : (
                                            <>
                                                <span>Arquivar</span>
                                            </>
                                        )}
                                    </Button>}
                                    <Button
                                        variant="light"
                                        style={{width: 170, marginBlock: 5}}
                                        onClick={handleOpenMove}
                                    >
                                        <span>Mover</span>

                                    </Button>
                                    {(selectedCard?.users?.some(user => user?.id?.toString() === currentUser.id?.toString()) || currentUserRole === "central") &&
                                        <Button
                                            variant="light"
                                            style={{width: 170, marginBlock: 5}}
                                            onClick={handleOpenChat}
                                        >
                                            <span>Abrir Chat</span>

                                        </Button>}
                                    {(selectedCard?.users?.length < 2 || currentUserRole === "central") && <Button
                                        variant="light"
                                        dissable="false"
                                        style={{width: 170, marginBlock: 5}}
                                        onClick={handleOpenAddMember}
                                    >
                                        <span>Add Membro</span>

                                    </Button>}
                                    {(selectedCard?.users?.some(user => user?.id?.toString() === currentUser.id?.toString()) || currentUserRole === "central") &&
                                        <Button
                                            variant="light"
                                            dissable="true"
                                            style={{width: 170, marginBlock: 5}}
                                            onClick={handleOpenAddTag}
                                        >
                                            <span>Add Tags</span>
                                        </Button>}
                                    {/* currentUserRole === "central" &&
                                        <Button
                                            variant="light"
                                            dissable="true"
                                            style={{width: 170, marginBlock: 5}}
                                            onClick={handleOpenAddTag}
                                        >
                                            <i className='bx bx-purchase-tag-alt'></i>
                                            <span>Histórico</span>
                                        </Button>*/}
                                </React.Fragment>


                            )}


                            {/* Seção de Membros */}
                            {/*<div style={{marginTop: '20px'}}>
                               <h5>Membros</h5>
                               <ListGroup>
                                   {members.map((member, index) => (
                                       <ListGroup.Item key={index}>
                                           {member}
                                       </ListGroup.Item>
                                   ))}
                               </ListGroup>
                               <Form.Control
                                   type="text"
                                   placeholder="Adicionar membro..."
                                   value={newMember}
                                   onChange={(e) => setNewMember(e.target.value)}
                                   onKeyPress={(e) => {
                                       if (e.key === 'Enter') {
                                           handleAddMember();
                                       }
                                   }}
                                   style={{marginTop: '10px'}}
                               />
                               <Button variant="primary" onClick={handleAddMember} style={{marginTop: '10px'}}>
                                   Adicionar Membro
                               </Button>
                           </div>*/}

                            {/* Seção de Tags */}
                            {/*<div style={{marginTop: '20px'}}>
                                   <h5>Tags</h5>
                                   <ListGroup>
                                       {tags.map((tag, index) => (
                                           <ListGroup.Item key={index}>
                                               {tag}
                                           </ListGroup.Item>
                                       ))}
                                   </ListGroup>
                                   <Form.Control
                                       type="text"
                                       placeholder="Adicionar tag..."
                                       value={newTag}
                                       onChange={(e) => setNewTag(e.target.value)}
                                       onKeyPress={(e) => {
                                           if (e.key === 'Enter') {
                                               handleAddTag();
                                           }
                                       }}
                                       style={{marginTop: '10px'}}
                                   />
                                   <Button variant="primary" onClick={handleAddTag} style={{marginTop: '10px'}}>
                                       Adicionar Tag
                                   </Button>
                               </div>*/}
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSubmitCard}>
                        Salvar Alterações
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showArchiveModal} dialogClassName="delete-custom-modal" onHide={handleCloseArchiveModal}
                   size="sm" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">{selectedCard?.archivedCard === true ? "Desarquivar" : "Arquivar"} </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div
                        className="d-flex align-items-center justify-content-center">
                        <small>Deseja {selectedCard?.archivedCard === true ? "Desarquivar" : "Arquivar"} - {selectedCard ? selectedCard.title : ''} ?</small>
                    </div>


                    <div
                        className="d-flex align-items-center justify-content-center">
                        <button
                            style={{marginRight: 20}}
                            className="btn btn-secondary update-contact"
                            type="button"
                            onClick={() => handleArchive(selectedCard)}
                            data-bs-original-title=""
                            title="">{selectedCard?.archivedCard === true ? "Desarquivar" : "Arquivar"}
                        </button>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleCloseArchiveModal}
                            data-bs-dismiss="modal"
                            data-bs-original-title=""
                            title="">Cancelar
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showMoveModal} onHide={handleCloseMoveModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Mover Card </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-control">
                        <div className="dropdown">
                            <button className="btn  dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" style={{
                                width: "100%",
                                color: selectedLane ? "#ffffff" : "#050404",
                                backgroundColor: selectedLane ? selectedLane.color : ''
                            }}>
                                {selectedLane ? selectedLane.title : "Selecione a Coluna"}
                            </button>
                            <ul className="dropdown-menu" style={{width: "100%"}}>
                                {lanes.map((option, index) => (
                                    <li key={option.lane_id} tabIndex={index}
                                        onClick={() => handleOptionLaneChange(option)}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "4px 8px"
                                        }}>
                                            <div
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                    backgroundColor: option.color || "#a10889",
                                                    borderRadius: "2px",
                                                }}
                                            ></div>
                                            <span>{option.title}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>


                    <div
                        className="mt-10 d-flex align-items-center justify-content-center">
                        <button
                            style={{marginRight: 20}}
                            className="btn btn-secondary update-contact"
                            type="button"
                            onClick={handleMoveCard}
                            data-bs-original-title=""
                            title=""> Salvar
                        </button>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleCloseMoveModal}
                            data-bs-dismiss="modal"
                            data-bs-original-title=""
                            title="">Cancelar
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showAddMemberModal} onHide={handleCloseAddMemberModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Adicionar Membros </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-10 col-md-12">
                        <label>Atendente</label>
                        <Select
                            id="opt_interest"
                            name="opt_interest"
                            classNamePrefix="react-select"
                            options={optionsOperational}
                            onChange={handleUserSelection}
                            placeholder="Selecione o Atendente"
                        />
                    </div>

                    {currentCompany.type_company === "advocacia" && <div className="mb-10 col-md-12">
                        <label>Advogado</label>
                        <Select
                            id="opt_interest"
                            name="opt_interest"
                            classNamePrefix="react-select"
                            options={optionsAttorney}
                            onChange={handleUserSelection}
                            placeholder="Selecione o Advogado"
                        />
                    </div>}


                    <div className=" mt-10 d-flex align-items-center justify-content-center">
                        <button
                            style={{marginRight: 20}}
                            className="btn btn-secondary update-contact"
                            type="button"
                            onClick={handleAddMember}
                            data-bs-original-title=""
                            title="">Salvar
                        </button>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleCloseAddMemberModal}
                            data-bs-dismiss="modal"
                            data-bs-original-title=""
                            title="">Cancelar
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showAddTagModal} onHide={handleCloseAddTagModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Adicionar Tags </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {currentCompany.type_company === "advocacia" && <div className="mb-10 col-md-12">
                        <label>Interresse</label>
                        <select
                            id="opt_interest"
                            name="opt_interest"
                            className="form-control"
                            onChange={handleChangeInterest}
                        >
                            <option
                                defaultValue> {"Selecione o Interresse"}</option>
                            <option key={"labor_lawyer"} tabIndex={0} value={JSON.stringify({
                                code: "labor_lawyer",
                                title: "Advogado Trabalhista"
                            })}>Advogado Trabalhista
                            </option>
                            <option key={"civil_lawyer"} tabIndex={1}
                                    value={JSON.stringify({code: "civil_lawyer", title: "Advogado Civil"})}>Advogado
                                Civil
                            </option>
                            <option key={"criminal_lawyer"} tabIndex={2} value={JSON.stringify({
                                code: "criminal_lawyer",
                                title: "Advogado Criminal"
                            })}>Advogado Criminal
                            </option>
                            <option key={"family_lawyer"} tabIndex={3} value={JSON.stringify({
                                code: "family_lawyer",
                                title: "Advogado de Família"
                            })}>Advogado de Família
                            </option>
                            <option key={"tax_lawyer"} tabIndex={4} value={JSON.stringify({
                                code: "tax_lawyer",
                                title: "Advogado Tributarista"
                            })}>Advogado Tributarista
                            </option>
                            <option key={"environmental_lawyer"} tabIndex={5} value={JSON.stringify({
                                code: "environmental_lawyer",
                                title: "Advogado Ambiental"
                            })}>Advogado Ambiental
                            </option>
                            <option key={"business_lawyer"} tabIndex={6} value={JSON.stringify({
                                code: "business_lawyer",
                                title: "Advogado Empresarial"
                            })}>Advogado Empresarial
                            </option>
                            <option key={"real_estate_lawyer"} tabIndex={7} value={JSON.stringify({
                                code: "real_estate_lawyer",
                                title: "Advogado Imobiliário"
                            })}>Advogado Imobiliário
                            </option>
                            <option key={"social_security_lawyer"} tabIndex={8} value={JSON.stringify({
                                code: "social_security_lawyer",
                                title: "Advogado Previdenciário"
                            })}>Advogado Previdenciário
                            </option>
                            <option key={"consumer_lawyer"} tabIndex={9} value={JSON.stringify({
                                code: "consumer_lawyer",
                                title: "Advogado do Consumidor"
                            })}>Advogado do Consumidor
                            </option>
                            <option key={"international_lawyer"} tabIndex={10} value={JSON.stringify({
                                code: "international_lawyer",
                                title: "Advogado Internacional"
                            })}>Advogado Internacional
                            </option>
                            <option key={"intellectual_property_lawyer"} tabIndex={11} value={JSON.stringify({
                                code: "intellectual_property_lawyer",
                                title: "Advogado de Propriedade Intelectual"
                            })}>Advogado de Propriedade
                                Intelectual
                            </option>
                            <option key={"military_lawyer"} tabIndex={12} value={JSON.stringify({
                                code: "military_lawyer",
                                title: "Advogado Militar"
                            })}>Advogado Militar
                            </option>
                            <option key={"administrative_lawyer"} tabIndex={13} value={JSON.stringify({
                                code: "administrative_lawyer",
                                title: "Advogado Administrativo"
                            })}>Advogado Administrativo
                            </option>
                            <option key={"human_rights_lawyer"} tabIndex={14} value={JSON.stringify({
                                code: "human_rights_lawyer",
                                title: "Advogado de Direitos Humanos"
                            })}>Advogado de Direitos Humanos
                            </option>

                        </select>
                    </div>}
                    <div className="mb-10 col-md-12">
                        <label>Tag Primaria</label>
                        <div className="form-control dropdown">
                            <button className="btn  dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" style={{
                                width: "100%",
                                color: selectedTagPrimary ? "#ffffff" : "#050404",
                                backgroundColor: selectedTagPrimary ? selectedTagPrimary.color : ''
                            }}>
                                {selectedTagPrimary ? selectedTagPrimary.title : "Selecione a Tag"}
                            </button>
                            <ul className="dropdown-menu" style={{width: "100%"}}>
                                {referenceTags.map((option, index) => (
                                    <li key={option.code} tabIndex={index}
                                        onClick={() => handleOptionTagPrimaryChange(option)}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "4px 8px"
                                        }}>
                                            <div
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                    backgroundColor: option.color || "#a10889",
                                                    borderRadius: "2px",
                                                }}
                                            ></div>
                                            <span>{option.title}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {selectedTagPrimary && <div className="mb-10 col-md-12">
                        <label>Tag Segundaria</label>
                        <div className="form-control dropdown">
                            <button className="btn  dropdown-toggle" type="button"
                                    data-bs-toggle="dropdown" style={{
                                width: "100%",
                                color: selectedTagSegundary ? "#ffffff" : "#050404",
                                backgroundColor: selectedTagSegundary ? selectedTagSegundary.color : ''
                            }}>
                                {selectedTagSegundary ? selectedTagSegundary.title : "Selecione a Tag"}
                            </button>
                            <ul className="dropdown-menu" style={{width: "100%"}}>
                                {tags[selectedTagPrimary.code].map((option, index) => (
                                    <li key={option.code} tabIndex={index}
                                        onClick={() => handleOptionTagSegundaryChange(option)}>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "8px",
                                            padding: "4px 8px"
                                        }}>
                                            <div
                                                style={{
                                                    width: "12px",
                                                    height: "12px",
                                                    backgroundColor: option.color || "#a10889",
                                                    borderRadius: "2px",
                                                }}
                                            ></div>
                                            <span>{option.title}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>}


                    <div className=" mt-10 d-flex align-items-center justify-content-center">
                        <button
                            style={{marginRight: 20}}
                            className="btn btn-secondary update-contact"
                            type="button"
                            onClick={handleAddTag}
                            data-bs-original-title=""
                            title="">Salvar
                        </button>
                        <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleCloseAddTagModal}
                            data-bs-dismiss="modal"
                            data-bs-original-title=""
                            title="">Cancelar
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={showEditTaskModal} onHide={handleCloseEditTaskModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Editar Tarefa</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleTaskSubmit}
                          className="form-bookmark needs-validation"
                          id="bookmark-form" noValidate="">
                        <div className="row g-2">
                            <div className="mt-0 mb-3 col-md-12">
                                <label>Descrição</label>
                                <div
                                    className="row">
                                    <div
                                        className="col-sm-12">
                                        <input
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={selectedTask?.text}
                                            required
                                            placeholder="Por favor, digite a tarefa"
                                            onChange={handleChangeTitleTask}
                                            data-bs-original-title=""
                                            title="Por favor, digite a tarefa"/>
                                    </div>

                                </div>
                            </div>

                        </div>


                        <div
                            className="d-flex align-items-center justify-content-center">
                            <button
                                style={{marginRight: 20}}
                                className="btn btn-secondary update-contact"
                                type="submit"
                                data-bs-original-title=""
                                title="">Salvar
                            </button>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleCloseEditTaskModal}
                                data-bs-dismiss="modal"
                                data-bs-original-title=""
                                title="">Cancelar
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={showChatModal} onHide={handleCloseChatModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Chat</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>

                            <ChatComponent chatId={selectedCard?.links.chat} currentUser={currentUser}
                                           currentCompany={currentCompany} selectedCard={selectedCard}
                                           profile_picture={profile_picture}></ChatComponent>

                        </Col>
                    </Row>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseChatModal}>
                        Fechar
                    </Button>
                </Modal.Footer>*/}
            </Modal>


        </React.Fragment>
    );
}
export default useDetailsModal;