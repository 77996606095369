import React from "react";
import axios from 'axios';
import InputMask from "react-input-mask";
import Loader from "../Loader";
export default class  EditServiceInfoChatContact  extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedOptionId: 0,
            selectedOptionDepartment: '',
            selectedOptionUser:'',
            selectedOptionOperationalUser:'',
            selectedOptionAttorneyUser:'',
            selectedOptionTicket:'',
            openOptionsSelectedUser: false,
            openOptionsSelectedProcessStatus: false,
            selectedProcessStatus: '',
            selectedQueryType: '',
            queryType: [],
            users: [],
            tags: [],
            referenceTags: [],
            operationalUsers: [],
            attorneyUsers: [],
            isChecked: false,
            isLoading: false,
            tagSelected: '',
            interest: '',
            operational_user_name: '',
            attorney_user_name: '',
            numProcess: '',
            email: '',
        };
    }

    handleOptionUserChange = (value, index, role_user) => {
        const {infoContact}  = this.props;
        const {users, operationalUsers,attorneyUsers}  = this.state;

        if(role_user === "operational"){
            this.setState({selectedOptionOperationalUser: value });
            const filteredUser = operationalUsers.filter(user => user[1].toString() === value);
            infoContact.operational_user_name = filteredUser[0][0];
        }else{
            if(role_user === "attorney"){
                this.setState({selectedOptionAttorneyUser: value });
                const filteredUser = attorneyUsers.filter(user => user[1].toString() === value);
                infoContact.attorney_user_name = filteredUser[0][0];
            }
        }





    }
    handleFormSubmitServiceInfo = (event) => {
        event.preventDefault();
        const {selectedOptionUser,selectedOptionOperationalUser,selectedOptionAttorneyUser,selectedProcessStatus,selectedQueryType} = this.state;
        const {infoContact}  = this.props;


            this.setState({ isLoading:   true});
            const formData = new FormData();
            formData.append('chat_id', infoContact.id);
            formData.append('id', infoContact.contactId);
            formData.append('user', selectedOptionUser);
            formData.append('operational_user', selectedOptionOperationalUser);
            formData.append('attorney_user', selectedOptionAttorneyUser);
            formData.append('processStatus', selectedProcessStatus);
            formData.append('queryType', selectedQueryType);


            fetch('/messagings/user_from_chat', {
                method: 'POST',
                body: formData
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao editar contact.');
                }
                return response.json();
            }).then(data => {
                this.props.fetchGetChats();
                let interval = setInterval(() => {
                    clearInterval(interval); // Clear the interval once stopLoader is called
                    this.stopLoader();

                }, 15000);

            }).catch(error => {
                console.error('Erro ao editar contact:', error);});



    }
    stopLoader = () => {

        this.setState({ isLoading:   false})
        this.props.onCloseEditMoreInfo("editServiceInfo", "editServiceMoreInfo", "serviceInfo");
    }
    async getUsers() {

        const {currentCompany}  = this.props;


        let response = await axios.get('/messagings/get_users.json',{
            params: {
                id: currentCompany.id,

            }
        });

        this.setState({users: response.data.users})
        this.setState({operationalUsers: response.data.operational_users})
        this.setState({attorneyUsers: response.data.attorney_users})

        this.setState({openOptionsSelectedUser: true });



    }
    async getTags(){
        let response = await axios.get('/tags/get_tags.json');

        this.setState({tags: response.data.tags, referenceTags: response.data.references})
    }
    handleOptionProcessStatusChange = (value) => {

        const { tags} = this.state;
        const {
            infoContact
        } = this.props;
        this.setState({selectedProcessStatus:  JSON.parse(value).code });
        infoContact.chatTag =  JSON.parse(value).code

        this.setState({openOptionsSelectedProcessStatus: true });
        this.setState({queryType:  tags[ JSON.parse(value).code]  });


    }
    handleOptionQueryTypeChange = (value) => {
        const {
            infoContact
        } = this.props;

        const tag = JSON.parse(value)

        this.setState({selectedQueryType: tag.code });
        infoContact.chatSubTag = tag.code
    }
    componentDidMount() {
        const {
            infoContact
        } = this.props;
        this.setState({selectedOptionUser: infoContact.user_name });
        this.getUsers()
        this.getTags()

    }
    componentWillUnmount() {}


    render() {

        const {
            tagSelected,
            interest,
            numProcess,
            selectedOptionUser,
            openOptionsSelectedProcessStatus,
            queryType,
            users,
            tags,
            referenceTags,
            operationalUsers,
            attorneyUsers,
            isLoading
        } = this.state;
        const {
            infoContact,
            instance_id,
            tickets,
            myService,
            user_role,
            onMyService,
            selectedChatInfo,
            departments,
            process_status,
            query_types_new_process,
            query_types_in_progress,
            query_types_in_close,
            currentCompany,
            onCloseEditMoreInfo,
            lawyers,
            loader
        } = this.props;




        return (<div>
            <form className="col-xl-12 mt-20" action="#" onSubmit={this.handleFormSubmitServiceInfo}
                  method="post" acceptCharset="utf-8">

                <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="email-general">
                            <h6 className="mb-3 text-info">Editar Informações de Atendimento</h6>
                            {isLoading ? <Loader src={loader} className= "loader-edit"/> :

                                <>

                                    {(user_role === "central"  || user_role === "support" || user_role === "operational") &&
                                        <div className="mb-4 mt-24 ">
                                            <div className="form-group">
                                                <label>Atendente</label>
                                                <select className="form-control" id="idDepartment"
                                                        onChange={(e) => this.handleOptionUserChange(e.target.value, e.target.tabIndex,"operational" )}>
                                                    <option
                                                        defaultValue> {infoContact.operational_user_name || "Selecione o Atendente"}</option>

                                                    {operationalUsers.map((option, index) => (
                                                        <option key={option[1]} tabIndex={index} value={option[1]}>{option[0]}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {currentCompany.type_company === "advocacia" && <div className="mb-4 mt-24 ">
                                        <div className="form-group">
                                            <label>Advogado</label>
                                            <select className="form-control" id="idAttorney"
                                                    onChange={(e) => this.handleOptionUserChange(e.target.value, e.target.tabIndex, "attorney")}>
                                                <option
                                                    defaultValue> {infoContact.attorney_user_name || "Selecione o Advogado"}</option>

                                                {attorneyUsers.map((option, index) => (
                                                    <option key={option[1]} tabIndex={index} value={option[1]}>{option[0]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>}
                                    <div className="mb-4 mt-24 ">
                                        <div className="form-group">
                                            <label>Etiqueta</label>
                                            <select className="form-control" id="idTicket"
                                                    onChange={(e) => this.handleOptionProcessStatusChange(e.target.value)}>
                                                <option defaultValue>Selecione o Etiqueta</option>
                                                {referenceTags.length > 0 && referenceTags.map((option, index) => (
                                                    <option key={index} value={JSON.stringify({ code: option.code, color: option.color , referenceCode: option.reference_code }) }>{option.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {openOptionsSelectedProcessStatus && <div>
                                        <div className="mb-4 mt-24 ">
                                            <div className="form-group">
                                                <label>Etapa</label>
                                                <select className="form-control" id="idDepartment"
                                                        onChange={(e) => this.handleOptionQueryTypeChange(e.target.value)}>
                                                    <option defaultValue>Selecione a etapa</option>
                                                    {queryType.map((option, index) => (
                                                        <option key={index} value={JSON.stringify({ code: option.code})}>{option.title}</option>

                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                    </div>}
                                    <div>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <button
                                                    style={{marginRight: 20}}
                                                    className="btn btn-secondary update-contact"
                                                    type="submit"
                                                    data-bs-original-title=""
                                                    title="">Salvar
                                                </button>
                                                <button
                                                    className="btn btn-primary"
                                                    type="button"
                                                    onClick={() => onCloseEditMoreInfo("editServiceInfo", "editServiceMoreInfo", "serviceInfo")}
                                                    title="">Cancelar
                                                </button>
                                            </div>

                                        </div>
                                </>
                            }


                        </div>
                    </div>
                </div>


            </form>
            </div>
        );
    }
}