import React, { useState, useEffect } from 'react';
import Board from 'react-trello';
import axios from 'axios';
import CustomCard from './CustomCard';
import CustomLaneHeader from './CustomLaneHeader';
import CustomLaneFooter from './CustomLaneFooter';
import CustomNewCardForm from './CustomNewCardForm';
import CustomAddCardLink from './CustomAddCardLink';
import './style/kanban.css.scss';
import { Modal} from 'react-bootstrap';

import DetailsModal from "./hooks/useDetailsModal";
import { ChromePicker } from 'react-color';
import ImageWithFallback from "../ImageWithFallback";
import ActionCable from "actioncable";

const getId = () => `${String(+new Date()).slice(6)}`;
class KanbanBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.externalData.lanes.length > 0 ? props.externalData : {
                lanes: [
                    {
                        id: getId(),
                        title: 'Entradas',
                        color: "#65bd52",
                        department:"initial",
                        isFixed: true,
                        draggable: false,
                        cards: [],
                    },
                ],
            },
            filteredCards: props.externalData.lanes.length > 0 ? props.externalData : {
                lanes: [
                    {
                        id: getId(),
                        title: 'Entradas',
                        color: "#65bd52",
                        department:"initial",
                        isFixed: true,
                        draggable: false,
                        cards: [],
                    },
                ],
            },
            usersCount: 0,
            showModal: false,
            showCreatedLineModal: false,
            showDeletedLineModal: false,
            selectedDepartment:null,
            selectedDepartmentName:"",
            selectedCard: null,
            selectedLane: null,
            color: '#fff',
            searchTerm: '',
            showArchived: false,
            titleLane: ''
        };
    }

    applyFilters = (term, showArchivedState) => {
        this.setState({
            filteredCards: {
                lanes: Array.isArray(this.state.data?.lanes)
                    ? this.state.data.lanes.map((lane) => {
                        const cards = Array.isArray(lane?.cards) ? lane.cards : [];

                        let filtered = cards.filter((card) =>
                            showArchivedState ? card.archivedCard : !card.archivedCard
                        );

                        if (term) {
                            filtered = filtered.filter((card) =>
                                card.title?.toLowerCase().includes(term.toLowerCase())
                            );
                        }

                        return { ...lane, cards: filtered };
                    })
                    : [],
            },
        });
    };

    handleTagColor = (s) => {
        switch (s) {
            case 'simulation':
            case 'NEW_PROCESS':
            case 'IN_PROGRESS':
                return 'badge-primary';
            case 'approved':
                return 'badge-success';
            case 'reproved':
                return 'badge-danger';
            case 'resend_documents':
            case 'under_analysis':
            case 'send_documents':
            case 'company_analysing':
            case 'awaiting_contract':
            case 'CLOSED':
                return 'badge-warning';
            case 'REPASSADO_PARA_EMPRESA':
            case 'PRE_APROVADO':
                return 'badge-success';
            case 'hired':
            case 'fill_data':
                return 'badge-info';
            case 'released':
                return 'badge-purple';
            default:
                return 'badge-success';
        }
    }

    componentDidMount() {
        const{showArchived, searchTerm}=this.state
        this.applyFilters(searchTerm, showArchived)
        let self = this;
        const cable = ActionCable.createConsumer('/cable');
            // Subscribing to Chat Channel
        this.cardChannel = cable.subscriptions.create("NewCardNotificationChannel", {
            connected: () => {
                console.log('Connected to NewCardNotificationChannel - KANBAN');
            },
            received: (data) => {
                console.log( data)

                console.log( "entrou cardChannel ")
                if(data.tenant_name === self.props.currentTenant){
                    this.fetchLines(); // Chama a função fetchGetChats quando uma nova mensagem for recebida
                }





            },
        });
    }
    componentDidUpdate(prevProps) {
        if (prevProps.externalData !== this.props.externalData) {
            const newData = this.props.externalData.lanes.length > 0 ? this.props.externalData : {
                lanes: [
                    {
                        id: getId(),
                        title: 'Entradas',
                        color: "#65bd52",
                        isFixed: true,
                        draggable: false,
                        cards: [],
                    },
                ],
            };
            this.setState({
                data: newData,
                filteredCards: newData
            });
        }
    }

    componentWillUnmount() {
        if (this.cardChannel) {
            this.cardChannel.unsubscribe(); // Desinscreve do canal quando o componente for desmontado
        }

    }
    handleCardClick = (cardId, metadata, laneId) => {
        const lane = this.state.filteredCards.lanes.find(lane => lane.id === laneId);
        const card = lane?.cards.find(card => card.id === cardId);

        if (card) {
            this.setState({ selectedCard: card, showModal: true });
        }
    };

   

    handleLaneDragEnd = (removedIndex, addedIndex) => {
        const lanes = [...this.state.data.lanes];

        if (lanes[removedIndex]?.isFixed) {
            alert('A lane fixa não pode ser movida.');
            return;
        }

        const [movedLane] = lanes.splice(removedIndex, 1);
        lanes.splice(addedIndex, 0, movedLane);

        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                lanes,
            }
        }));

        const laneOrder = lanes.map((lane, index) => ({
            id: lane.id,
            position: index + 1,
        }));

        axios.post('/kanbans/update_lane_order', { laneOrder })
            .then(() => console.log('Ordem das lanes atualizada no servidor.'))
            .catch((error) => console.error('Erro ao atualizar a ordem das lanes:', error));
    };


    handleCardMove = async (fromLaneId, toLaneId, cardId, index) => {
        try {

            const formData = new FormData();
            formData.append('cardId',cardId);
            formData.append('fromLaneId',fromLaneId);
            formData.append('toLaneId',toLaneId);
            formData.append('index',index);
            axios.post('/kanbans/move_card', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    console.log('Resposta do servidor:', response.data);
                    axios.get('/kanbans/get_lines',  {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(response => {

                            this.setState({data: response.data.data});
                            this.setState({filteredCards: response.data.data});
                        })
                        .catch(error => {
                            console.error('Erro ao buscar lines :', error);
                        });

                })
                .catch(error => {
                    console.error('Erro ao mover card:', error);
                });

        } catch (error) {
            console.error('Erro ao mover o cartão:', error);
        }
    };

    toggleShowArchived = () => {
        const{showArchived, searchTerm}=this.state

        this.setState((prevState) => ({ showArchived: !prevState.showArchived }));
        this.applyFilters(searchTerm, !showArchived)
    };

    openModal = (id) => {
        const myModal = new bootstrap.Modal(document.getElementById(id), {
            keyboard: false
        });
        myModal.show();
    };

    openCreatedModal = ()=>{
        this.setState({  showCreatedLineModal: true });
    }
    closeCreatedModal = ()=>{
        this.setState({  showCreatedLineModal: false });
    }

    openDeletedModal = ()=>{
        this.setState({  showDeletedLineModal: true });
    }
    closeDeletedModal = ()=>{
        this.setState({  showDeletedLineModal: false });
    }
    closeModal = (id) => {
        document.getElementById(id).style.display = "none";
        document.querySelectorAll(".modal-backdrop").forEach((elemento) => {
            elemento.classList.remove("modal-backdrop");
        });
    };

    handleSearchChange = (term) => {
        this.setState({ searchTerm: term });
    };
    handleColorChange = (event) => {
        this.setState({ color: event.target.value });
    };

    handleChangeNameLane = (e) => {
        this.setState({ titleLane: e.target.value });
    };
    handleChangeSelectedLane = (lane) => {
        this.setState({ selectedLane: lane });
    };
    handleSaveSubmit = async (e) => {
        e.preventDefault();

        if (this.state.titleLane) {
            const newLane = {
                id: getId(),
                title: this.state.titleLane,
                color: this.state.color,
                department: this.state.selectedDepartment,
                departmentName: this.state.selectedDepartmentName,
                isFixed: false,
                draggable: true,
                cards: []
            };

            this.setState((prevState) => ({
                filteredCards: {
                    lanes: [...prevState.filteredCards.lanes, newLane]
                },
                titleLane: '',
                color: '#fff'
            }));


            try {

                const formData = new FormData();

                formData.append('newLane', JSON.stringify(newLane));
                formData.append('filteredCards', JSON.stringify(this.state.filteredCards));
                formData.append('currentTenant', this.props.currentTenant);

                axios.post('/kanbans/add_line', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        console.log('Resposta do servidor:', response.data);
                        axios.get('/kanbans/get_lines',  {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                            .then(response => {
                                this.setState({data: response.data.data});
                                this.setState({filteredCards: response.data.data});
                                this.closeCreatedModal();
                            })
                            .catch(error => {
                                console.error('Erro ao buscar lines :', error);
                            });

                    })
                    .catch(error => {
                        console.error('Erro ao mover card:', error);
                    });

            } catch (error) {
                console.error('Erro ao mover o cartão:', error);
            }


        }
    };

    fetchLines  = async () => {

        console.log( "chamou fetchLines")
        axios.get('/kanbans/get_lines',  {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                this.setState({data: response.data.data});
                this.setState({filteredCards: response.data.data});

            })
            .catch(error => {
                console.error('Erro ao buscar lines :', error);
            });
    }

    handleSearchTwo = (laneId, term, showArchived) => {


        this.setState((prevState) => {
            const updatedLanes = prevState.data.lanes.map((lane) => {
                if (lane.id === laneId) {
                    // Filtrar os cards com base no termo de busca e configuração de arquivados
                    const filteredCards = lane.cards.filter((card) => {
                        const matchesTerm = card.title.toLowerCase().includes(term.toLowerCase());
                        const matchesArchived = showArchived || !card.archived; // Exibir arquivados conforme o parâmetro
                        return matchesTerm && matchesArchived;
                    });

                    // Substituir os cards por um array vazio caso nenhum resultado seja encontrado
                    return {
                        ...lane,
                        filteredCards: filteredCards.length > 0 ? filteredCards : [], // Vazio se nenhum card corresponder
                    };
                }

                return lane; // Retorna os outros lanes sem alterações
            });

            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    lanes: updatedLanes,
                },
                searchTerm: term, // Atualiza o termo de busca no estado global
            };
        });
    };


    handleCloseModal = () => this.setState({ showModal: false });


    handleSaveChanges = () => {
        // Salva as mudanças no card aqui, se necessário
        this.handleCloseModal();
    };

    handleLaneDeleteChanges = async (e) => {
        e.preventDefault();

        try {
            // Envia a requisição DELETE com os dados como parâmetros
            const response = await axios.delete('/kanbans/delete_line', {
                data: {
                    laneId: this.state.selectedLane?.laneId,
                    lane: this.state.selectedLane,
                },
                headers: {
                    'Content-Type': 'application/json', // JSON é mais comum para DELETE
                },
            });

            console.log('Resposta do servidor:', response.data);

            // Busca as linhas atualizadas
            const getLinesResponse = await axios.get('/kanbans/get_lines', {
                headers: {
                    'Content-Type': 'application/json', // Aqui pode ser JSON para GET
                },
            });

            this.setState({
                data: getLinesResponse.data.data,
                filteredCards: getLinesResponse.data.data,
            });

            // Fecha o modal
            this.closeDeletedModal();
        } catch (error) {
            console.error('Erro ao excluir Lane:', error);
        }
    };
    handleDataRChange = (newData) => {
        const laneOrder = newData.lanes.map((lane, index) => ({
            id: lane.id,
            position: index + 1,
        }));

        this.setState({ data: newData });

        axios.post('/kanbans/update_lane_order', { laneOrder })
            .then(() => console.log('Ordem das lanes atualizada no servidor.'))
            .catch((error) => console.error('Erro ao atualizar a ordem das lanes:', error));
    };
    handleDataChange = (newData) => {
        this.setState({ data: newData});
    }
    handleFilteredCardsChange= (newData) => {
        this.setState({ filteredCards: newData});
    }
    handleOptionDepartmentChange = (e) => {
        const { id, name } = JSON.parse(e.target.value); // Parse o JSON para extrair os valores
        this.setState({
            selectedDepartment: id,
            selectedDepartmentName: name,
        });
    };



    render() {
        const {showArchived,filteredCards, selectedCard , data , showModal, color,showCreatedLineModal, usersCount, showDeletedLineModal, selectedLane} = this.state
        const {departments, users, currentUserRole} = this.props

        return (
            <React.Fragment>
                <div className="box">
                    <div className="box-header">
                        <div className="team-name">
                            <a href="#" className="team">
                                <div className="icon"><i
                                    className="fas fa-columns"></i></div>
                                <h5>Kanban</h5>
                            </a>
                        </div>
                        <div className="action">
                            {(currentUserRole === "central" || currentUserRole === "support") &&
                                <a href="#" onClick={this.toggleShowArchived}
                                   className="invite"> {showArchived ? 'Ver Ativos' : 'Ver Arquivados'}</a>
                            }

                            {(currentUserRole === "central" || currentUserRole === "support" ) &&
                                <a href="#" onClick={this.openCreatedModal} className="add">
                                <i className="fa-solid fa-plus"></i> Criar Coluna </a>
                            }
                        </div>
                        <div className="box-right d-flex">
                            <ul className="user-list s2">

                                {users.slice(0, 3).map((user) => (
                                    <li key={user[1]}>
                                        <ImageWithFallback
                                            src={user[2]}
                                            style={{filter: "none" }}
                                            title={user[0]}
                                            fallback={this.props.image_avatar}
                                            alt={`user${user[1]}`}
                                        />
                                    </li>))}

                                {users.length > 3 && (
                                    <li   title={`Outros:\n ${users.slice(3).map((user) => user[0]).join('\n ')}`}  className="total">
                                        <span>+{users.length - 3}</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="box-body pd-0">

                        <Board
                            data={filteredCards}
                            draggable
                            editable
                            onCardClick={this.handleCardClick}

                            onCardMoveAcrossLanes={this.handleCardMove}
                            components={{
                                Card: (props) => (
                                    <CustomCard
                                        handleTagColor={this.handleTagColor}
                                        handleCloseModal={this.handleCloseModal}
                                        handleSaveChanges={this.handleSaveChanges}
                                        currentUserId={this.props.currentUserId}
                                        currentUserRole={this.props.currentUserRole}
                                        image_avatar={this.props.image_avatar}
                                        closeModal={this.closeModal}
                                        {...props}
                                        onClick={() => this.handleCardClick(props.id, props, props.laneId)}
                                    />
                                ),
                                LaneHeader: (laneProps) => (
                                    <CustomLaneHeader

                                        setSearchTerm={this.handleSearchChange}
                                        openDeletedModal={this.openDeletedModal}
                                        handleChangeSelectedLane={this.handleChangeSelectedLane}
                                        searchTerm={this.state.searchTerm}
                                        departmentName={laneProps.department_name}
                                        currentUserRole={this.props.currentUserRole}
                                        title={laneProps.title}
                                        draggable={laneProps.draggable}
                                        lanes={this.state.filteredCards}
                                        laneId={laneProps.id}
                                        cards={laneProps.cards}
                                        color={laneProps.color}
                                        onSearch={() => this.handleSearchTwo(laneProps.id, this.state.searchTerm, showArchived)}
                                    />
                                ),
                                AddCardLink: CustomAddCardLink,
                            }}
                            style={{
                                backgroundColor: 'transparent',
                                overflowX: "auto"
                            }}

                        />
                    </div>
                </div>


                <Modal show={showCreatedLineModal} onHide={this.closeCreatedModal} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title">Nova Coluna </h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSaveSubmit}
                              className="form-bookmark needs-validation"
                              id="bookmark-form" noValidate="">
                            <div className="row g-2">
                                <div className="mt-0 mb-3 col-md-12">
                                    <label>Nome</label>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <input
                                                className="form-control"
                                                id="name"
                                                name="name"
                                                type="text"
                                                required
                                                placeholder="Por favor, digite o nome"
                                                onChange={this.handleChangeNameLane}
                                                data-bs-original-title=""
                                                title="Por favor, digite o nome"/>
                                        </div>

                                    </div>
                                </div>
                                <div className="mt-0 mb-3 col-md-12">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Departamento</label>
                                            <select className="form-control" id="idDepartment"
                                                    onChange={(e) => this.handleOptionDepartmentChange(e)}>
                                                <option
                                                    defaultValue> {"Selecione o departamento"}</option>

                                                {departments.map((option, index) => (
                                                    <option key={option[1]} tabIndex={index}
                                                            value={JSON.stringify({
                                                                id: option[1],
                                                                name: option[0]
                                                            })}>{option[0]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Cor da Coluna</label>
                                    <input
                                        style={{ height: 35 }}
                                        className="form-control"
                                        onChange={this.handleColorChange}
                                        type="color"
                                        name="color"
                                        required
                                        value={this.state.color}
                                    />
                                </div>

                                {/* <div className="mt-0 mb-3 col-md-12 ">

                                    <label>Cor da Coluna</label>
                                    <div style={{marginTop: '10px', display: 'flex', alignItems: 'center'}}>
                                        <div
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                backgroundColor: color,
                                                border: '1px solid #000',
                                                marginRight: '10px'
                                            }}
                                        />

                                        <span>{color}</span>
                                    </div>

                                    <ChromePicker color={color} onChange={this.handleColorChange}/>

                                </div>*/}
                            </div>


                            <div className="d-flex align-items-center justify-content-center">
                                <button
                                    style={{marginRight: 20}}
                                    className="btn btn-secondary update-contact"
                                    type="submit"
                                    data-bs-original-title=""
                                    title="">Salvar
                                </button>
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    onClick={this.closeCreatedModal}
                                    data-bs-dismiss="modal"
                                    data-bs-original-title=""
                                    title="">Cancelar
                                </button>
                            </div>
                        </form>


                    </Modal.Body>
                </Modal>
                <Modal  dialogClassName="delete-custom-modal" show={showDeletedLineModal} onHide={this.closeDeletedModal} size="sm" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h5 className="modal-title"> Deletar Coluna</h5>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div
                            className="d-flex align-items-center justify-content-center">
                            <small>Deseja deletar a coluna {selectedLane?.title}  ?</small>
                        </div>


                        <div
                            className="d-flex align-items-center justify-content-center">
                            <button
                                style={{marginRight: 20}}
                                className="btn btn-secondary update-contact"
                                type="button"
                                onClick={(e)=> this.handleLaneDeleteChanges(e)}
                                data-bs-original-title=""
                                title=""> Deletar
                            </button>
                            <button
                                className="btn btn-primary"
                                type="button"
                                onClick={this.closeDeletedModal}
                                data-bs-dismiss="modal"
                                data-bs-original-title=""
                                title="">Cancelar
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

                <DetailsModal showModal={showModal} selectedCard={selectedCard}
                              handleCloseModal={this.handleCloseModal}
                              closeModal={this.closeModal}
                              openModal={this.openModal}
                              applyFilters={this.applyFilters}
                              users={users}
                              users_groups={this.props.users_groups}
                              lanes={this.props.lanes}
                              lawyers={this.props.lawyers}
                              process_status={this.props.process_status}
                              query_types_new_process={this.props.query_types_new_process}
                              lawquery_types_in_progressyers={this.props.query_types_in_progress}
                              query_types_in_close={this.props.query_types_in_close}
                              image_avatar={this.props.image_avatar}
                              currentUser={this.props.currentUser}
                              currentUserRole={this.props.currentUserRole}
                              profile_picture={this.props.profile_picture}
                              currentCompany={this.props.currentCompany}
                              handleSaveChanges={this.handleSaveChanges}
                              setData={this.handleDataChange}
                              data={data}
                              setFilteredCards={this.handleFilteredCardsChange}/>


            </React.Fragment>
        );
    }
}

export default KanbanBoard;