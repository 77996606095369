import React, { useState } from "react";

// Paleta de cores do Google Calendar

const ColorSelector = ({ selectedColor, onSelectColor, colors }) => {
    const [activeColor, setActiveColor] = useState(selectedColor || colors[0]);

    const handleColorSelect = (color) => {
        setActiveColor(color);
        if (onSelectColor) onSelectColor(color);
    };

    return (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" ,     justifyContent: "center"}}>
            {colors.map((colorObj, index) => (
                <div
                    key={index}
                    onClick={() => handleColorSelect(colorObj)}
                    title={colorObj.name} // Tooltip com o nome da cor
                    style={{
                        backgroundColor: colorObj.color,
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        border: colorObj.color === activeColor.color ? "3px solid #a10889" : "2px solid #fff",
                        cursor: "pointer",
                        boxShadow: "0 0 2px rgba(0, 0, 0, 0.2)"
                    }}
                />
            ))}

        </div>
    );
};

export default ColorSelector;
