import React, { useState, useEffect } from "react";
import axios from "axios";
const getId = () => `${String(+new Date()).slice(6)}`;
const useComments = (card) => {
    const [comments, setComments] = useState(card?.comments || []);
    const [commentText, setCommentText] = useState('');

    useEffect(() => {
        if (card?.comments) {
            setComments(card.comments);
        }
    }, [card?.comments]); // Atualiza sempre que card.comments mudar

    const addComment = async (user) => {

        if (commentText.trim()) {
            const newComment = {
                id: getId(),
                userName: user.name,
                userId: user.id,
                cardId: card.id,
                userImage: user.avatar,
                text: commentText,
                timestamp: new Date().toLocaleString(),
            };
            try {
                await axios.post('/kanbans/add_comment', { newComment });

                setComments([...comments, newComment]);
                setCommentText('');

            } catch (error) {
                console.error('Erro ao criar tarefa:', error);
            }


        }

    };

    const editComment = (commentId, newText) => {

        setComments(comments.map(comment =>
            comment.id === commentId ? { ...comment, text: newText } : comment
        ));
    };

    const deleteComment = (commentId) => {
        setComments(comments.filter(comment => comment.id !== commentId));
    };

    return {
        setCommentText,
        commentText,
        comments,
        addComment,
        editComment,
        deleteComment,
    };
};

export default useComments;
