import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

const BotReport = ({ botId, botName }) => {
    const [report, setReport] = useState([]);

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const response = await fetch(`/bots/${botId}/report`);
                const data = await response.json();
                setReport(data);
            } catch (error) {
                console.error("Erro ao buscar relatório:", error);
            }
        };

        if (botId) {
            fetchReport();
        }
    }, [botId]);

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(report);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Bot Report");

        XLSX.writeFile(workbook, `report-${botName}.xlsx`);
    };
    const closeModal = (id) => {
        document.getElementById(id).style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    }
    return (
        <div className="row">
            <div className="col-12 p-4">


                {report.length > 0 ? (
                    <div>
                        <table className="col-12 min-w-full border-collapse border border-gray-300">
                            <thead>
                            <tr className="bg-gray-200">
                                <th className="border p-2">Número WhatsApp</th>
                                <th className="border p-2">Nome</th>
                                <th className="border p-2">Existe?</th>
                                <th className="border p-2">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {report.map((item, index) => (
                                <tr key={index} className="border">
                                    <td className="border p-2">{item.whatsapp_number}</td>
                                    <td className="border p-2">{item.name}</td>
                                    <td className="border p-2">{item.exists ? "Sim" : "Não"}</td>
                                    <td className="border p-2">{item.status}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-center justify-content-center mt-10">
                            <button
                                style={{marginRight: 20}}
                                onClick={exportToExcel}
                                className="btn btn-primary "
                                type="button">
                                Exportar
                            </button>
                            <button
                                className="btn btn-secondary"
                                type="button"
                                onClick={() => closeModal(`mass-campaing-report-${botId}`)}
                                data-bs-dismiss="modal"
                                data-bs-original-title=""
                                title="">Cancelar
                            </button>
                        </div>

                        <div className='d-flex justify-content-end mt-10'>

                        </div>
                    </div>
                ) : (
                    <p>Carregando relatório...</p>
                )}
            </div>
        </div>

    );
};

export default BotReport;
