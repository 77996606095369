import React, { useState } from 'react';
import "./style/kanban.css.scss";

const CustomLaneHeader = ({ title, laneId, color, cards, onSearch, departmentName, setSearchTerm, searchTerm, currentUserRole,draggable,lanes, openDeletedModal, handleChangeSelectedLane }) => {

    const [isSearching, setIsSearching] = useState(false);
    
    // Função para alternar a visibilidade do input de pesquisa
    const toggleSearch = () => {
        if (isSearching) {
            setSearchTerm(''); // Limpa o termo de pesquisa quando fecha a barra de pesquisa
            onSearch(laneId, ''); // Reseta a pesquisa quando fecha
        }
        setIsSearching(!isSearching); // Alterna o estado
    };


    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

    };
    const handleLaneChange = (lane) => {
        handleChangeSelectedLane(lane)
        openDeletedModal()

    }
    const lane = lanes.lanes.find(lane => lane.id === laneId);
    

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            padding:  '15px',
            borderRadius: '5px 5px 0 0',
            position: 'relative',
            width: 320,
            justifyContent: 'space-between'
        }}>
            <div style={{
                width: '5px',
                backgroundColor: color || "#a10889", // Defina a cor da lane dinamicamente
                height:  currentUserRole === "central" ? '70px': "60px",
                position: 'absolute',
                left: 0,
                top: 0
            }}></div>

            {/* Contêiner que inclui o título e o badge */}
            <div >
                <div className={'d-flex align-items-center'} style={{flexGrow: 1}}>
                    <span style={{backgroundColor: color || "#a10889", padding: "8px 15px"}}
                          className={`badge`}>{cards.length}</span>
                    <h5 style={{
                        marginLeft: 10,
                        marginTop: 0,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: isSearching ? 'none' : 'block'
                    }}>
                        {title}
                    </h5>

                </div>
                <p style={{marginLeft: 50, marginBlock:0,marginTop: -10, display: isSearching ? 'none' : 'block'}}> {departmentName}</p>

            </div>
            {( currentUserRole === "central" && cards.length === 0 && lane?.draggable  ) && <a  title="Excluir" href="#" onClick={() => handleLaneChange(lane)} style={{cursor: 'pointer', marginLeft: '10px', padding: "8px 15px"}}>
            <i className='bx bx-trash' ></i>
            </a>}
            { currentUserRole === "central" && lane?.draggable === false && <a  title="Fixo" style={{cursor: 'pointer', marginLeft: '10px', padding: "8px 15px"}}>
                <i className='bx bx-lock' ></i>
            </a>}

            {/* Input de pesquisa, exibido quando isSearching for verdadeiro
            <input
                type="text"
                className={`search-input ${isSearching ? 'expanded' : ''}`}
                placeholder="Pesquisar..."
                value={searchTerm}
                onChange={handleSearchChange}
                style={{
                    marginLeft: '10px',
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                    transition: 'width 0.3s ease',
                    width: isSearching ? '200px' : '0', // Controla a largura para expandir/recolher
                    opacity: isSearching ? 1 : 0, // Controla a opacidade para suavizar o efeito
                    visibility: isSearching ? 'visible' : 'hidden' // Oculta o input quando não está ativo
                }}
            />
             Ícone de pesquisa
            <span onClick={toggleSearch} style={{cursor: 'pointer', marginLeft: '10px', padding: "8px 15px"}}>
                <i className='bx bx-search'></i>
            </span>*/}
        </div>
    );
};

export default CustomLaneHeader;
