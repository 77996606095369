import React, { useState, useEffect } from "react";
import axios from "axios";
const getId = () => `${String(+new Date()).slice(6)}`;
const useTasks = ( card ) => {

    const [tasks, setTasks] = useState(card?.tasks || []);
    const [taskText, setTaskText] = useState('');

    useEffect(() => {
        if (card?.tasks) {
            setTasks(card.tasks);
        }
    }, [card?.tasks]); // Atualiza sempre que card.tasks mudar

    const addTask  = async () => {

        if (taskText.trim()) {
            const newTask = {
                id: getId(),
                cardId: card.id,
                text: taskText,
                completed: false,
            };

            try {
                await axios.post('/kanbans/add_task', { newTask });

                setTasks([...tasks, newTask]);
                setTaskText('');

            } catch (error) {
                console.error('Erro ao criar tarefa:', error);
            }

        }

    };

    const editTask  = async (taskId, newText) => {
        try {
            setTasks(tasks.map(task =>
                task.id === taskId ? { ...task, text: newText } : task
            ));

            const task =  tasks.filter(task => task.id === taskId)
            task[0].cardId =  card.id
            task[0].text =  newText

            await axios.put('/kanbans/update_task', { task });


        } catch (error) {
            console.error('Erro ao editar tarefa:', error);
        }
    };

    const deleteTask = async (taskId) => {

        try {
            await axios.put('/kanbans/delete_task', { taskId });
            setTasks(tasks.filter(task => task.id !== taskId));
        } catch (error) {
            console.error('Erro ao editar tarefa:', error);
        }
    };

    const toggleTaskCompletion = async (taskId) => {

        try {
            setTasks(tasks.map(task =>
                task.id === taskId ? { ...task, completed: !task.completed } : task
            ));

            const task =  tasks.filter(task => task.id === taskId)
            task[0].cardId =  card.id
            task[0].completed =  !task[0].completed

            await axios.put('/kanbans/update_task', {task});



        } catch (error) {
            console.error('Erro ao editar tarefa:', error);
        }


    };

    const calculateProgress = () => {
        const totalTasks = tasks.length;
        const completedTasks = tasks.filter(task => task.completed).length;
        return totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
    };

    return {
        tasks,
        taskText,
        setTaskText,
        addTask,
        editTask,
        deleteTask,
        toggleTaskCompletion,
        calculateProgress,
    };
};

export default useTasks;
