import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Image, ListGroup, Modal, ProgressBar, Row} from "react-bootstrap";
import ImageWithFallback from "../../ImageWithFallback";
import axios from "axios";
import Select from "react-select";
import "../../chats/style/slick.css";
import "../../chats/style/slick-theme.css";
import "../../chats/style/conversa.css";
import "../../chats/style/scrollbar.css";
import "../style/calendar.css";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import ColorSelector from "./useColorSelector";
const COLORS = [
    { color: "#7986CB", name: "Lavanda" },
    { color: "#33B679", name: "Sálvia" },
    { color: "#8E24AA", name: "Ametista" },
    { color: "#E67C73", name: "Cereja" },
    { color: "#F6BF26", name: "Banana" },
    { color: "#F4511E", name: "Tangerina" },
    { color: "#039BE5", name: "Pavão" },
    { color: "#616161", name: "Grafite" },
    { color: "#3F51B5", name: "Mirtilo" },
    { color: "#0B8043", name: "Manjericão" },
    { color: "#D50000", name: "Tomate" },
    { color: "#9E69AF", name: "Orquídea" }
];
const useDetailsModal = ({event, showDetailsModal, handleCloseDetailsModal, image_avatar, onSave})=> {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [eventColor, setEventColor] = useState({color: event?.extendedProps?.eventColor, colorName: event?.extendedProps?.colorName} || COLORS[4]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [title, setTitle] = useState(event?.title || "");
    const [startDate, setStartDate] = useState(event?.start || new Date());
    const [endDate, setEndDate] = useState(event?.end || new Date());
    const [attendees, setAttendees] = useState(event?.extendedProps.attendees || []);
    const [description, setDescription] = useState(event?.extendedProps.description || "");
    const [startTime, setStartTime] = useState( "");
    const [endTime, setEndTime] = useState("");
    const [isAllDay, setIsAllDay] = useState(event?.allDay || false);

    const formatDate = (date) => {
        if (!date) return ""; // Se a data não existir, retorna vazio
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, "0"); // Adiciona zero à esquerda
        const day = String(d.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const formatTime = (date) => {
        if (!date) return ""; // Se o horário não existir, retorna vazio
        const d = new Date(date);
        const hours = String(d.getHours()).padStart(2, "0");
        const minutes = String(d.getMinutes()).padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    useEffect(() => {
        if (event?.title) {
            setTitle(event.title);
        }
    }, [event?.title]);
    useEffect(() => {
        if (event?.extendedProps?.eventColor) {
            setEventColor({color: event.extendedProps.eventColor, colorName: event.extendedProps.colorName} );
        }
    }, [event?.extendedProps?.eventColor, event?.extendedProps?.colorName]);
    useEffect(() => {
        if (event?.allDay) {
            setIsAllDay(event.allDay);
        }
    }, [event?.allDay]);
    useEffect(() => {
        if (event?.start) {
            setStartDate(formatDate(event.start));
            if(!event.allDay){
                setStartTime(formatTime(event.start));
            }

        }
    }, [event?.start]);
    useEffect(() => {
        if (event?.end) {
            setEndDate(formatDate(event.end));
            if(!event.allDay){
                setEndTime(formatTime(event.end));
            }

        }
    }, [event?.end]);
    useEffect(() => {
        if (event?.extendedProps?.description) {
            setDescription(event.extendedProps.description);
        }
    }, [event?.extendedProps?.description]);

    const formatDateToPortuguese = (date,  allDay) =>{
        if (allDay) {
            return format(date, "EEEE, dd 'de' MMMM 'de' yyyy", { locale: ptBR }) + " (dia inteiro)";
        } else {
            return format(date, "EEEE, dd 'de' MMMM 'de' yyyy 'às' HH:mm", { locale: ptBR });
        }

        return formatter.format(date);
    }
    const hexToRgbWithOpacity  = (hex, opacity) => {
        // Remove o '#' se estiver presente
        hex = hex.replace("#", "");

        // Converte o hexadecimal para valores RGB
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        // Retorna no formato rgba
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    const handleCloseDeleteModal = () => {
        setShowDeleteModal(false)
    };
    const handleOpenDeleteModal = () => {
        setShowDeleteModal(true)
    };
    const handleCloseEditModal = () => {
        setShowEditModal(false)
    };
    const handleOpenEditModal = () => {
        setShowEditModal(true)
    };
    const handleAddAttendee = (e) => {
        if (e.key === "Enter" && e.target.value) {
            setAttendees([...attendees, e.target.value]);
            e.target.value = "";
        }
    };
    const handleRemoveAttendee = (email) => {
        setAttendees(attendees.filter((attendee) => attendee !== email));
    };
    const handleSave = () => {
        onSave({
            title,
            start,
            end,
            description,
            attendees,
        });
        setShowEditModal(false);
    };


    return(
        <React.Fragment>
            <Modal show={showDetailsModal} onHide={handleCloseDetailsModal} size="lg" centered>
                <Row style={{padding: 10}} className="d-flex justify-content-between">
                    <Col md={9}>
                        <Modal.Title>
                            <div className="team-name">
                                <a  className="team" style={{backgroundColor: eventColor.color, width: "100%"}}>
                                    <h5 style={{ color: "#fff"}}>  {event ? event.title : ''}</h5>
                                </a>
                            </div>

                        </Modal.Title>
                    </Col>
                    <Col md={3}>
                        <a href="#"  title="Editar Evento" onClick={handleOpenEditModal} className="first_name_0" style={{paddingLeft: 10}}>
                            <i style={{fontSize: 25}} className='bx bx-calendar-edit'></i>
                        </a>
                        <a href="#"  title="Excluir Evento" onClick={handleOpenDeleteModal} className="first_name_0" style={{paddingLeft: 10}}>
                            <i style={{fontSize: 25}} className='bx bx-trash'></i>
                        </a>
                        <a href="#"  title="Fechar" onClick={handleCloseDetailsModal} className="first_name_0"
                           style={{paddingLeft: 75}}>
                            <i style={{fontSize: 25}} className='bx bx-x'></i>
                        </a>
                    </Col>
                    <div style={{padding: 10}} className="d-flex">
                        <i style={{fontSize: 25, paddingRight: 5}} className='bx bx-calendar-event'></i>
                        {event ? formatDateToPortuguese(event.start, event.allDay) : ''}
                    </div>

                </Row>
                <Modal.Body>
                    <Row>
                        <small>Descrição:</small>
                        {event ? (
                                <>
                                    {event.extendedProps.description && (
                                        <p>{event.extendedProps.description}</p>
                                     )}
                                </>
                        ): ""}
                    </Row>
                    <Row >

                        <div>
                            <small>Integrantes:</small>
                            {event ? (
                                <>
                                    {Array.isArray(event?.extendedProps.attendees) && event.extendedProps.attendees.length > 0 ? (
                                        event.extendedProps.attendees.map((attendee, index) => (
                                            <div style={{
                                                display: "flex",
                                                padding: "5px 10px",
                                                backgroundColor: "#f0f0f0",
                                                borderRadius: "15px",
                                                border: "1px solid #ccc",
                                                color: "#333",
                                                width:250,
                                                fontSize: "14px",
                                            }} className=" mb-3"  key={index}>
                                                <ImageWithFallback
                                                    classImage=" img-fluid m-r-20 rounded-circle update_img_0"
                                                    src={attendee.avatar}
                                                    style={{ width: 20, height: 20, filter: "none" }}
                                                    fallback={image_avatar}
                                                    alt="attendee"
                                                />
                                                <div>
                                                    {attendee.attendeeType === "user" && <a title={`${attendee.name} - ${attendee.email}`} className="font-w400 first_name_0 d-flex">
                                                        <small style={{
                                                            display: "block", // Garante que seja um bloco para respeitar o width
                                                            overflow: "hidden",
                                                            whiteSpace: "nowrap",
                                                            textOverflow: "ellipsis",
                                                            width: "150px", // Certifique-se de usar "px" para a largura
                                                        }}>{attendee.email}</small>
                                                        <i  style={{paddingLeft: 15,  fontSize: 20}} className='bx bx-envelope'></i>
                                                    </a>}
                                                    {attendee.attendeeType === "contact" &&
                                                        <a title={`${attendee.name} - ${attendee.cellphone}`}
                                                           className="font-w400 first_name_0 d-flex">
                                                            <small style={{
                                                                display: "block", // Garante que seja um bloco para respeitar o width
                                                                overflow: "hidden",
                                                                whiteSpace: "nowrap",
                                                                textOverflow: "ellipsis",
                                                                width: "150px", // Certifique-se de usar "px" para a largura
                                                            }}>{attendee.cellphone}</small>

                                                            <i style={{paddingLeft: 15, fontSize: 20}} className='bx bxs-contact'></i>
                                                        </a>}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>Nenhum participante encontrado.</p>
                                    )}
                                </>
                            ) : (
                                ''
                            )}
                        </div>

                    </Row>
                </Modal.Body>


            </Modal>
            <Modal show={showEditModal} onHide={handleCloseEditModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Editar Evento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        {/* Título do Evento */}
                        <Form.Group controlId="eventTitle" className="mb-3">
                            <Form.Label>Título</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digite o título do evento"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                        <div className="row">

                            <div className="col-sm-12">
                                <div>
                                    <label>Selecione a Cor do Evento:</label>
                                    <ColorSelector
                                        selectedColor={eventColor}
                                        colors={COLORS}
                                        onSelectColor={(color) => setEventColor(color)}
                                    />
                                    <p style={{display: "flex", justifyContent: "center"}}>
                                        <small>Cor Selecionada:</small>
                                        <span style={{
                                            color: eventColor?.color,
                                            marginLeft: "10px"
                                        }}>{eventColor?.name}</span>
                                    </p>
                                </div>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="checkbox-wrapper-4">
                                    <input className="inp-cbx" id="morning" checked={isAllDay}
                                           onChange={(e) => setIsAllDay(e.target.checked)} type="checkbox"/>
                                    <label className="cbx" htmlFor="morning"><span>
                                <svg width="12px" height="10px">
                                <use href="#check-4"></use>
                                </svg></span><span>Dia Inteiro</span></label>
                                    <svg className="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {isAllDay && <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group mb-3">
                                    <label>Data de Início</label>
                                    <div className="cal-icon">
                                        <input
                                            className="form-control"
                                            name="start_date"
                                            type="date"
                                            id="startDate"
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        {!isAllDay && (
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group mb-3">
                                        <label>Data de Início</label>
                                        <div className="cal-icon">
                                            <input
                                                className="form-control"
                                                name="start_date"
                                                type="date"
                                                id="startDate"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 ">
                                    <div className="form-group mb-3">
                                        <label>Horário de Início</label>
                                        <div className="cal-icon">
                                            <input
                                                className="form-control"
                                                name="start_time"
                                                type="time"
                                                id="startTime"
                                                value={startTime}
                                                onChange={(e) => setStartTime(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        {!isAllDay && (
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group mb-3">
                                        <label>Data de Finalização</label>
                                        <div className="cal-icon">
                                            <input
                                                className="form-control"
                                                name="end_date"
                                                type="date"
                                                id="endDate"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group mb-3">
                                        <label>Horário de Finalização</label>
                                        <div className="cal-icon">
                                            <input
                                                className="form-control"
                                                name="end_time"
                                                type="time"
                                                id="endTime"
                                                value={endTime}
                                                onChange={(e) => setEndTime(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}


                        <Form.Group controlId="eventTitle" className="mb-3">
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                style={{resize: 'none'}}
                                placeholder="Digite a descrição do evento"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Group>

                        {/* Data e Hora */}


                        {/* Participantes */}
                        <Form.Group controlId="eventAttendees" className="mb-3">
                            <Form.Label>Participantes</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Digite o email e pressione Enter"
                                onKeyDown={handleAddAttendee}
                            />
                            <div className="mt-2">
                                {attendees.map((email, index) => (
                                    <div
                                        key={index}
                                        className="d-flex align-items-center justify-content-between mb-2 p-2 border rounded"
                                    >
                                        <span>{email}</span>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleRemoveAttendee(email)}
                                        >
                                            Remover
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={handleSave}>
                        Salvar
                    </Button>
                    <Button variant="secondary" onClick={handleCloseEditModal}>
                        Cancelar
                    </Button>

                </Modal.Footer>


            </Modal>
            <Modal dialogClassName="delete-custom-modal" show={showDeleteModal} onHide={handleCloseDeleteModal}
                   size="sm" centered>

                <Modal.Header closeButton>
                    <Modal.Title>
                        Remover
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-center">
                        <small> Deseja excluir {event ? event.title : ''} ? </small>
                    </div>

                </Modal.Body>
                <Modal.Footer className=" d-flex align-items-center justify-content-center">

                    <Button variant="primary">
                        Excluir
                    </Button>
                    <Button variant="secondary" onClick={handleCloseDeleteModal}>
                        Fechar
                    </Button>
                </Modal.Footer>

            </Modal>
        </React.Fragment>
    );
}
export default useDetailsModal;