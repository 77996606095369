import React, { Component } from "react";
import axios from "axios";
import ActionCable from "actioncable";
import ImageWithFallback from "../../ImageWithFallback";
import FilePreview from "../../FilePreview";
import ListMessage from "../../chats/whatsapp/ListMessage";
import ListResponseMessage from "../../chats/whatsapp/ListResponseMessage";
import ResponseMessage from "../../chats/whatsapp/ResponseMessage";
import ErrorMessage from "../../chats/whatsapp/ErrorMessage";
import ErrorCall from "../../chats/whatsapp/ErrorCall";

import {Button, Form, Spinner} from "react-bootstrap";



class ChatComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageText: "",
            chat: { messages: [] },
        };
        this.chatContainerRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    // Método para lidar com clique fora do chat
    handleClickOutside(event) {
        if (
            this.chatContainerRef.current &&
            !this.chatContainerRef.current.contains(event.target)
        ) {
            console.log("Clique fora do chat!");
        }
    }

    // Método para buscar as mensagens do chat
    async handleChat() {
        const { chatId } = this.props; // O chatId é passado via props
        try {
            let response = await axios.get("/messagings/get_chat.json", {
                params: {
                    chat_id: chatId,
                },
            });
            this.setState({ chat: { messages: response.data.messages } });
        } catch (error) {
            console.error("Erro ao buscar chat:", error);
        }
    }

    // Enviar mensagem
    async sendMessage() {
        const { messageText } = this.state;
        const { chatId, currentUser } = this.props;

        try {
            const formData = new FormData();
            if (messageText.trim()) {
                const newMessage = {
                    position: "right",
                    type: "text",
                    sender: currentUser.name,
                    avatar: this.props.profile_picture,
                    subtype: "text",
                    text: messageText,
                    date: new Date(),
                };

                formData.append("text", messageText);
                formData.append("type", "text");
                formData.append("subtype", "text");
                formData.append("date", new Date());
                formData.append("position", "right");
                formData.append("chat_id", chatId);

                axios
                    .post("/messagings/send_message", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => {
                        console.log("Resposta do servidor:", response.data);

                        this.setState((prevState) => ({
                            chat: {
                                ...prevState.chat,
                                messages: [
                                    ...(prevState.chat?.messages || []),
                                    newMessage,
                                ],
                            },
                        }));
                        this.setState({ messageText: "" });
                    })
                    .catch((error) => {
                        console.error("Erro ao enviar arquivos:", error);
                    });
            }
        } catch (error) {
            console.error("Erro ao enviar mensagem do chat:", error);
        }
    }

    // Função para rolar para o final do chat
    scrollToBottomChat() {
        console.log("chamou o pai - scrollToBottom ");
        if (this.chatContainerRef.current) {
            setTimeout(() => {
                const lastMessage =
                    this.chatContainerRef.current.lastElementChild; // Acessa o último <li>
                if (lastMessage) {
                    lastMessage.scrollIntoView({ behavior: "smooth" }); // Rola suavemente até o último <li>
                }
            }, 100); // Atraso de 100ms
        }
    }

    renderMessageContent = (msg) => {
        const {  chat } = this.state;
        switch (msg.type) {
            case 'text':
                if (msg.subtype === 'text') {
                    return <div className="msg-text message-bubble">{msg.text}</div>;
                }
                return this.renderFileContent(msg);
            case 'list':
                return <ListMessage title={msg.title.replace(/\*/g, "")} description={msg.description} sections={msg.sections} />;
            case 'listResponse':
                return <React.Fragment>
                    <ListResponseMessage title={msg.contextInfo["title"].replace(/\*/g, "")} description={msg.contextInfo["description"]}/>
                    <div className="list-response-message-response">{msg.text}</div>
                </React.Fragment>;
            case 'response':
                return <ResponseMessage message={msg} image_avatar={this.props.selectedCard?.avatar_fallback} chat={chat} currentCompany={this.props.currentCompany} />;
            case 'error':
                return <ErrorMessage message={msg} image_avatar={this.props.selectedCard?.avatar_fallback} chat={chat} currentCompany={this.props.currentCompany} />;
            case 'call':
                return <ErrorCall message={msg} image_avatar={this.props.selectedCard?.avatar_fallback} chat={chat} currentCompany={this.props.currentCompany} />;
            default:
                return null;
        }
    };

    renderFileContent = (msg) => {
        if (msg.subtype.split('/')[0] === 'image') {
            return <img  src={msg.file} alt={`image-${msg.id}`} style={{ filter: "none", minWidth: 400, maxWidth: 400, minHeight: 280, maxHeight: 280, borderRadius: "5px" }} />;
        }
        if (msg.subtype.split('/')[0] === 'audio') {
            return <audio src={msg.file} controls />;
        }
        if (msg.subtype.split('/')[0] === 'video') {
            return <video src={msg.file} controls />;
        }
        if (msg.subtype.split('/')[0] === 'pdf') {
            return <FilePreview fileUrl={msg.file} file_name={msg.file_name || ''} extension={msg.subtype} />;
        }
        return null;
    };
    handleInputMessageTextChange = (event) => {
        this.setState({ messageText: event.target.value });
    }
    componentDidMount() {
        // Cria o consumidor para o ActionCable
        let cable = ActionCable.createConsumer("/cable");


        // Configura o canal de assinatura
        this.chatChannel = cable.subscriptions.create("MessageNotificationChannel", {

            connected: () => {
                console.log("me chamou aqui no modal");
                console.log("Connected to MessageNotificationChannel - CHAT");
            },
            received: (data) => {
                const { chatId } = this.props;

                if (data.chat_id.toString() === chatId.toString()) {
                    this.handleChat().catch((error) => {
                        console.error("Erro ao salvar no backend:", error);
                    });
                }
            },
        });

        // Adiciona o evento de clique fora do componente
        document.addEventListener("mousedown", this.handleClickOutside);

        // Buscar chat inicial se chatId estiver presente
        const { chatId } = this.props;
        if (chatId) {
            this.handleChat().catch((error) => {
                console.error("Erro ao buscar o chat no backend:", error);
            });
        }
    }

    componentWillUnmount() {
        // Remove o evento de clique
        document.removeEventListener("mousedown", this.handleClickOutside);

        // Desinscreve do canal
        if (this.chatChannel) {
            this.chatChannel.unsubscribe();
            console.log("Unsubscribed from MessageNotificationChannel - CHAT");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Chama a rolagem para o final sempre que novas mensagens são adicionadas
        if (prevState.chat.messages !== this.state.chat.messages) {
            this.scrollToBottomChat();
        }
    }

    render() {
        const { messageText, chat } = this.state;
        return (
            <React.Fragment>
                {/*  <div style={{maxHeight: '200px', overflowY: 'auto'}}>*/}
                <div className="right-sidebar-chats">
                    <div className="msger">
                        <div className="msger-chat">
                            <ul ref={this.chatContainerRef}>
                                {Array.isArray(chat?.messages) && chat?.messages?.length > 0 && chat?.messages?.map((msg, index) => (
                                    <li key={msg.id || index}>
                                        <div className={`d-flex msg mb-10 ${msg.position === "right" ? 'right-msg' : 'left-msg'}`}>
                                            <div className="msg-img">
                                                <ImageWithFallback
                                                    classImage={"img-fluid rounded-circle update_img_0"}
                                                    style={{ width: 35, height: 35, filter: "none" }}
                                                    src={msg.avatar}
                                                    fallback={this.props.selectedCard?.avatar_fallback}
                                                    alt="Imagem"
                                                />
                                            </div>
                                            <div className="msg-bubble" style={{ position: "relative" }}>
                                                <div className="msg-info">
                                                    <div className="msg-info-name">
                                                        {msg.position === "right" ? `${this.props.currentCompany.name}${msg.sender ? " - " + msg.sender : ""}` : this.props.selectedCard ? this.props.selectedCard.title : ''}
                                                    </div>
                                                </div>
                                                {this.renderMessageContent(msg)}
                                                <div className="time-info">{(new Date(msg.date)).toLocaleString()}</div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {chat?.messages?.length === 0 && (
                                    <div className="center">
                                        <Spinner animation="border" role="status" />
                                        <p>Carregando mensagens...</p>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                {/*</div>*/}
                <div className="d-flex col-xl-12">
                    <Form.Control
                        as="textarea"
                        rows={2}
                        className="col-xl-10 w-90"
                        placeholder="Escreva sua mensagem..."
                        value={messageText}
                        onChange={this.handleInputMessageTextChange}
                        style={{marginTop: '10px'}}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault(); // Prevent line breaks
                                this.sendMessage()
                                    .then(() => setMessageText(''))  // Clear message after sending
                                    .catch((error) => {
                                        console.error("Erro ao enviar message", error);
                                        // Optionally: Notify the user about the error
                                    });
                            }
                        }}
                    />
                    <div className=" col-xl-2">
                        <Button className="msger-send-btn" style={{margin: 25}} title="Enviar" onClick={() => this.sendMessage()}><i
                            className="bx bxs-paper-plane"></i>
                        </Button>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default ChatComponent;
