import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import ImageWithFallback from "../chats/ImageWithFallback";
import "./style/kanban.css.scss";

import ReactDOM from 'react-dom';
import {Button, Col, Form, Image, ListGroup, Modal, ProgressBar, Row} from "react-bootstrap";
import {ChromePicker} from "react-color";
const CardContainer = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  max-width: 290px;
  min-width: 290px;

`;

const CustomCard = ({ title, description,handleTagColor,handleCloseModal, closeModal,handleSaveChanges, onClick, color, currentUserId, currentUserRole, image_avatar,...props }) => {



    const [cardColor, setCardColor] = useState("#a10889");
    const [showDropdown, setShowDropdown] = useState(false);


    const toggleDropdown = (e) => {
        e.preventDefault();
        setShowDropdown(!showDropdown); // Alterna entre mostrar e esconder
    };


    const searchingUser = () => {
        console.log("searchingUser");

        if (props.users.length > 0) {
            const user = props.users.find(user => user.id === currentUserId);
            return !!user; // Retorna 'true' se o usuário for encontrado, caso contrário, 'false'
        } else {
            return false;
        }
    }
    const  hexToRgb =(hex) =>{
        if (!hex) return "rgba(0, 0, 0, 0.5)"; // Cor padrão se hex não estiver presente
        const bigint = parseInt(hex.replace("#", ""), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r}, ${g}, ${b}, 0.5)`;
    }
    useEffect(() => {
        setCardColor( color || "#a10889")
    }, [color]);

    return (
        <React.Fragment>
            <CardContainer key={props.id} style={{position: 'relative', cursor: 'pointer'}}>
                {/* Linha vertical no lado esquerdo */}
                <div style={{
                    width: '5px',
                    backgroundColor: cardColor, // Defina a cor da lane dinamicamente
                    height: '80%',
                    position: 'absolute',
                    left: 0,
                    top: 15
                }}
                     onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = color)}
                ></div>


                <div>
                    <div onClick={onClick} className={'d-flex'}>
                        <div className="active-profile pr-20" style={{paddingRight: 10}}>
                            <ImageWithFallback
                                classImage={"img-fluid rounded-circle "}
                                src={props.avatar}
                                style={{height: 40, width: 40, filter: "none" }}
                                fallback={image_avatar}
                                alt="user"
                            />

                            <div className="status bg-success" style={{height: 20,
                                top: 20, right: 4, width: 20, display: "flex", justifyContent: "center",alignItems: "center"  }}>
                                <i className='bx bxl-whatsapp'></i>
                            </div>
                        </div>
                        <div className="pf-10">
                            <span>{title}</span>
                            <p style={{marginTop:0}}>{props.phone}</p>
                        </div>
                    </div>
                    <div>
                        <div style={{display: "flex", justifyContent: "center"}}>

                            {props?.tags?.length < 1 && <span style={{padding: "0px 135px"}}> </span>}

                        </div>
                        <div className="divider"></div>
                        <div style={{display: "flex", minHeight:25, maxHeight: 25, justifyContent: "flex-start"}}>
                            <div className="linha">
                                <ul className="d-flex">
                                    {props.tags  && <li>

                                        <div className="dropdown" style={{position: 'relative', zIndex: 10}}>
                                            <a href="#" onClick={toggleDropdown}>

                                                <i className='bx bx-purchase-tag-alt' title="Tag"></i>
                                            </a>

                                            <div className="dropdown-menu" style={{
                                                position: 'absolute',
                                                top: -83,
                                                left: 25,
                                                zIndex: 1000,
                                                backgroundColor: '#fff',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                padding: '10px',
                                               minWidth: 225,
                                                display: showDropdown ? "block": "none"
                                            }}>
                                                {props?.tags &&
                                                    <>
                                                        <a className="dropdown-item " style={{display: "flex", flexDirection: "column"}}>
                                                            <span key={props.tags?.primary?.code}
                                                                  title={props.tags?.primary?.title}
                                                                  style={{
                                                                      color:props?.tags?.primary?.color ,
                                                                      backgroundColor:  hexToRgb(props?.tags?.primary?.color),
                                                                  }}
                                                                  className={`texto-limitado badge  mb-3`}>{props.tags?.primary?.title}</span>

                                                            <span key={props.tags?.segundary?.code}
                                                                  title={props.tags?.segundary?.title}
                                                                  style={{
                                                                      color: props?.tags?.segundary?.color,
                                                                      backgroundColor: hexToRgb(props?.tags?.segundary?.color),
                                                                  }}
                                                                  className={`texto-limitado badge  mb-3`}>{props.tags?.segundary?.title}</span>

                                                        </a>
                                                    </>
                                                }

                                            </div>

                                        </div>

                                    </li>}
                                    {/* <li><a href="#" title="Contatos"><i className='bx bxs-contact'></i></a></li>*/}
                                    {Array.isArray(props?.users) &&
                                       ( props?.users?.some(user => user?.id?.toString() === currentUserId?.toString()) || currentUserRole === "central"  ) &&
                                        props?.links?.chat && (
                                            <li>
                                                <a
                                                    href={`/messagings.${props.links.chat}`}
                                                    title="Ir para Chat"
                                                >
                                                    <i className="bx bx-chat bx-flip-horizontal"></i>
                                                </a>
                                            </li>
                                        )}
                                </ul>
                            </div>
                            <div style={{paddingLeft: "55%"}}>
                                <ul className="user-list s3">
                                    {props.users?.slice(0, 2).map((user) => (
                                        <li key={user.id}>
                                            <ImageWithFallback
                                                src={user.avatar}
                                                style={{filter: "none"}}
                                                fallback={image_avatar}
                                                title={user.name}
                                                alt={`user${user.id}`}
                                            />
                                        </li>
                                    ))}
                                    {props.users?.length > 2 && (
                                        <li   title={`Outros: ${props.users.slice(2).map((user) => user.name).join(',\n ')}`}  className="total">
                                            <span>+{props.users.length - 2}</span>
                                        </li>
                                    )}

                                </ul>
                            </div>

                        </div>

                    </div>


                </div>

            </CardContainer>



        </React.Fragment>
    );
};
export default CustomCard;