import React, { useState } from 'react';
const ErrorCall = ({message, chat, image_avatar, currentCompany}) => {
    return (
        <div className="list-message mb-10" style={{
            borderLeft: "4px solid #ec5d5d",
            paddingLeft: 16, /* Espaço entre a linha e o conteúdo do card */
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 8
        }}>
            <div className="d-flex">
                <i className='bx bx-phone-call' style={{fontSize: 48, color: "#ec5d5d", paddingRight: 10}}></i>
                <p style={{marginTop: "-3px"}}
                   className="msg-text">{"Não é possível receber ligações neste dispositivo. Abra o WhatsApp no seu celular para retornar a ligação. "}</p>
            </div>


        </div>
    )
}
export default ErrorCall;