import React, { useState } from 'react';

const ListResponseMessage = ({ title, description }) => {
    const [isListVisible, setIsListVisible] = useState(false);

    const toggleListVisibility = () => setIsListVisible(!isListVisible);


    return (
        <div className="list-response-message">
            {/* Cabeçalho */}
            <div className="list-response-message-header">
                <h5 style={{fontWeight: "bold"}}>{title}</h5>
            </div>

            {/* Corpo */}
            <div className="list-response-message-body">
                <p>{description}</p>
            </div>

            {/* Botão para exibir opções */}
            <div className="list-response-message-footer center">
                <button disabled={true}>
                    {"Ver Opções"}
                </button>
            </div>

        </div>
    );
};

export default ListResponseMessage;
